import { Card, Stack } from "@mantine/core";
import { getReadableDate, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import React from "react";
import { CardKeyValue } from "../../card-key-value.component";
import { OrchestryCardTitle } from "../../orchestry-card-title.component";
import { useTenantPage } from "../../use-tenant-page.hook";
export var Provisioning = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Provisioning, _c = _b === void 0 ? {} : _b, LastProvisioningJobDate = _c.LastProvisioningJobDate, CustomWorkspaceTemplatesCount = _c.CustomWorkspaceTemplatesCount, CustomMetaDataCount = _c.CustomMetaDataCount, CustomNamingConventionsCount = _c.CustomNamingConventionsCount, CustomApprovalProcessesCount = _c.CustomApprovalProcessesCount, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, mih: 300, h: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Provisioning" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.calendar, keyString: "Last Provisioning Job Date", value: LastProvisioningJobDate != null ? getReadableDate(LastProvisioningJobDate) : "Never", suffix: LastProvisioningJobDate != null ? "(".concat(getReadableDateFromNow(new Date(LastProvisioningJobDate)), ")") : "" }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.layout, keyString: "Custom Workspace Templates", value: CustomWorkspaceTemplatesCount === null || CustomWorkspaceTemplatesCount === void 0 ? void 0 : CustomWorkspaceTemplatesCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.codeBrowser, keyString: "Custom Metadata", value: CustomMetaDataCount === null || CustomMetaDataCount === void 0 ? void 0 : CustomMetaDataCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.tag03, keyString: "Custom Naming Conventions", value: CustomNamingConventionsCount === null || CustomNamingConventionsCount === void 0 ? void 0 : CustomNamingConventionsCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.checkSquare, keyString: "Custom Approval Processes", value: CustomApprovalProcessesCount === null || CustomApprovalProcessesCount === void 0 ? void 0 : CustomApprovalProcessesCount.toLocaleString() }))));
};
