import { GrowthBook } from "@growthbook/growthbook-react";
import { nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import React from "react";
var FEATURES_ENDPOINT = "https://cdn.growthbook.io/api/features/sdk-RqLg9Mij7nVmzISw";
export var useGrowthbook = function () {
    var growthbook = new GrowthBook({
        apiHost: "https://cdn.growthbook.io",
        clientKey: "sdk-RqLg9Mij7nVmzISw",
        attributes: {
            id: localStorage.getItem("growthbook_visitor_id") || nanoid()
        },
        trackingCallback: function (experiment, result) {
            console.log({
                experimentId: experiment.key,
                variationId: result.variationId
            });
        }
    });
    React.useEffect(function () {
        var growthbook_visitor_id = localStorage.getItem("growthbook_visitor_id");
        if (!growthbook_visitor_id) {
            growthbook_visitor_id = nanoid();
            localStorage.setItem("growthbook_visitor_id", growthbook_visitor_id);
            growthbook.setAttributes({ id: growthbook_visitor_id });
        }
        axios
            .get(FEATURES_ENDPOINT)
            .then(function (res) { return res.data; })
            .then(function (data) {
            growthbook.setPayload(data.features);
        })
            .catch(function (error) { return console.error("Failed to fetch features:", error); });
    }, [FEATURES_ENDPOINT, growthbook]);
    return growthbook;
};
