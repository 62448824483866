var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useForm } from "@mantine/form";
import { useAddAnnotationMutation, useGetAnnotationsQuery } from "appv2/api/health-checks/health-checks.api";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export var useAddAnnotationModal = function (_a) {
    var _b;
    var context = _a.context, id = _a.id, _c = _a.innerProps, intl = _c.intl, annotationCategory = _c.annotationCategory, healthCheckId = _c.healthCheckId, tenantGuid = _c.tenantGuid, associatedGUID = _c.associatedGUID, hideAnnotationList = _c.hideAnnotationList;
    var userType = tenantGuid ? "partner" : "customer";
    var _d = useGetAnnotationsQuery([userType, healthCheckId, annotationCategory, tenantGuid, associatedGUID]), annotations = _d.data, annotationsIsFetching = _d.isFetching, annotationsIsLoading = _d.isLoading;
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var _e = useAddAnnotationMutation(), addAnnotation = _e[0], _f = _e[1], addAnnotationIsLoading = _f.isLoading, isAddSuccess = _f.isSuccess;
    var form = useForm({
        initialValues: {
            TenantGuid: tenantGuid,
            AssociatedGUID: associatedGUID,
            Category: annotationCategory,
            HealthCheckGuid: healthCheckId,
            Body: ""
        },
        validate: {}
    });
    var handleFormSubmit = function (values) {
        var _a;
        addAnnotation({
            userType: tenantGuid ? "partner" : "customer",
            insertAnnotationCommand: {
                TenantGuid: tenantGuid,
                HealthCheckGuid: values.HealthCheckGuid,
                Body: values.Body,
                Category: values.Category,
                AssociatedGUID: values.AssociatedGUID
            },
            authorId: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.GUID) !== null && _a !== void 0 ? _a : ""
        });
    };
    useEffect(function () {
        var handleSuccess = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (isAddSuccess) {
                    context.closeModal(id);
                    OrchestrySuccessToast(intl.formatMessage({ id: "page.health_check.toast.annotation_added", defaultMessage: "Annotation added successfully." }));
                }
                return [2 /*return*/];
            });
        }); };
        handleSuccess();
    }, [isAddSuccess, context, id, intl]);
    var saveIsDisabled = ((_b = form.values.Body) === null || _b === void 0 ? void 0 : _b.length) < 1 || addAnnotationIsLoading;
    var shouldRenderAnnotationsList = !!(annotations === null || annotations === void 0 ? void 0 : annotations.length) && !hideAnnotationList;
    return {
        form: form,
        isLoading: annotationsIsLoading || addAnnotationIsLoading,
        handleFormSubmit: handleFormSubmit,
        saveIsDisabled: saveIsDisabled,
        annotations: annotations,
        annotationsIsFetching: annotationsIsFetching,
        shouldRenderAnnotationsList: shouldRenderAnnotationsList
    };
};
