import { CopyButton } from "@mantine/core";
import { SubtitleButton } from "app/components/common/subtitle";
import * as TableList from "app/components/common/table-list";
import TenantService from "app/services/tenant";
import { TenantType } from "app/store/tenants";
import * as TenantCurrentStore from "app/store/tenants/current";
import { PartnerToolsTableLicenseColumn } from "appv2/pages/partner-tools/partner-tools-table-columns.component";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./partner.component.scss";
var OrchestryTenantsDetailsPartner = React.memo(function () {
    var tenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var dispatch = useDispatch();
    var _a = React.useState(null), partnerTenants = _a[0], setPartnerTenants = _a[1];
    var _b = React.useState(false), loadingPartnerTenants = _b[0], setLoadingPartnerTenantsPartnerTenants = _b[1];
    React.useEffect(function () {
        if (tenant !== undefined && tenant != null && tenant.GUID !== undefined && tenant.GUID !== null) {
            if (partnerTenants === null) {
                setLoadingPartnerTenantsPartnerTenants(true);
                TenantService.getPartnerTenants(tenant.GUID).then(function (response) {
                    setPartnerTenants(response);
                    setLoadingPartnerTenantsPartnerTenants(false);
                });
            }
        }
    }, [tenant]);
    var disconnectPartnerTenant = function (tenantGuid) {
        setLoadingPartnerTenantsPartnerTenants(true);
        var tempTenantGuid = tenantGuid;
        if (tenantGuid === undefined || tenantGuid === null) {
            tempTenantGuid = tenant.GUID;
        }
        console.log("tenant guid", tenantGuid);
        TenantService.disconnectPartnerTenant(tempTenantGuid).then(function (disconnectResponse) {
            if (tenantGuid === undefined || tenantGuid === null) {
                dispatch(TenantCurrentStore.Clear());
                dispatch(TenantCurrentStore.LoadWithInfo(tempTenantGuid));
            }
            else {
                TenantService.getPartnerTenants(tempTenantGuid).then(function (response) {
                    setPartnerTenants(response);
                    setLoadingPartnerTenantsPartnerTenants(false);
                });
            }
        });
    };
    return (React.createElement("div", { className: "tenant-details-partner-tab" }, tenant.TenantType == TenantType.PartnerHub ? (React.createElement(Container, { fluid: true, className: "tenant-details-partner-hub" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Partner License Key"),
                    React.createElement("div", { className: "inline-input-save-button" },
                        React.createElement(Form.Control, { placeholder: "", 
                            //@ts-ignore
                            value: tenant.InstallCode, disabled: true }),
                        React.createElement(CopyButton, { value: tenant.InstallCode }, function (_a) {
                            var copied = _a.copied, copy = _a.copy;
                            return React.createElement(SubtitleButton, { faIcon: copied ? "check" : "save", color: "orange", onClick: copy, noMargin: true });
                        }))),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Partner Multi Tenant Management Secret"),
                    React.createElement("div", { className: "inline-input-save-button" },
                        React.createElement(Form.Control, { placeholder: "", 
                            //@ts-ignore
                            value: tenant.PartnerSecret, disabled: true }),
                        React.createElement(CopyButton, { value: tenant.PartnerSecret }, function (_a) {
                            var copied = _a.copied, copy = _a.copy;
                            return React.createElement(SubtitleButton, { faIcon: copied ? "check" : "save", color: "orange", onClick: copy, noMargin: true });
                        }))),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Partner Organization Name"),
                    React.createElement(Form.Control, { placeholder: "Enter Organization Name", 
                        //@ts-ignore
                        value: tenant.PartnerOrganizationName, onChange: function (event) { return dispatch(TenantCurrentStore.Update({ PartnerOrganizationName: event.target.value })); } })),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Partner Organization Email"),
                    React.createElement(Form.Control, { placeholder: "Enter Email", 
                        //@ts-ignore
                        value: tenant.PartnerSupportEmail, onChange: function (event) { return dispatch(TenantCurrentStore.Update({ PartnerSupportEmail: event.target.value })); } })))),
        React.createElement(TableList.Context, { listItems: partnerTenants === null || partnerTenants === void 0 ? void 0 : partnerTenants.PartnersTenants, showLoader: loadingPartnerTenants, wrappedInPortlet: false },
            React.createElement(TableList.TableList, null,
                React.createElement(TableList.HeaderItem, null, "Tenant"),
                React.createElement(TableList.HeaderItem, null, "Owner Email"),
                React.createElement(TableList.HeaderItem, null, "Installed Date"),
                React.createElement(TableList.HeaderItem, null, "Renewal Date"),
                React.createElement(TableList.HeaderItem, null, "Version"),
                React.createElement(TableList.HeaderItem, { className: "unlink-action" }, "Unlink"),
                React.createElement(TableList.ContentItem, { type: "strong", listDataProperyName: "TenantName", listDataSubProperyName: "Guid", isMobileTitle: true }),
                React.createElement(TableList.ContentItem, { type: "normal", listDataProperyName: "OwnerEmail" }),
                React.createElement(TableList.ContentItemDate, { listDataProperyName: "InstallDate" }),
                React.createElement(TableList.ContentItemDate, { listDataProperyName: "RenewalDate" }),
                React.createElement(TableList.ContentItemCustomRender, { render: function (item) { return PartnerToolsTableLicenseColumn(item.Version); } }),
                React.createElement(TableList.ContentItemActions, { customActionIcon: "fas fa-unlink", onCustomAction: function (item) { return disconnectPartnerTenant(item.Guid); } }))))) : (React.createElement(Container, { fluid: true, className: "tenant-details-partner-customer" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Connected Partner Tenant"),
                    React.createElement("div", { className: "inline-connected-status" }, tenant.AssociatedPartnerTenantGuid !== undefined && tenant.AssociatedPartnerTenantGuid !== null ? (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "fa-regular fa-square-check" }),
                        React.createElement("span", null, "Connected to partner tenant"))) : (React.createElement(React.Fragment, null,
                        React.createElement("i", { className: "fa-regular fa-square" }),
                        React.createElement("span", null, "Not connected to partner tenant"))))),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Connected Partner Tenant Name"),
                    React.createElement("div", { className: "inline-input-save-button" },
                        React.createElement(Form.Control, { placeholder: "", 
                            //@ts-ignore
                            value: tenant.AssociatedPartnerName, disabled: true }))),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Connected Management Secret"),
                    React.createElement(Form.Control, { placeholder: "Enter Organization Name", 
                        //@ts-ignore
                        value: tenant.AssociatedPartnerSecret, onChange: function (event) { return dispatch(TenantCurrentStore.Update({ PartnerOrganizationName: event.target.value })); }, disabled: true })),
                tenant.AssociatedPartnerTenantGuid !== undefined && tenant.AssociatedPartnerTenantGuid !== null ? (React.createElement(SubtitleButton, { title: "Disconnect from MTM Partner", faIcon: "unlink", variant: "primary", onClick: function () { return disconnectPartnerTenant(); }, noMargin: true })) : null))))));
});
export default OrchestryTenantsDetailsPartner;
