import * as React from "react";
import "./team-directory.scss";
import Directory from "app/components/directory";
import DirectoryAddExists from "app/components/directory/directory-components/directory-add-exists";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
var TeamDirectory = React.memo(function (props) {
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    return (React.createElement("div", { className: clsx("team-directory", { "team-directory-sharepoint": isInSharepoint }) },
        React.createElement("header", { style: { display: "none" } },
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: "orch-logo" },
                    React.createElement("div", { className: "icon" },
                        React.createElement("img", { src: "/logo192.png" }))),
                React.createElement("div", { className: "title" },
                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_directory.workspace_directory", defaultMessage: "Workspace Directory" }))),
            React.createElement("div", { className: "right" },
                React.createElement(DirectoryAddExists, null,
                    React.createElement("button", null,
                        React.createElement("i", { className: "fa fa-plus" }),
                        React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_directory.add_team_or_site", defaultMessage: "Add Existing Team or Site" }))))),
        React.createElement(Directory, { mode: "tab" })));
});
export default TeamDirectory;
