import { HealthCheckStartDate } from "./health-check-start-date.type";
/**
 * Maps the HealthCheckStartDate to an ISO string.
 * Subtracting 1 from the days because we only have data up to the previous day.
 * @param startDate The start date to map.
 */
export var mapHealthCheckStartDateToIsoString = function (startDate) {
    switch (startDate) {
        case HealthCheckStartDate["30DaysAgo"]:
            return new Date(new Date().setDate(new Date().getDate() - 31)).toISOString();
        case HealthCheckStartDate["60DaysAgo"]:
            return new Date(new Date().setDate(new Date().getDate() - 61)).toISOString();
        case HealthCheckStartDate["90DaysAgo"]:
            return new Date(new Date().setDate(new Date().getDate() - 91)).toISOString();
        case HealthCheckStartDate["1YearAgo"]:
            return new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setDate(new Date().getDate() - 1)).toISOString();
        default:
            return "";
    }
};
