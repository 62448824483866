var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo, useState } from "react";
var TenantEventTypeFilterList = [
    { title: "Install", value: "Install", colour: "var(--mantine-color-orange-8)" },
    { title: "Uninstall", value: "Uninstall", colour: "var(--mantine-color-red-8)" },
    { title: "Upgrade", value: "Upgrade", colour: "var(--mantine-color-green-8)" },
    { title: "Downgrade", value: "Downgrade", colour: "var(--mantine-color-blue-8)" },
    { title: "Unlicense", value: "Unlicense", colour: "var(--mantine-color-yellow-8)" }
];
var TenantEventPropertyFilterList = [{ title: "License Status", value: "LicenseStatus", colour: "var(--mantine-color-orange-8)" }];
var TenantEventChangeSourceFilterList = [
    { title: "Manual", value: "Manual", colour: "var(--mantine-color-orange-8)" },
    { title: "ETL", value: "ETL", colour: "var(--mantine-color-red-8)" },
    { title: "Installer", value: "Installer", colour: "var(--mantine-color-green-8)" }
];
export var useTenantEventBrowserFilters = function () {
    var TENANT_EVENT_TYPE_FILTERS = useMemo(function () {
        return TenantEventTypeFilterList.map(function (filter) {
            return {
                title: filter.title,
                value: filter.value,
                color: filter.colour,
                group: "EventType",
                type: "selectable",
                selected: false
            };
        });
    }, []);
    var TENANT_EVENT_PROPERTY_FILTERS = useMemo(function () {
        return TenantEventPropertyFilterList.map(function (filter) {
            return {
                title: filter.title,
                value: filter.value,
                color: filter.colour,
                group: "Property",
                type: "selectable",
                selected: false
            };
        });
    }, []);
    var TENANT_EVENT_CHANGE_SOURCE_FILTERS = useMemo(function () {
        return TenantEventChangeSourceFilterList.map(function (filter) {
            return {
                title: filter.title,
                value: filter.value,
                color: filter.colour,
                group: "ChangeSource",
                type: "selectable",
                selected: false
            };
        });
    }, []);
    var _a = useState(__spreadArray(__spreadArray(__spreadArray([], TENANT_EVENT_PROPERTY_FILTERS, true), TENANT_EVENT_TYPE_FILTERS, true), TENANT_EVENT_CHANGE_SOURCE_FILTERS, true)), appliedFilters = _a[0], setAppliedFilters = _a[1];
    var clearAllFilters = function () {
        setAppliedFilters(function (filters) {
            return filters.map(function (filter) {
                return __assign(__assign({}, filter), { selected: false });
            });
        });
    };
    var removeFilter = function (filter) {
        var selectableFilter = filter;
        var newAppliedFilters = appliedFilters.map(function (appliedFilter) { var _a, _b; return ((_a = appliedFilter.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = selectableFilter.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? __assign(__assign({}, appliedFilter), { selected: false }) : appliedFilter; });
        setAppliedFilters(newAppliedFilters);
    };
    return {
        appliedFilters: appliedFilters,
        setAppliedFilters: setAppliedFilters,
        clearAllFilters: clearAllFilters,
        removeFilter: removeFilter
    };
};
