import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import Authenticate from "app/components/authentication/authenticate";
import NotificationToast from "app/components/common/notification-toast";
import { useGrowthbook } from "app/utils/hooks/useGrowthbook";
import { orchestryModalDefinitions } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { orchestryTheme, resolver } from "../src/mantineTheme.config";
import { Routes } from "./app/router/Routes";
var App = React.memo(function (_a) {
    var store = _a.store, basename = _a.basename;
    var growthbook = useGrowthbook();
    React.useEffect(function () {
        growthbook.init();
    }, []);
    console.log(growthbook);
    return (React.createElement(React.Fragment, null,
        React.createElement(Provider, { store: store },
            React.createElement(MantineProvider, { cssVariablesResolver: resolver, theme: orchestryTheme },
                React.createElement(ModalsProvider, { modals: orchestryModalDefinitions, modalProps: { variant: "orchestry", centered: true, withCloseButton: false } },
                    React.createElement(GrowthBookProvider, { growthbook: growthbook },
                        React.createElement(BrowserRouter, { basename: basename },
                            React.createElement(LastLocationProvider, null,
                                React.createElement(Authenticate, null,
                                    React.createElement(Routes, null),
                                    React.createElement(NotificationToast, null))))))))));
});
export default App;
