var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./progress-report.scss";
import { Collapse } from "react-bootstrap";
import JobService from "app/services/jobs";
import AnimatedLoader from "../animated-loader";
import DynamicScrollbar from "../dynamic-scollbar";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
window.OrchestryProgressConsole = {
    ConsoleOpen: false,
    DoneJobs: []
};
var ProgressReport = React.memo(function (props) {
    var hideAllText = React.useState(props.hideAllText !== undefined ? props.hideAllText : false)[0];
    var _a = React.useState({}), jobProgress = _a[0], setJobProgress = _a[1];
    var _b = React.useState([]), jobProgressLog = _b[0], setJobProgressLog = _b[1];
    var _c = React.useState(props.showConsoleByDefault !== undefined ? props.showConsoleByDefault : false), consoleOpen = _c[0], setConsoleOpen = _c[1];
    var _d = React.useState(false), isJobDone = _d[0], setIsJobDone = _d[1];
    var _e = React.useState(false), isComplete = _e[0], setIsComplete = _e[1];
    var _f = React.useState(""), subJobTitle = _f[0], setSubJobTitle = _f[1];
    var timeoutRef = React.useRef(null);
    var jobDone = React.useRef(false);
    var intl = useIntl();
    React.useEffect(function () {
        console.log("Progress Console Loaded");
        window.OrchestryProgressConsole.ConsoleOpen = props.showConsoleByDefault !== undefined ? props.showConsoleByDefault : false;
        return function () {
            console.log("Progress Console Unloaded");
        };
    }, []);
    React.useEffect(function () {
        if (props.logs !== undefined) {
            console.log(props.logs);
            setJobProgressLog(props.logs);
        }
    }, [props.logs]);
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        function progressLoop() {
            //console.log("Progress Loop Running", props.jobId, isJobDone,);
            timerID = setTimeout(function () {
                // If there is a job ID and if the progress isn't done...
                if (props.jobId !== undefined && props.jobId !== null && props.jobId !== "" && isJobDone === false && !window.OrchestryProgressConsole.DoneJobs.some(function (i) { return i === props.jobId; })) {
                    if (window.OrchestryProgressConsole.ConsoleOpen === false) {
                        JobService.GetProgress(props.jobId)
                            .then(function (data) {
                            if (data !== undefined) {
                                var newJobProgress = data;
                                if (checkSetJobIsDone(newJobProgress) === false) {
                                    setJobProgress(newJobProgress);
                                }
                                else {
                                    setIsJobDone(true);
                                    setJobDone();
                                }
                            }
                            progressLoop();
                        })
                            .catch(function () {
                            console.log("Something went wrong trying to get latest job progress. Going to wait a bit and try again.");
                            setTimeout(function () {
                                progressLoop();
                            }, 30000);
                        });
                    }
                    else {
                        JobService.GetProgressWithLogs(props.jobId, jobProgressLog.length)
                            .then(function (data) {
                            if (data !== undefined) {
                                var newJobProgress = data;
                                if (checkSetJobIsDone(newJobProgress) === false) {
                                    setJobProgress(newJobProgress);
                                    if (Array.isArray(newJobProgress.Logs) && newJobProgress.Logs.length > 0) {
                                        setJobProgressLog(__spreadArray(__spreadArray([], jobProgressLog, true), newJobProgress.Logs, true));
                                    }
                                }
                                else {
                                    setIsJobDone(true);
                                    setJobDone();
                                }
                            }
                            progressLoop();
                        })
                            .catch(function () {
                            console.log("Something went wrong trying to get latest job progress. Going to wait a bit and try again.");
                            setTimeout(function () {
                                progressLoop();
                            }, 30000);
                        });
                    }
                }
                else {
                    progressLoop();
                }
            }, 3000);
        }
        progressLoop();
        return function cleanup() {
            //When we leave component we stop the timer
            if (timerID !== null) {
                clearInterval(timerID);
            }
        };
    }, [props.jobId, isJobDone, jobProgressLog, props.onComplete]);
    var checkSetJobIsDone = function (thisJobStatus) {
        var isDone = false;
        if (thisJobStatus.IsJobDone === true) {
            console.log("Job Done!");
            if (isJobDone === false) {
                isDone = true;
            }
        }
        return isDone;
    };
    var setJobDone = function () {
        if (!window.OrchestryProgressConsole.DoneJobs.some(function (i) { return i === props.jobId; }) && props.jobId !== undefined) {
            window.OrchestryProgressConsole.DoneJobs.push(props.jobId);
        }
        if (props.turnOffAfter !== undefined && props.turnOffAfter === true) {
            setIsComplete(true);
            setSubJobTitle("");
        }
        else {
            setIsComplete(true);
            setSubJobTitle("");
            setConsoleOpen(false);
            window.OrchestryProgressConsole.ConsoleOpen = false;
        }
        if (props.onComplete !== undefined) {
            props.onComplete();
        }
    };
    // const GetJobProgress = () => {
    //    // console.log("GetJobProgress > isComplete", isComplete)
    //    // console.log("GetJobProgress > timeoutRef", jobDone)
    //    if (
    //       props.jobId !== undefined &&
    //       props.jobId !== null &&
    //       props.jobId !== "" &&
    //       isComplete === false &&
    //       jobDone.current === false
    //    ) {
    //       if (window.consoleOpen === false) {
    //          JobService.GetProgress(props.jobId).then((data: Job) => {
    //             if (data !== undefined) {
    //                const newJobProgress = data;
    //                if (checkJobIsDone(newJobProgress) === false) {
    //                   setJobProgress(newJobProgress);
    //                }
    //             }
    //          }).finally(()=>{
    //             setTimeout(() => {
    //                GetJobProgress();
    //             }, 2000);
    //          })
    //       } else {
    //          JobService.GetProgressWithLogs(props.jobId, jobProgressLog.length).then((data: Job) => {
    //             if (data !== undefined) {
    //                const newJobProgress = data;
    //                if (checkJobIsDone(newJobProgress) === false) {
    //                   setJobProgress(newJobProgress);
    //                   if(Array.isArray(newJobProgress.Logs) && newJobProgress.Logs.length > 0){
    //                      setJobProgressLog([...jobProgressLog, ...newJobProgress.Logs]);
    //                   }
    //                }
    //             }
    //          }).finally(()=>{
    //             setTimeout(() => {
    //                GetJobProgress();
    //             }, 2000);
    //          })
    //       }
    //    }
    // }
    // React.useEffect(() => {
    //    // timeoutRef.current = setTimeout(() => {
    //    //    timeoutRef.current = null;
    //    //    GetJobProgress();
    //    // }, 2000);
    // }, [jobProgress, consoleOpen]);
    // React.useEffect(() => {
    //    GetJobProgress();
    //    // return () => {
    //    //    setIsComplete(true);
    //    // }
    // }, [props.jobId])
    // React.useEffect(() => {
    //    checkJobIsDone(jobProgress);
    // }, [jobProgress])
    // const checkJobIsDone = (thisJobStatus: Job) => {
    //    let isDone = false;
    //    if (thisJobStatus.IsJobDone === true) {
    //       console.log("Job DOne!")
    //       jobDone.current = true;
    //       if (timeoutRef.current !== null) {
    //          clearTimeout(timeoutRef.current);
    //       }
    //       if (isComplete === false) {
    //          setJobDone();
    //          isDone = true;
    //       }
    //    }
    //    console.log("Is Job Done", isDone, thisJobStatus);
    //    return isDone;
    // }
    // const setJobDone = () => {
    //    if (props.turnOffAfter !== undefined && props.turnOffAfter === true) {
    //       setIsComplete(true);
    //       //StopStatusLoop();
    //       setSubJobTitle("");
    //    } else {
    //       setIsComplete(true);
    //       //StopStatusLoop();
    //       setSubJobTitle("");
    //       setConsoleOpen(false)
    //       window.consoleOpen = false;
    //    }
    //    if (props.onComplete !== undefined) { props.onComplete(); }
    // }
    var GetCurrentStepPosition = function () {
        var currentStep = null;
        if (jobProgress !== undefined) {
            if (jobProgress.Steps !== undefined && jobProgress.Steps.length > 0) {
                jobProgress.Steps.forEach(function (step, stepIndex) {
                    if (step.Status === "InProgress" || step.Status === "Pending") {
                        if (currentStep === null) {
                            currentStep = stepIndex;
                        }
                    }
                });
            }
        }
        return currentStep;
    };
    var GetCurrentStepObject = function () {
        var currentStep = null;
        if (jobProgress !== undefined) {
            if (jobProgress.Steps !== undefined && jobProgress.Steps.length > 0) {
                var currentPosition = GetCurrentStepPosition();
                if (currentPosition !== null) {
                    currentStep = jobProgress.Steps[currentPosition];
                }
            }
        }
        return currentStep;
    };
    var CalculateMainProgressWidth = function () {
        var width = "0%";
        if (jobProgress !== undefined) {
            if (jobProgress.Steps !== undefined && jobProgress.Steps.length > 0) {
                var currentStepNumber = GetCurrentStepPosition();
                if (currentStepNumber !== null) {
                    var fullLength = jobProgress.Steps.length;
                    // if(fullLength === 1){
                    //     fullLength = fullLength + 2;
                    // }
                    // if(fullLength === 2){
                    //     fullLength = fullLength + 1;
                    // }
                    // if(fullLength === 3){
                    //     fullLength = fullLength + 1;
                    // }
                    // // if(fullLength === 4){
                    // //     fullLength = fullLength + 1;
                    // // }
                    // if(currentStepNumber === fullLength){
                    //     fullLength = fullLength + 1;
                    // }
                    width = (currentStepNumber / fullLength) * 100 + "%";
                }
                else {
                    width = "100%";
                }
            }
        }
        return width;
    };
    var showProgressBar = function () {
        var shouldShowProgressBar = false;
        if (jobProgress !== undefined) {
            //if (jobProgress.Steps !== undefined && jobProgress.Steps.length > 1) {
            if (jobProgress.Steps !== undefined) {
                shouldShowProgressBar = true;
            }
            //}
        }
        //console.log("showProgressBar", shouldShowProgressBar);
        return shouldShowProgressBar;
    };
    var GetCurrentSubStepPosition = function () {
        var currentStep = GetCurrentStepObject();
        var currentSubStep = null;
        if (currentStep !== null) {
            if (currentStep.SubSteps !== undefined && currentStep.SubSteps.length > 0) {
                currentStep.SubSteps.forEach(function (substep, stepIndex) {
                    if (substep.CompleteTasks === 0 && substep.TotalTasks === 0) {
                        if (substep.Status === "InProgress" || substep.Status === "Pending") {
                            if (currentSubStep === null) {
                                currentSubStep = stepIndex;
                            }
                        }
                    }
                    else {
                        if (substep.CompleteTasks !== substep.TotalTasks) {
                            if (currentSubStep === null) {
                                currentSubStep = stepIndex;
                            }
                        }
                    }
                });
            }
        }
        return currentSubStep;
    };
    var GetCurrentSubStepObject = function () {
        var currentSubStep = null;
        var currentSubPosition = GetCurrentSubStepPosition();
        var currentStep = GetCurrentStepObject();
        if (currentSubPosition !== null) {
            var pos = currentStep === null || currentStep === void 0 ? void 0 : currentStep.SubSteps[currentSubPosition];
            currentSubStep = pos !== undefined ? pos : null;
        }
        return currentSubStep;
    };
    var showSubProgressBar = function () {
        var shouldShowSubBar = false;
        var currentSubStep = GetCurrentSubStepObject();
        if (currentSubStep !== null) {
            shouldShowSubBar = true;
        }
        return shouldShowSubBar;
    };
    var CalculateSubProgressWidth = function () {
        var width = "0%";
        if (jobProgress !== undefined) {
            if (jobProgress.Steps !== undefined) {
                //const currentStep = GetCurrentStepObject();
                var currentSubStep = GetCurrentSubStepObject();
                if (currentSubStep !== null) {
                    if (currentSubStep.CompleteTasks === 0 && currentSubStep.TotalTasks === 0) {
                        var currentStep = GetCurrentStepObject();
                        var currentSubStepPosition = GetCurrentSubStepPosition();
                        if (currentStep !== null && currentSubStepPosition !== null) {
                            width = (currentSubStepPosition / (currentStep === null || currentStep === void 0 ? void 0 : currentStep.SubSteps.length)) * 100 + "%";
                        }
                    }
                    else {
                        if (currentSubStep.CompleteTasks !== currentSubStep.TotalTasks) {
                            width = (currentSubStep.CompleteTasks / currentSubStep.TotalTasks) * 100 + "%";
                        }
                        else {
                            width = "100%";
                        }
                    }
                }
            }
        }
        return width;
    };
    var renderProgressConsole = function (maxHeight, scrolledToBottom) {
        return (React.createElement("div", { className: "progress-console" },
            React.createElement("div", { className: "console-wrapper" },
                jobProgressLog === undefined || jobProgressLog.length === 0 ? React.createElement(AnimatedLoader, { size: "xxsmall" }) : null,
                React.createElement(DynamicScrollbar, { maxHeight: maxHeight === undefined ? 200 : maxHeight, shouldShow: true, keepScrolledToBottom: scrolledToBottom === undefined ? true : scrolledToBottom }, jobProgressLog !== undefined
                    ? jobProgressLog.map(function (log, logIndex) { return (React.createElement("div", { className: "console-line", key: logIndex },
                        React.createElement("span", { className: "cl-date-time" }, moment
                            .utc(log.DateTime)
                            .local()
                            .format("HH:mm:ss")),
                        React.createElement("div", { className: "right-side" },
                            React.createElement("span", { className: "cl-message" + (log.StatusType !== undefined && log.StatusType !== null && log.StatusType !== "" ? " type-" + log.StatusType.toLowerCase() : "") },
                                log.Message,
                                " "),
                            log.Activity !== undefined && log.Activity !== "" ? (React.createElement("span", { className: "cl-activity" },
                                React.createElement("i", { className: "la la-angle-right" }),
                                " ",
                                log.Activity,
                                " ")) : null,
                            React.createElement("span", { className: "cl-status" },
                                " ",
                                log.StatusType)))); })
                    : null))));
    };
    return (React.createElement("div", { className: "progress-report", "data-pw": "progress-report" }, props.logs === undefined ? (React.createElement(React.Fragment, null,
        hideAllText === false ? (React.createElement(React.Fragment, null,
            props.title !== undefined ? (React.createElement("div", { className: "progress-title" },
                React.createElement("h2", null, props.title))) : null,
            props.subtitle !== undefined && isComplete === false ? React.createElement("div", { className: "progress-subtitle" }, props.subtitle) : null)) : null,
        React.createElement(Collapse, { appear: true, in: (jobProgress.Id !== undefined && isComplete === false) ||
                (jobProgress.Id !== undefined && props.turnOffAfter !== undefined && props.turnOffAfter === false) ||
                (props.showBeforeJobId !== undefined && props.showBeforeJobId === true) },
            React.createElement("div", null,
                React.createElement(Collapse, { appear: true, in: props.showBeforeJobId !== undefined && props.showBeforeJobId === true && showProgressBar() === false },
                    React.createElement("div", { className: "progress-fullbar" },
                        React.createElement("div", { className: "progress m-progress-lg" },
                            React.createElement("div", { className: "progress-left" }),
                            React.createElement("div", { className: "progress-right" },
                                React.createElement("div", { className: "progress-spinner" },
                                    React.createElement(AnimatedLoader, { size: "xsmall" })))))),
                React.createElement(Collapse, { appear: true, in: showProgressBar() && (props.hideAllProgressBars === undefined || (props.hideAllProgressBars !== undefined && props.hideAllProgressBars === false)) },
                    React.createElement("div", { className: "progress-fullbar" + (props.showOnlySubBar !== undefined && props.showOnlySubBar === true ? " main-bar-hidden" : "") },
                        isComplete === false ? (React.createElement(React.Fragment, null,
                            React.createElement(React.Fragment, null, GetCurrentStepObject() !== null && hideAllText === false ? React.createElement("div", { className: "task-title" },
                                " ",
                                GetCurrentStepObject().Title,
                                " ") : null),
                            !(props.showOnlySubBar !== undefined && props.showOnlySubBar === true) ? (React.createElement("div", { className: "progress m-progress-lg" },
                                React.createElement("div", { className: "progress-left" },
                                    React.createElement("div", { className: "progress-wrapper", style: { width: CalculateMainProgressWidth() } },
                                        React.createElement("div", { className: "progress-bar progress-bar-striped progress-bar-animated bg-success", role: "progressbar" }))),
                                React.createElement("div", { className: "progress-right" },
                                    React.createElement("div", { className: "progress-spinner", "data-pw": "progress-spinner" },
                                        React.createElement(AnimatedLoader, { size: "xsmall" }))))) : null)) : (React.createElement("div", { className: "progress m-progress-lg" },
                            React.createElement("div", { className: "progress-wrapper", style: { width: "100%" } },
                                React.createElement("div", { className: "progress-bar progress-bar-striped bg-success", role: "progressbar" },
                                    React.createElement("i", { className: "fa fa-check" }))))),
                        React.createElement(Collapse, { appear: true, in: showSubProgressBar() && isComplete === false },
                            React.createElement("div", { className: "sub-progress-bar" },
                                GetCurrentSubStepObject() !== null && hideAllText === false ? (React.createElement("div", { className: "sub-task-title" },
                                    " ",
                                    GetCurrentSubStepObject().Title,
                                    " ",
                                    GetCurrentSubStepObject().CurrentTaskTitle !== undefined ? React.createElement("span", null,
                                        " > ",
                                        GetCurrentSubStepObject().CurrentTaskTitle) : "",
                                    " ")) : null,
                                React.createElement("div", { className: "progress" },
                                    React.createElement("div", { className: "progress-wrapper", style: { width: CalculateSubProgressWidth() } },
                                        React.createElement("div", { className: "progress-bar progress-bar-striped progress-bar-animated bg-warning", role: "progressbar" }))))))))),
        props.hideProgressLog !== undefined && props.hideProgressLog === true ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: !(props.hideAllProgressBars === undefined || (props.hideAllProgressBars !== undefined && props.hideAllProgressBars === false)) ? "only-console" : "" },
            React.createElement("div", { className: "progress-concole-toggle" + (consoleOpen ? " open" : ""), onClick: function () {
                    setConsoleOpen(!consoleOpen);
                    window.OrchestryProgressConsole.ConsoleOpen = !consoleOpen;
                } },
                React.createElement("i", { className: "fa fa-chevron-right" }),
                subJobTitle !== "" && consoleOpen === false ? React.createElement("span", { className: "sub-progress-title" }, subJobTitle) : React.createElement("span", { className: "console-title", "data-pw": "progress-console" },
                    React.createElement(FormattedMessage, { id: "general.label.progress_console", defaultMessage: "Progress Console" }))),
            React.createElement(Collapse, { appear: true, in: consoleOpen }, renderProgressConsole()))))) : (React.createElement("div", null, renderProgressConsole(700, true)))));
});
export default ProgressReport;
