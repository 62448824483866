var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import "./orchestry-localization-editor.scss";
import { Row, Col, Form } from "react-bootstrap";
import { LocalizationService } from "app/services/localization";
import * as PageHeader from "app/components/common/page-header";
import { SubtitleButton } from "app/components/common/subtitle";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { Portlet, PortletBody } from "app/components/common/portlet";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import NoResults from "app/components/common/no-results";
import { useSelector } from "react-redux";
import OrchestryLocalizationEditorUsers from "app/components/orchestry-admin/orchestry-localization-editor/components/orchestry-localization-editor-users";
import OrchestryLocalizationCacheBusting from "app/components/orchestry-admin/orchestry-localization-editor/components/orchestry-localization-cache-busting";
var OrchestryLocalizationEditor = React.memo(function () {
    var _a = React.useState(null), items = _a[0], setItems = _a[1];
    var _b = React.useState(false), orchAddModalOpen = _b[0], setOrchAddModalOpen = _b[1];
    var _c = React.useState(false), orchUsersModalOpen = _c[0], setOrchUsersModalOpen = _c[1];
    var _d = React.useState(false), orchCacheBustingModalOpen = _d[0], setOrchCacheBustingModalOpen = _d[1];
    var _e = React.useState(null), editingItem = _e[0], setEditingItem = _e[1];
    var _f = React.useState(false), isEditNew = _f[0], setIsEditNew = _f[1];
    var _g = React.useState("all"), currentFilter = _g[0], setCurrentFilter = _g[1];
    var _h = React.useState(""), searchTerm = _h[0], setSearchTerm = _h[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _j = React.useState("fr"), editingLang = _j[0], setEditingLang = _j[1];
    var _k = React.useState(false), isOrchestryInternalAdmin = _k[0], setIsOrchestryInternalAdmin = _k[1];
    React.useEffect(function () {
        if (orchestryContext !== undefined) {
            setIsOrchestryInternalAdmin(orchestryContext.IsOrchestryMaintenance);
        }
    }, [orchestryContext]);
    React.useEffect(function () {
        LocalizationService.getLocalizedLabelsFromServer(null).then(function (items) {
            setItems(items);
        });
    }, []);
    React.useEffect(function () {
        if (orchestryContext !== undefined) {
            setEditingLang(orchestryContext.IsOrchestryMaintenance === true ? "fr" : orchestryContext.LangaugeEditor.length > 0 ? orchestryContext.LangaugeEditor[0] : "fr");
        }
    }, [orchestryContext]);
    var AddNewOrUpdateItem = function () {
        if (editingItem !== null) {
            LocalizationService.updateLocalizedLabelOnServer(editingItem).then(function () {
                setOrchAddModalOpen(false);
                if (items === null) {
                    setItems([editingItem]);
                }
                else {
                    if (items.some(function (i) { return i.Key === editingItem.Key; }) === false) {
                        setItems(__spreadArray(__spreadArray([], items, true), [editingItem], false));
                    }
                    else {
                        setItems(items.map(function (i) { return (i.Key === editingItem.Key ? __assign({}, editingItem) : __assign({}, i)); }));
                    }
                }
            });
        }
    };
    var openEditModal = function (item) {
        if (item === undefined || (item !== undefined && item === null)) {
            setIsEditNew(true);
            setEditingItem({
                Key: ""
            });
        }
        else {
            setIsEditNew(false);
            setEditingItem(item);
        }
        setOrchAddModalOpen(true);
    };
    var getShowLanguages = function () {
        if (orchestryContext.IsOrchestryMaintenance) {
            return LocalizationService.AllLangauges.filter(function (i) { return i.lang !== "en"; });
        }
        else {
            return LocalizationService.AllLangauges.filter(function (i) { var _a; return (_a = orchestryContext.LangaugeEditor) === null || _a === void 0 ? void 0 : _a.some(function (le) { return le === i.lang; }); }).filter(function (i) { return i.lang !== "en"; });
        }
        //return LocalizationService.AllLangauges.filter(i => i.lang !== "en");
    };
    var getValueFromLangItem = function (langItem, langCode) {
        if (langItem[langCode] !== undefined) {
            return langItem[langCode];
        }
        else {
            return "";
        }
    };
    var getEditingLangName = function () {
        var _a;
        if (editingLang !== null) {
            var foundItem = LocalizationService.AllLangauges.find(function (i) { return i.lang === editingLang; });
            if (foundItem !== undefined) {
                return foundItem.name;
            }
        }
        return (_a = LocalizationService.AllLangauges.find(function (i) { return i.lang === "en"; })) === null || _a === void 0 ? void 0 : _a.name;
    };
    var getEditingLangCode = function () {
        if (editingLang !== null) {
            var foundItem = LocalizationService.AllLangauges.find(function (i) { return i.lang === editingLang; });
            if (foundItem !== undefined) {
                return foundItem.lang;
            }
        }
        return "en";
    };
    var getListItems = function () {
        var returnItems = items;
        if (currentFilter === "missing" && returnItems !== null) {
            returnItems = returnItems.filter(function (i) { return i[editingLang] === null || i[editingLang] === ""; });
        }
        if (searchTerm !== null && searchTerm !== "" && returnItems !== null) {
            returnItems = returnItems.filter(function (li) {
                var matchedItem = false;
                LocalizationService.AllLangauges.forEach(function (al) {
                    if (li[al.lang] !== undefined && li[al.lang] !== null) {
                        if (li[al.lang].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                            matchedItem = true;
                        }
                    }
                });
                if (li.Key.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                    matchedItem = true;
                }
                return matchedItem;
            });
        }
        return returnItems;
    };
    return (React.createElement("div", { className: "orchestry-localization-editor fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Localization", subTitle: "Editor" }),
        React.createElement(PageHeader.SetRightContent, null,
            React.createElement(SubtitleButton, { title: "Update Server Caches", variant: "dark", faIcon: "lemon", onClick: function () { return setOrchCacheBustingModalOpen(true); } }),
            orchestryContext.IsOrchestryMaintenance === true ? (React.createElement(React.Fragment, null,
                React.createElement(SubtitleButton, { title: "Config Localization Editors", color: "orange", onClick: function () { return setOrchUsersModalOpen(true); } }),
                React.createElement(SubtitleButton, { title: "Add Translation String", onClick: function () { return openEditModal(); } }))) : null),
        React.createElement(OrchModal, { showModalOverride: orchUsersModalOpen, showModalToggleOverride: setOrchUsersModalOpen, showCloseButton: true, headerText: "Localization Editors" }, orchUsersModalOpen === true ? React.createElement(OrchestryLocalizationEditorUsers, null) : null),
        React.createElement(OrchModal, { showModalOverride: orchCacheBustingModalOpen, size: "lg", showModalToggleOverride: setOrchCacheBustingModalOpen, showCloseButton: true, headerText: "Cache Busting" }, orchCacheBustingModalOpen === true ? React.createElement(OrchestryLocalizationCacheBusting, null) : null),
        React.createElement(OrchModal, { showModalOverride: orchAddModalOpen, showModalToggleOverride: setOrchAddModalOpen }, editingItem !== null ? (React.createElement(React.Fragment, null,
            React.createElement(Form.Group, { className: "form-group" },
                React.createElement(Form.Label, null, "Key"),
                React.createElement(Form.Control, { placeholder: "Enter Key", disabled: !isEditNew, value: editingItem.Key, onChange: function (event) {
                        setEditingItem(__assign(__assign({}, editingItem), { Key: event.target.value }));
                    } })),
            isEditNew === true ? (React.createElement(Form.Group, { className: "form-group" },
                React.createElement(Form.Label, null, "English"),
                React.createElement(Form.Control, { placeholder: "Please enter English label", value: editingItem.en !== undefined && editingItem.en !== null ? editingItem.en : "", onChange: function (event) {
                        setEditingItem(__assign(__assign({}, editingItem), { en: event.target.value }));
                    } }))) : (React.createElement(React.Fragment, null,
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, "English"),
                    React.createElement(Form.Control, { disabled: !isOrchestryInternalAdmin, value: editingItem.en !== undefined && editingItem.en !== null ? editingItem.en : "", onChange: function (event) {
                            setEditingItem(__assign(__assign({}, editingItem), { en: event.target.value }));
                        } })),
                React.createElement(Form.Group, { className: "form-group" },
                    React.createElement(Form.Label, null, getEditingLangName()),
                    React.createElement(Form.Control, { placeholder: "Please enter " + getEditingLangName() + " label", value: editingItem[getEditingLangCode()] === null ? "" : editingItem[getEditingLangCode()], onChange: function (event) {
                            var ei = __assign({}, editingItem);
                            ei[getEditingLangCode()] = event.target.value;
                            setEditingItem(ei);
                        } })))),
            React.createElement(SubtitleButton, { title: isEditNew === true ? "Add New Item" : "Save Edits", onClick: function () { return AddNewOrUpdateItem(); }, noMargin: true }))) : null),
        React.createElement(Row, null,
            React.createElement(Col, { md: 7 },
                React.createElement("div", { className: "lang-pills" + (getShowLanguages().length === 1 ? " single-item" : "") }, getShowLanguages().map(function (l) { return (React.createElement("div", { className: "nav-item " + (editingLang === l.lang ? "selected" : ""), onClick: function () { return setEditingLang(l.lang); } }, l.name)); }))),
            React.createElement(Col, null,
                React.createElement("div", { className: "searchbox" },
                    React.createElement(Form.Control, { value: searchTerm, placeholder: "Search text..", onChange: function (event) {
                            setSearchTerm(event.target.value);
                        } }))),
            React.createElement(Col, null,
                React.createElement("div", { className: "filters" },
                    React.createElement("div", { className: "filter-icon" }),
                    React.createElement("div", { className: "filters" },
                        React.createElement(FormDropdownSelect, { list: [
                                {
                                    title: "All Items",
                                    id: "0",
                                    value: "all"
                                },
                                {
                                    title: "Missing Items",
                                    id: "2",
                                    value: "missing"
                                }
                            ], value: currentFilter, onChange: function (value) {
                                setCurrentFilter(value);
                            } }))))),
        React.createElement(Portlet, null,
            React.createElement(PortletBody, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12 }, getListItems() === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 32, bodyWidth: 237 })) : (React.createElement(React.Fragment, null,
                        React.createElement(Row, { className: "value-titles" },
                            React.createElement(Col, { md: 5 }, "English"),
                            React.createElement(Col, { md: 5 }, getEditingLangName()),
                            React.createElement(Col, { md: 2 }, "Other Langs")),
                        getListItems().map(function (langItem) { return (React.createElement(Row, { className: "value-edit", onClick: function () { return openEditModal(langItem); } },
                            React.createElement(Col, { md: 5 }, langItem["en"]),
                            React.createElement(Col, { md: 5 }, langItem[getEditingLangCode()] === null || langItem[getEditingLangCode()] === "" ? (React.createElement("div", { className: "no-value" }, "-- empty -- ")) : (React.createElement(React.Fragment, null, langItem[getEditingLangCode()]))),
                            React.createElement(Col, { md: 2, className: "lang-stats" }, getShowLanguages().map(function (li) { return (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "lang-status " + (langItem[li.lang] !== undefined && langItem[li.lang] !== null && langItem[li.lang] !== "" ? "lang-green" : "lang-red") }, li.lang))); })))); }),
                        getListItems().length === 0 ? (React.createElement("div", { style: { padding: "30px 0 0 0" } },
                            React.createElement(NoResults, null))) : null))))))));
});
export default OrchestryLocalizationEditor;
