import { Skeleton, Text } from "@mantine/core";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryUnlockFullAccessOverlay } from "appv2/components/orchestry-unlock-full-access-overlay/orchestry-unlock-full-access-overlay.component";
import React from "react";
import { useRecommendationCardResolveModal } from "./recommendation-card-resolve-modal.hook";
import "./recommendation-card-resolve-modal.scss";
export var ResolveRecommendationModalContent = function (_a) {
    var context = _a.context, id = _a.id, innerProps = _a.innerProps;
    var _b = useRecommendationCardResolveModal({
        context: context,
        recommendation: innerProps.recommendation,
        modalId: id
    }), isSuccess = _b.isSuccess, isLoading = _b.isLoading, onSubmitClick = _b.onSubmitClick, currentUserIsBeaconUser = _b.currentUserIsBeaconUser, beaconRemediationSteps = _b.beaconRemediationSteps;
    return (React.createElement("div", { className: "recommendation-card-resolve-modal" },
        React.createElement("div", { className: "header" },
            React.createElement(OrchestryFeaturedIcon, { colorVariant: "success-6", size: "xl", variant: "double-circle", imgIconSrc: ORCH_SVG_ICONS.Logomark }),
            React.createElement(Text, { c: "text-primary.0", size: "lg", className: "title" }, "How to Remediate")),
        React.createElement(Text, { size: "sm", c: "text-tertiary.0", className: "description" }, "Follow these steps to resolve this recommendation."),
        React.createElement("div", { className: "resolve-steps" }, currentUserIsBeaconUser ? (React.createElement(React.Fragment, null,
            React.createElement(OrchestryUnlockFullAccessOverlay, { showIcon: true }),
            React.createElement("div", { className: "steps", dangerouslySetInnerHTML: { __html: beaconRemediationSteps } }))) : (React.createElement(React.Fragment, null,
            innerProps.recommendation.Recommendation.RemediationEmbed && (React.createElement("div", { className: "video", dangerouslySetInnerHTML: { __html: innerProps.recommendation.Recommendation.RemediationEmbed } })),
            innerProps.recommendation.Recommendation.RemediationSteps && (React.createElement("div", { className: "steps", dangerouslySetInnerHTML: { __html: innerProps.recommendation.Recommendation.RemediationSteps } })),
            innerProps.recommendation.Recommendation.RemediationGuideLink && (React.createElement("div", { className: "link" },
                React.createElement(OrchestryButton, { variant: "grey-light", component: "a", href: innerProps.recommendation.Recommendation.RemediationGuideLink, target: "_blank" }, "Access Full Guide")))))),
        React.createElement("div", { className: "bottom-buttons" },
            React.createElement(OrchestryButton, { className: "cancel", size: "md", variant: "link-gray", onClick: function () { return context.closeModal(id); } }, "Cancel"),
            !currentUserIsBeaconUser && (React.createElement(React.Fragment, null,
                innerProps.recommendation.Recommendation.OrchestryReportLink && (React.createElement(OrchestryButton, { target: "_blank", href: innerProps.recommendation.Recommendation.OrchestryReportLink, iconType: "svg", iconLeftBase: ORCH_SVG_ICONS.LogoMarkColour, component: "a", className: "report", size: "md", variant: "secondary-gray" }, "Orchestry Report")),
                isLoading ? (React.createElement(Skeleton, { height: 36, width: "150px" })) : (React.createElement(OrchestryButton, { size: "md", variant: "primary", onClick: onSubmitClick }, "Mark as Resolved")))))));
};
