import AnimatedLoader from "app/components/common/animated-loader";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import InstallService from "app/services/install";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Collapse, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./status.access-code.component.scss";
var StatusAccessCode = React.memo(function () {
    var _a = React.useState(""), accessCode = _a[0], setAccessCode = _a[1];
    var _b = React.useState(false), verifying = _b[0], setVerifying = _b[1];
    var _c = React.useState(), verified = _c[0], setVerified = _c[1];
    var inputEl = React.useRef();
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _d = React.useState(null), installedBy = _d[0], setInstalledBy = _d[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        if (currentTenant !== undefined && currentTenant.GUID !== undefined) {
            if (currentTenant.InstallCode !== undefined && currentTenant.InstallCode !== null) {
                setAccessCode(currentTenant.InstallCode);
            }
        }
    }, [currentTenant]);
    React.useEffect(function () {
        if (accessCode !== "") {
            setVerifying(true);
            setInstalledBy(null);
            setTimeout(function () {
                if (accessCode === inputEl.current.value) {
                    InstallService.IsAccessCodeCorrect(accessCode).then(function (reply) {
                        setVerifying(false);
                        var replyAccess = reply;
                        if (replyAccess.accessCode !== undefined && replyAccess.accessCode === "6UryJMwByQSqdC3L") {
                            setVerified(true);
                        }
                        else {
                            setVerified(false);
                        }
                        dispatch(TenantCurrentStore.Update({ InstallCode: accessCode }));
                        if (reply.installedBy !== undefined && reply.installedBy !== null) {
                            setInstalledBy(reply.installedBy);
                        }
                    });
                }
            }, 500);
        }
        else {
            setVerified(undefined);
        }
    }, [accessCode]);
    var handleKeyDown = function (e) {
        //console.log(e.keyCode)
        // //const { cursor, result } = this.state
        // // arrow up/down button should select next/previous list element
        if (e.keyCode === 13) {
            e.preventDefault();
            if (verified) {
                //iwc.gotoNextStep();
            }
        }
        //return false;
    };
    return (React.createElement("div", { className: "tenant-details-access-code fadein-500ms" },
        React.createElement("div", { className: "access-code" }, currentTenant !== undefined && currentTenant.GUID !== undefined ? (React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
            React.createElement(Form.Label, null, "License Key"),
            React.createElement("div", { className: "form-input-with-icon" },
                React.createElement(Form.Control, { placeholder: "Enter Diplay Name", ref: inputEl, spellCheck: "false", autoComplete: "off", 
                    //@ts-ignore
                    value: accessCode, onChange: function (event) { return setAccessCode(event.target.value); }, onKeyDown: function (event) {
                        handleKeyDown(event);
                    } }),
                verifying === true ? (React.createElement("div", { className: "icon" },
                    React.createElement(AnimatedLoader, { size: "xxsmall" }))) : null,
                verifying === false && verified === true ? (React.createElement("div", { className: "icon yes" },
                    React.createElement("i", { className: "fas fa-check" }))) : verifying === false && verified === false ? (React.createElement("div", { className: "icon no" },
                    React.createElement("i", { className: "fas fa-times" }))) : null),
            React.createElement(Collapse, { appear: true, in: installedBy !== null },
                React.createElement("div", { className: "access-code-provided-by" },
                    "License Provider ",
                    React.createElement("span", null, installedBy))))) : (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 34, bodyWidth: 350 })))));
});
export default StatusAccessCode;
