import React from "react";
import { IntlProvider } from "react-intl";
import { RecommendationViewDetailsModalContent } from "./recommendation-card-view-details-modal-content.component";

/**
 * This wrapper component is used to provide the necessary context to the AddAnnotationModalContent component
 * because some of it's subcomponents use a useIntl hook which requires the IntlProvider to be present in the component tree.
 */
export const RecommendationViewDetailsModal = props => {
   const {
      context,
      id,
      innerProps: {
         recommendation: { Recommendation, Vote, RecommendationRecord },
         intl
      }
   } = props;
   const children = <RecommendationViewDetailsModalContent context={context} id={id} innerProps={{ recommendation: { intl, Recommendation, Vote, RecommendationRecord } }} />;

   return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
         {children}
      </IntlProvider>
   );
};
