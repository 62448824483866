var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, Skeleton, createPolymorphicComponent } from "@mantine/core";
import React from "react";
import { OrchestryButtonIcon } from "./orchestry-button-icon";
export var OrchestryButton = createPolymorphicComponent(React.forwardRef(function (props, ref) {
    var _a;
    var iconType = props.iconType, children = props.children;
    // Remove icon settings from props so they don't get spread to the button, causing an error
    var nonIconSettingsProps = (function () {
        if (iconType === "font") {
            var iconType_1 = props.iconType, iconLeft = props.iconLeft, iconRight = props.iconRight, iconFontSize = props.iconFontSize, iconColor = props.iconColor, isLoading = props.isLoading, skeletonHeight = props.skeletonHeight, skeletonWidth = props.skeletonWidth, rest = __rest(props, ["iconType", "iconLeft", "iconRight", "iconFontSize", "iconColor", "isLoading", "skeletonHeight", "skeletonWidth"]);
            return rest;
        }
        else if (iconType === "svg") {
            var iconType_2 = props.iconType, iconLeftBase = props.iconLeftBase, iconLeftActive = props.iconLeftActive, iconRightBase = props.iconRightBase, iconRightActive = props.iconRightActive, iconFontSize = props.iconFontSize, iconColor = props.iconColor, isLoading = props.isLoading, skeletonHeight = props.skeletonHeight, skeletonWidth = props.skeletonWidth, rest = __rest(props, ["iconType", "iconLeftBase", "iconLeftActive", "iconRightBase", "iconRightActive", "iconFontSize", "iconColor", "isLoading", "skeletonHeight", "skeletonWidth"]);
            return rest;
        }
        return props;
    })();
    if (props.isLoading) {
        //This could maybe be done more intelligently like OrchestryText
        return React.createElement(Skeleton, __assign({ height: props.skeletonHeight, width: props.skeletonWidth }, nonIconSettingsProps));
    }
    return (React.createElement(Button, __assign({ style: { transition: "0.3s all", opacity: (_a = props.fadeTransitionOpacity) !== null && _a !== void 0 ? _a : 1, cursor: props.disabled ? "not-allowed" : "pointer" }, leftSection: props.iconType === "font"
            ? props.iconLeft && React.createElement(OrchestryButtonIcon, { iconName: props.iconLeft, type: "font", iconColor: props.iconColor, iconFontSize: props.iconFontSize })
            : props.iconType === "svg"
                ? props.iconLeftBase && React.createElement(OrchestryButtonIcon, { baseIconName: props.iconLeftBase, activeIconName: props.iconLeftActive, type: "svg" })
                : null, rightSection: props.iconType === "font"
            ? props.iconRight && React.createElement(OrchestryButtonIcon, { iconName: props.iconRight, type: "font", iconColor: props.iconColor, iconFontSize: props.iconFontSize })
            : props.iconType === "svg"
                ? props.iconRightBase && React.createElement(OrchestryButtonIcon, { baseIconName: props.iconRightBase, activeIconName: props.iconRightActive, type: "svg" })
                : null, "data-icon-type": props.iconType, "data-icon-size": props.iconSize }, nonIconSettingsProps, { ref: ref }), children));
}));
