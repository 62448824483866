import React from "react";
import "./orchestry-check-admin.scss";
import { toAbsoluteUrl } from "_metronic";
import { useSelector } from "react-redux";
var OrchestryCheckAdmin = React.memo(function (props) {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(false), IsUserAdmin = _a[0], setIsUserAdmin = _a[1];
    React.useEffect(function () {
        if (orchestryContext.IsCurrentUserAzureGlobalAdmin || orchestryContext.IsCurrentUserOrchestryAdmin || orchestryContext.IsOrchestryMaintenance) {
            setIsUserAdmin(true);
        }
    }, [orchestryContext]);
    if (IsUserAdmin) {
        return React.createElement(React.Fragment, null, props.children);
    }
    else {
        return (React.createElement("div", { className: "orchestry-check-admin" },
            React.createElement("img", { src: toAbsoluteUrl("/logo192.png"), alt: "orchestyr logo", style: { marginBottom: "20px", width: "100px" } }),
            React.createElement("div", { className: "title" }, "You don't have sufficient privileges"),
            React.createElement("div", { className: "sub-title" }, "Sorry, your account has not been granted access to the Orchestry management system")));
    }
});
export default OrchestryCheckAdmin;
