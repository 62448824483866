import { useDeleteAnnotationMutation } from "appv2/api/health-checks/health-checks.api";
export var useDeleteAnnotationModal = function (tenantGuid, category, body, associatedGuid, healthCheckGuid) {
    var _a = useDeleteAnnotationMutation(), deleteAnnotation = _a[0], _b = _a[1], isLoading = _b.isLoading, isSuccess = _b.isSuccess;
    var onDeleteClicked = function (uniqueId) {
        deleteAnnotation({
            userType: tenantGuid ? "partner" : "customer",
            deleteAnnotationCommand: { UniqueId: uniqueId, TenantGUID: tenantGuid },
            category: category,
            associatedGuid: associatedGuid,
            healthCheckGuid: healthCheckGuid
        });
    };
    var MAX_BODY_LENGTH = 30;
    var bodyShortened = body.length > MAX_BODY_LENGTH ? "".concat(body.substring(0, MAX_BODY_LENGTH), "...") : body;
    return {
        onDeleteClicked: onDeleteClicked,
        isLoading: isLoading,
        isSuccess: isSuccess,
        bodyShortened: bodyShortened
    };
};
