import { useState } from "react";
/**
 * Hook to determine if the page is loading. It counts the number of active requests
 * and returns true if there is at least one active request.
 * Used for aggressive loading indicators for things like PDF generation.
 */
export var useIsPageLoading = function () {
    var _a = useState(0), activeRequests = _a[0], setActiveRequests = _a[1];
    // Save the original fetch function
    var originalFetch = window.fetch;
    window.fetch = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setActiveRequests(function (prev) { return prev + 1; });
        var fetchPromise = originalFetch.apply(void 0, args);
        fetchPromise.finally(function () {
            setActiveRequests(function (prev) { return prev - 1; });
        });
        return fetchPromise;
    };
    // Save the original XMLHttpRequest.send function
    var originalXhrSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setActiveRequests(function (prev) { return prev + 1; });
        this.addEventListener("loadend", function () {
            setActiveRequests(function (prev) { return prev - 1; });
        });
        return originalXhrSend.apply(this, args);
    };
    return activeRequests > 0;
};
