import { Group } from "@mantine/core";
import ORCH_SVG_ICONS from "appv2/assets/icons/orchestry-svgs/svg-icons";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryCombobox } from "appv2/components/orchestry-combobox/orchestry-combobox.component";
import { OrchestryFontIcon } from "appv2/components/orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryInformationBubble } from "appv2/components/orchestry-information-bubble/orchestry-information-bubble.component";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { OrchestryThumbs } from "appv2/components/orchestry-thumbs/orchestry-thumbs.component";
import { OrchestryTooltipGroup } from "appv2/components/orchestry-tooltip-group/orchestry-tooltip-group.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import "./recommendation-card-action-buttons-section.scss";
import { useRecommendationCardActionButtonsSection } from "./use-recommendation-card-action-buttons-section";
export var RecommendationCardActionButtonSection = function (_a) {
    var recommendation = _a.recommendation;
    var _b = useRecommendationCardActionButtonsSection(recommendation), showViewDetailsButton = _b.showViewDetailsButton, showResolveButton = _b.showResolveButton, showSnoozeDropdown = _b.showSnoozeDropdown, isSnoozed = _b.isSnoozed, isResolved = _b.isResolved, options = _b.options, onSectionClick = _b.onSectionClick, onReopenClick = _b.onReopenClick, onOptionClick = _b.onOptionClick, tooltipVisible = _b.tooltipVisible, setTooltipVisible = _b.setTooltipVisible, thumbOnClick = _b.thumbOnClick, onDeleteRecommendationClicked = _b.onDeleteRecommendationClicked, onEditRecommendationClicked = _b.onEditRecommendationClicked, onResolveClick = _b.onResolveClick, isCustom = _b.isCustom, intl = _b.intl, reopenToolTip = _b.reopenToolTip;
    return (React.createElement("div", { className: "recommendation-card-action-button-section", onClick: onSectionClick },
        React.createElement("div", { className: "secondary-section" }, showSnoozeDropdown && (React.createElement(OrchestryTooltip, { withinPortal: false, label: "Click to hide this recommendation from view", hidden: !tooltipVisible },
            React.createElement(OrchestryCombobox, { forcePlaceholder: true, placeholder: "Snooze for...", variant: "blue", iconClosed: ORCH_ICONS.chevronDown, iconOpened: ORCH_ICONS.chevronUp, options: options, onOptionClick: onOptionClick, setTooltipVisible: setTooltipVisible })))),
        React.createElement("div", { className: "primary-section" },
            isCustom ? (React.createElement(Group, { pr: 8 },
                React.createElement(OrchestryFontIcon, { onClick: onEditRecommendationClicked, iconClassName: ORCH_ICONS.edit02, fontSize: 18, lineHeight: "18px", color: "var(--mantine-color-gray-5)" }),
                React.createElement(OrchestryFontIcon, { onClick: onDeleteRecommendationClicked, iconClassName: ORCH_ICONS.trash02, fontSize: 18, lineHeight: "18px", color: "var(--mantine-color-gray-5)" }))) : (React.createElement(OrchestryTooltip, { withinPortal: false, label: "Was this recommendation useful?" },
                React.createElement(OrchestryThumbs, { voteValue: recommendation.Vote, onClickThumbs: thumbOnClick }))),
            showViewDetailsButton && (React.createElement(OrchestryTooltip, { withinPortal: false, label: "Click to see important context and resources" },
                React.createElement(OrchestryButton, { onClick: function () { return openOrchestryModal("recommendationViewDetailsModal", { recommendation: recommendation, intl: intl }, "lg"); }, iconType: "font", iconLeft: ORCH_ICONS.list, variant: "gray", size: "md", iconSize: "sm" }, "View Details"))),
            showResolveButton && (React.createElement(OrchestryTooltip, { withinPortal: false, label: "Click to see how to resolve this recommendation" },
                React.createElement(OrchestryButton, { variant: "logo", size: "md", iconSize: "sm", iconType: "svg", iconLeftBase: ORCH_SVG_ICONS.LogoMarkMono, iconLeftActive: ORCH_SVG_ICONS.LogoMarkColour, onClick: onResolveClick }, isCustom ? "Mark as Resolved" : "Resolve"))),
            (isSnoozed || (isResolved && isCustom)) && (React.createElement(OrchestryTooltip, { withinPortal: false, multiline: true, w: 296, label: reopenToolTip },
                React.createElement(OrchestryTooltipGroup, null,
                    React.createElement("div", { className: "reopen-group" },
                        React.createElement(OrchestryButton, { onClick: function () { return onReopenClick(recommendation.RecommendationRecord.RecommendationGUID); }, iconType: "font", iconLeft: ORCH_ICONS.eye, "data-type": "icon", variant: "inverted-primary", size: "md", iconSize: "sm" }, "Reopen"),
                        React.createElement(OrchestryInformationBubble, null))))))));
};
