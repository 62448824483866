import { Group, Stack, Text } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import React from "react";
import { useDeleteHealthCheckModal } from "./use-delete-health-check-modal";
export var DeleteHealthCheckModal = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, uniqueId = _b.uniqueId, name = _b.name, intl = _b.intl, tenantGuid = _b.tenantGuid;
    var _c = useDeleteHealthCheckModal(tenantGuid), isSuccess = _c.isSuccess, isLoading = _c.isLoading, onDeleteClicked = _c.onDeleteClicked;
    React.useEffect(function () {
        if (isSuccess) {
            context.closeModal(id);
            OrchestrySuccessToast(intl.formatMessage({ id: "page.health_checks.toast.health_check_deleted", defaultMessage: "Health Check {name} Successfully Deleted" }, { name: name }));
        }
    }, [isSuccess, context, id, intl, name]);
    return (React.createElement(Stack, { gap: 24, ff: "var(--mantine-font-family)" },
        React.createElement(Group, { gap: 16 },
            React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "error-7", iconClassName: ORCH_ICONS.trash01 }),
            React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check_delete_modal.delete_health_check", defaultMessage: "Delete Health Check" }))),
        React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 400, lh: "20px" }, intl.formatMessage({ id: "page.health_check_delete_modal.are_you_sure", defaultMessage: "Are you sure you want to delete this health check: {name}? This action cannot be undone." }, { name: name })),
        React.createElement(Group, { gap: 12, justify: "right" },
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "white", size: "sm", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "general.label.cancel", defaultMessage: "Cancel" })),
            React.createElement(OrchestryButton, { disabled: isLoading, variant: "danger", size: "sm", onClick: function () { return onDeleteClicked(uniqueId); } }, intl.formatMessage({ id: "controls.delete", defaultMessage: "Delete" })))));
};
