import { Card, Stack } from "@mantine/core";
import { getReadableDate, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { toSimplePercentageLocaleString } from "appv2/utils/string.utils";
import React from "react";
import { CardKeyValue } from "../../card-key-value.component";
import { OrchestryCardTitle } from "../../orchestry-card-title.component";
import { useTenantPage } from "../../use-tenant-page.hook";
export var Guests = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Guests, _c = _b === void 0 ? {} : _b, LastGuestReviewJobDate = _c.LastGuestReviewJobDate, CustomGuestReviewPoliciesCount = _c.CustomGuestReviewPoliciesCount, GuestReviewPolicyApplicationPercentage = _c.GuestReviewPolicyApplicationPercentage, LastRequestJobDate = _c.LastRequestJobDate, CustomGuestRequestPoliciesCount = _c.CustomGuestRequestPoliciesCount, GuestRequestPolicyApplicationPercentage = _c.GuestRequestPolicyApplicationPercentage, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, h: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Guests" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.calendar, keyString: "Last Guest Review Job Date", value: LastGuestReviewJobDate != null ? getReadableDate(LastGuestReviewJobDate) : "Never", suffix: LastGuestReviewJobDate != null ? "(".concat(getReadableDateFromNow(new Date(LastGuestReviewJobDate)), ")") : "" }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.userLeft01, keyString: "Custom Guest Review Policies", value: CustomGuestReviewPoliciesCount === null || CustomGuestReviewPoliciesCount === void 0 ? void 0 : CustomGuestReviewPoliciesCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.archive, keyString: "Guest Review Policy Application", value: "".concat(toSimplePercentageLocaleString(GuestReviewPolicyApplicationPercentage), "%") }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.calendar, keyString: "Last Guest Request Job Date", value: LastRequestJobDate != null ? getReadableDate(LastRequestJobDate) : "Never", suffix: LastRequestJobDate != null ? "(".concat(getReadableDateFromNow(new Date(LastRequestJobDate)), ")") : "" }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.archive, keyString: "Custom Guest Request Policies", value: CustomGuestRequestPoliciesCount === null || CustomGuestRequestPoliciesCount === void 0 ? void 0 : CustomGuestRequestPoliciesCount.toLocaleString() }),
            React.createElement(CardKeyValue, { isLoading: isPageLoading, icon: ORCH_ICONS.archive, keyString: "Guest Request Policy Application", value: "".concat(toSimplePercentageLocaleString(GuestRequestPolicyApplicationPercentage), "%") }))));
};
