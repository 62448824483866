import React from "react";
import { IntlProvider } from "react-intl";
import { ResolveRecommendationModalContent } from "./recommendation-card-resolve-modal-content.component";

/**
 * This wrapper component is used to provide the necessary context to the AddAnnotationModalContent component
 * because some of it's subcomponents use a useIntl hook which requires the IntlProvider to be present in the component tree.
 */
export const ResolveRecommendationModal = props => {
   const {
      context,
      id,
      innerProps: { recommendation, intl }
   } = props;
   const children = <ResolveRecommendationModalContent context={context} id={id} innerProps={{ recommendation, intl }} />;

   return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
         {children}
      </IntlProvider>
   );
};
