import React from "react";
import { IntlProvider } from "react-intl";
import { ShareHealthCheckModalContent } from "./share-health-check-modal-content.component";

/**
 * This wrapper component is used to provide the necessary context to the ShareHealthCheckModalContent component
 * because some of it's subcomponents use a useIntl hook which requires the IntlProvider to be present in the component tree.
 */
export const ShareHealthCheckModal = props => {
   const {
      context,
      id,
      innerProps: { uniqueId, intl, tenantGuid }
   } = props;
   const children = <ShareHealthCheckModalContent context={context} id={id} innerProps={{ uniqueId, intl, tenantGuid }} />;

   return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
         {children}
      </IntlProvider>
   );
};
