import * as microsoftTeams from "@microsoft/teams-js";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import * as TeamInformationCurrentStore from "app/store/team-information/current";
import { MicrosoftAppType } from "app/store/team-information/current";
import getParameterByName from "app/utils/getQueryStringParam";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import TeamInformationChrome from "./components/team-information-chrome";
import TeamInformationPageDetails from "./components/team-information-page-details";
import TeamInformationPageUsers from "./components/team-information-page-users";
import "./team-information.scss";
export default function TeamInformation() {
    var intl = useIntl();
    var dispatch = useDispatch();
    var groupId = getParameterByName("groupId");
    var isInTeams = React.useState(document.OrchestryIsInTeams)[0];
    var isInSharepoint = React.useState(document.OrchestryIsInSharePoint)[0];
    var _a = React.useState(false), showError = _a[0], setShowError = _a[1];
    React.useEffect(function () {
        // initializeIcons();
        if (isInTeams) {
            microsoftTeams.app.initialize();
            // microsoftTeams.enterFullscreen();
            microsoftTeams.app.getContext().then(function (context) {
                var _a, _b, _c;
                console.log("Team Context", context);
                dispatch(TeamInformationCurrentStore.LoadTeamContext(context));
                if (((_a = context.team) === null || _a === void 0 ? void 0 : _a.groupId) === undefined) {
                    setShowError(true);
                }
                else {
                    dispatch(TeamInformationCurrentStore.LoadCosmosObject((_b = context.team) === null || _b === void 0 ? void 0 : _b.groupId, MicrosoftAppType.Teams));
                    dispatch(TeamInformationCurrentStore.LoadTeamLinks((_c = context.team) === null || _c === void 0 ? void 0 : _c.groupId));
                    setTimeout(function () {
                        var _a;
                        dispatch(TeamInformationCurrentStore.LoadTeamLinksNotebookAndPlanner((_a = context.team) === null || _a === void 0 ? void 0 : _a.groupId));
                    }, 2000);
                }
            });
        }
    }, [isInTeams]);
    React.useEffect(function () {
        // initializeIcons();
        if (isInSharepoint && !!groupId) {
            dispatch(TeamInformationCurrentStore.LoadTeamContext({ team: { groupId: groupId } }));
            dispatch(TeamInformationCurrentStore.LoadCosmosObject(groupId, MicrosoftAppType.SharePoint));
            dispatch(TeamInformationCurrentStore.LoadTeamLinks(groupId));
            setTimeout(function () {
                dispatch(TeamInformationCurrentStore.LoadTeamLinksNotebookAndPlanner(groupId));
            }, 2000);
        }
    }, [isInSharepoint]);
    if (isInSharepoint) {
        return React.createElement(TeamInformationPageDetails, null);
    }
    return (React.createElement("div", { className: "team-information" }, isInTeams === false ? (React.createElement(Container, null,
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Portlet, { className: "team-style-1 portlet-not-in-teams" },
                    React.createElement(PortletBody, null,
                        React.createElement("div", { className: "icon" },
                            React.createElement("i", { className: "flaticon-warning-sign" })),
                        React.createElement("div", null,
                            React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.only_works_in_teams", defaultMessage: "The Orchestry Information tab only works from within teams" })))))))) : showError ? (React.createElement(Container, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6 },
                React.createElement("div", null,
                    React.createElement("div", { style: { marginLeft: "30px", marginRight: "auto", marginTop: "30px" } },
                        React.createElement(Portlet, { className: "team-style-1 portlet-error" },
                            React.createElement(PortletHeader, { title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.a_problem_has_occured", defaultMessage: "A problem has occured" }) }),
                            React.createElement(PortletBody, null,
                                React.createElement("div", { className: "error-message" },
                                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.could_not_retrieve", defaultMessage: "Could not retrieve team information." }),
                                    React.createElement("br", null),
                                    React.createElement(FormattedMessage, { id: "layout.teams_app.chrome.team_info.tab_will_not_work_in_private_channel", defaultMessage: "Please note that this tab will not work in a private channel." })))))))))) : (React.createElement(TeamInformationChrome, { tabs: [
            {
                title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.tab_title.details", defaultMessage: "Details" }),
                fontClass: "",
                component: React.createElement(TeamInformationPageDetails, null),
                key: "details"
            },
            {
                title: intl.formatMessage({ id: "layout.teams_app.chrome.team_info.tab_title.users", defaultMessage: "Users" }),
                fontClass: "",
                component: React.createElement(TeamInformationPageUsers, null),
                key: "users"
            }
        ] }))));
}
