var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import "./orchestry-localization-cache-busting.scss";
import { Row, Col, Form } from "react-bootstrap";
import { LocalizationService } from "app/services/localization";
import { SubtitleButton } from "app/components/common/subtitle";
import OrchModal from "app/components/common/orch-modal";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import { PortletBody } from "app/components/common/portlet";
import { useSelector } from "react-redux";
import AnimatedLoader from "app/components/common/animated-loader";
var OrchestryLocalizationCacheBusting = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(null), servers = _a[0], setServers = _a[1];
    var _b = React.useState(false), orchAddServerModalOpen = _b[0], setOrchAddServerModalOpen = _b[1];
    var _c = React.useState(), editName = _c[0], setEditName = _c[1];
    var _d = React.useState(), editUrl = _d[0], setEditUrl = _d[1];
    var _e = React.useState(), editGuid = _e[0], setEditGuid = _e[1];
    var _f = React.useState(null), bustCacheChecklist = _f[0], setBustCacheChecklist = _f[1];
    var _g = React.useState(false), bustComplete = _g[0], setBustComplete = _g[1];
    React.useEffect(function () {
        getServerItems();
    }, []);
    var getServerItems = function () {
        LocalizationService.getServers().then(function (serverData) {
            setServers(serverData);
        });
    };
    var updateItem = function () {
        if (editName !== undefined && editUrl !== undefined) {
            setServers(null);
            LocalizationService.updateServer({
                Guid: editGuid === undefined ? null : editGuid,
                Name: editName,
                Url: editUrl
            }, "update").then(function (data) {
                setOrchAddServerModalOpen(false);
                setEditName(undefined);
                setEditUrl(undefined);
                setEditGuid(undefined);
                getServerItems();
            });
        }
    };
    var editClick = function (serverItem) {
        setOrchAddServerModalOpen(true);
        setEditName(serverItem.Name);
        setEditUrl(serverItem.Url);
        setEditGuid(serverItem.Guid);
    };
    var deleteClick = function (serverItem) {
        setServers(null);
        LocalizationService.updateServer(serverItem, "delete").then(function (data) {
            setOrchAddServerModalOpen(false);
            setEditName(undefined);
            setEditUrl(undefined);
            setEditGuid(undefined);
            getServerItems();
        });
    };
    var cacheBustAllServers = function () {
        setBustComplete(false);
        var statuses = [];
        servers === null || servers === void 0 ? void 0 : servers.forEach(function (s) {
            statuses.push({
                item: s,
                status: "pending"
            });
        });
        setBustCacheChecklist(statuses);
        var processNextBust = function (statuses) {
            var toProcess = statuses.find(function (s) { return s.status === "pending"; });
            if (toProcess === undefined) {
                doneBusting();
            }
            else {
                statuses = statuses.map(function (s) { return (s.item.Guid === toProcess.item.Guid ? __assign(__assign({}, toProcess), { status: "processing" }) : s); });
                setBustCacheChecklist(statuses);
                LocalizationService.bustServerCache(toProcess.item).then(function (data) {
                    if (data === true) {
                        statuses = statuses.map(function (s) { return (s.item.Guid === toProcess.item.Guid ? __assign(__assign({}, toProcess), { status: "done" }) : s); });
                        setBustCacheChecklist(statuses);
                        processNextBust(statuses);
                    }
                });
            }
        };
        processNextBust(statuses);
        var doneBusting = function () {
            setBustComplete(true);
            setBustCacheChecklist(null);
            console.log("done busting!");
        };
    };
    var getServerStatus = function (server) {
        var foundChecklistItem = bustCacheChecklist === null || bustCacheChecklist === void 0 ? void 0 : bustCacheChecklist.find(function (cl) { return cl.item.Guid === server.Guid; });
        if (foundChecklistItem !== undefined) {
            return foundChecklistItem.status;
        }
        else if (bustComplete === true) {
            return "done";
        }
        return "server";
    };
    return (React.createElement("div", { className: "orchestry-localization-cache-busting fadein-500ms" },
        servers === null ? (React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 25 })) : (React.createElement("ul", null, servers.map(function (s) { return (React.createElement("li", null,
            React.createElement("div", { className: "bust-status" }, getServerStatus(s) === "server" ? (React.createElement("span", { className: "server" },
                React.createElement("i", { className: " fas fa-server" }))) : getServerStatus(s) === "pending" ? (React.createElement("span", { className: "pending" },
                React.createElement("i", { className: " far fa-hourglass" }))) : getServerStatus(s) === "processing" ? (React.createElement("span", { className: "processing" },
                React.createElement(AnimatedLoader, { size: "xxsmall" }))) : (React.createElement("span", { className: "done" },
                React.createElement("i", { className: "fas fa-check-circle" })))),
            React.createElement("div", { className: "title" }, s.Name),
            React.createElement("div", { className: "url" }, s.Url),
            React.createElement("div", { className: "action" }, orchestryContext.IsOrchestryMaintenance === true ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "edit", onClick: function () { return editClick(s); } },
                    React.createElement("i", { className: "fa fa-pen" })),
                React.createElement("div", { className: "delete", onClick: function () { return deleteClick(s); } },
                    React.createElement("i", { className: "fa fa-trash" })))) : null))); }))),
        React.createElement(Row, null,
            React.createElement(Col, null, orchestryContext.IsOrchestryMaintenance === true ? (React.createElement(OrchModal, { showModalOverride: orchAddServerModalOpen, showModalToggleOverride: setOrchAddServerModalOpen, modalBgClassName: "orchestry-localization-editor-add-server-bg", modalClassName: "orchestry-localization-editor-add-server", buttonContents: "Add Server", headerText: "Add Server", showCloseButton: true, size: "md" },
                React.createElement(PortletBody, null,
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(Form.Label, null, "Name"),
                        React.createElement(Form.Control, { value: editName, onChange: function (event) { return setEditName(event.target.value); } })),
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(Form.Label, null, "Url"),
                        React.createElement(Form.Control, { value: editUrl, onChange: function (event) { return setEditUrl(event.target.value); } })),
                    React.createElement("div", { style: { textAlign: "right" } },
                        React.createElement(SubtitleButton, { title: editGuid === undefined ? "Add" : "Update", disabled: editName === undefined || editUrl === undefined, onClick: function () { return updateItem(); } }))))) : null),
            React.createElement(Col, { md: 7 },
                React.createElement(SubtitleButton, { className: "float-right", title: "Update Server Caches with New Values", disabled: bustCacheChecklist !== null, onClick: function () { return cacheBustAllServers(); } })))));
});
export default OrchestryLocalizationCacheBusting;
