var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import "./feature-files.scss";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import { Form, Collapse, Modal } from "react-bootstrap";
import AnimatedLoader from "app/components/common/animated-loader";
import DynamicScrollbar from "app/components/common/dynamic-scollbar";
import { useSelector } from "react-redux";
import FeatureFilesService from "app/services/feature-files";
import moment from "moment";
import ProgressReport from "app/components/common/progress-report";
import FormValidationContext, { FormValidator, FormValidatorFocus } from "app/components/common/form-validation";
import Picklist from "app/components/common/form-picklist";
import FormOfficeAssetPicker from "app/components/common/form-office-asset-picker";
import * as PageHeader from "app/components/common/page-header";
import SharePointService from "app/services/sharepoint";
import ApplyFeatureFile from "app/components/tools/feature-files/apply-template";
import ViewTemplate from "app/components/tools/feature-files/view-template";
import UploadTemplate from "app/components/tools/feature-files/upload-template";
import { SkeletonFakeTableCol, SkeletonFakeTableRow, SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
var ExtrationHandlers = [
    "ApplicationLifecycleManagement",
    "AuditSettings",
    "ComposedLook",
    "ContentTypes",
    "CustomActions",
    "ExtensibilityProviders",
    "Features",
    "Fields",
    "Files",
    "Lists",
    "Navigation",
    "PageContents",
    "Pages",
    "PropertyBagEntries",
    "Publishing",
    "RegionalSettings",
    "SearchSettings",
    "SiteFooter",
    "SiteHeader",
    "SitePolicy",
    "SiteSecurity",
    "SiteSettings",
    "SupportedUILanguages",
    "TermGroups",
    "Theme",
    "Workflows",
    "WebSettings",
    "ImageRenditions",
    "Tenant",
    "WebApiPermissions"
];
var BoolableConfigs = [
    //")]
    {
        title: "Include All Term Groups",
        value: "IncludeAllTermGroups",
        description: "If checked, all term groups will be included. Overrides IncludeSiteCollectionTermGroup.",
        enabled: false
    },
    {
        title: "Include Site Collection Term Group",
        value: "IncludeSiteCollectionTermGroup",
        description: "If checked, all the site collection term groups will be included. Overridden by IncludeAllTermGroups.",
        enabled: false
    },
    {
        title: "Include Site Groups",
        value: "IncludeSiteGroups",
        description: "If checked all site groups will be included.",
        enabled: false
    },
    {
        title: "Include Term Groups Security",
        value: "IncludeTermGroupsSecurity",
        description: "If checked all the managers and contributors of term groups will be included.",
        enabled: false
    },
    {
        title: "Include Search Configuration",
        value: "IncludeSearchConfiguration",
        description: "If checked the template will contain the current search configuration of the site.",
        enabled: false
    },
    {
        title: "Persist Branding Files",
        value: "PersistBrandingFiles",
        description: "If checked the files used for masterpages, sitelogo, alternate CSS and the files that make up the composed look will be saved.",
        enabled: false
    },
    {
        title: "Persist Publishing Files",
        value: "PersistPublishingFiles",
        description: "If checked the files used for the publishing feature will be saved.",
        enabled: false
    },
    {
        title: "Include Native Publishing Files",
        value: "IncludeNativePublishingFiles",
        description: "If checked, out of the box / native publishing files will be saved.",
        enabled: false
    },
    {
        title: "Include Hidden Lists",
        value: "IncludeHiddenLists",
        description: "If checked hidden lists will be included in the template.",
        enabled: false
    },
    {
        title: "Include All Client Side Pages",
        value: "IncludeAllClientSidePages",
        description: "If checked all client side pages will be included.",
        enabled: true
    }
];
var FeatureFiles = React.memo(function (props) {
    //const dispatch = useDispatch();
    // const [notificationCount, setNotificationCount] = React.useState<number>(0);
    // const requests = useSelector((state: RootState) => state.requestListState.userItems);
    // const orchestryContext = useSelector((state: RootState) => state.orchestryContextCurrentState.item);
    // const [openedOnce, setOpenedOnce] = React.useState(false);
    var _a = React.useState([]), currentFiles = _a[0], setCurrentFiles = _a[1];
    var _b = React.useState(true), loadingFiles = _b[0], setLoadingFiles = _b[1];
    var _c = React.useState(false), extractingTemplate = _c[0], setExtractingTemplate = _c[1];
    var _d = React.useState(false), extractSpecificLists = _d[0], setExtractSpecificLists = _d[1];
    var _e = React.useState([]), availableLists = _e[0], setAvailableLists = _e[1];
    var _f = React.useState(), jobId = _f[0], setJobId = _f[1];
    var _g = React.useState(false), showAddModal = _g[0], setShowAddModal = _g[1];
    var _h = React.useState(false), extractFormValid = _h[0], setExtractionFormValid = _h[1];
    var _j = React.useState(""), extractFileName = _j[0], setExtractFileName = _j[1];
    var _k = React.useState(""), extractWebUrl = _k[0], setExtractWebUrl = _k[1];
    var _l = React.useState(""), extractHandlers = _l[0], setExtractHandlers = _l[1];
    var _m = React.useState(""), extractBoolValues = _m[0], setExtractBoolValues = _m[1];
    var _o = React.useState(true), extractAllHandlers = _o[0], setExtractAllHandlers = _o[1];
    var _p = React.useState(""), extractListsIds = _p[0], setExtractListsIds = _p[1];
    var _q = React.useState(""), extractListData = _q[0], setExtractListData = _q[1];
    var _r = React.useState(), webUrlData = _r[0], setWebUrlData = _r[1];
    var _s = React.useState([]), handlerPicklistData = _s[0], setHandlerPicklistData = _s[1];
    var _t = React.useState([]), boolableData = _t[0], setBoolableData = _t[1];
    var _u = React.useState(false), showAdvancedConfig = _u[0], setShowAdvancedConfig = _u[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    React.useEffect(function () {
        setupPicklistDataWithDefaults();
        setupBoolabelDataWithDefaults();
        loadFileList();
    }, []);
    var setupPicklistDataWithDefaults = function () {
        var picklistData = [];
        ExtrationHandlers.forEach(function (handler, hIndex) {
            picklistData.push({
                Name: handler,
                Identifier: handler,
                Picked: false
            });
        });
        setHandlerPicklistData(picklistData);
    };
    var setupBoolabelDataWithDefaults = function () {
        setBoolableData(__spreadArray([], BoolableConfigs, true));
    };
    var loadFileList = function () {
        setLoadingFiles(true);
        FeatureFilesService.GetFiles().then(function (files) {
            setCurrentFiles(files.filter(function (f) { return f.Deleted === false; }));
            setLoadingFiles(false);
        });
    };
    var removeFeatureFolder = function (fileName) {
        return fileName.replace("feature-files/", "");
    };
    React.useEffect(function () {
        if (extractSpecificLists === true) {
            setExtractListsIds(extractListData);
        }
        else {
            setExtractHandlers("");
        }
    }, [extractListData, extractSpecificLists]);
    React.useEffect(function () {
        if (extractAllHandlers === false) {
            var stringArray = handlerPicklistData.filter(function (hd) { return hd.Picked === true; }).map(function (hd) { return hd.Identifier; });
            var outputValue = stringArray.join("|");
            setExtractHandlers(outputValue);
        }
        else {
            setExtractHandlers("");
        }
    }, [handlerPicklistData, extractAllHandlers]);
    React.useEffect(function () {
        var outputValue = JSON.stringify(boolableData.map(function (bd) { return ({ value: bd.value, enabled: bd.enabled }); }));
        setExtractBoolValues(outputValue);
    }, [boolableData]);
    React.useEffect(function () {
        if (webUrlData !== undefined && webUrlData !== null) {
            if (webUrlData.length === 0) {
                setExtractWebUrl("");
                setAvailableLists([]);
            }
            else {
                var outputValue = JSON.parse(webUrlData);
                console.log("site url", outputValue);
                setExtractWebUrl(outputValue.URL);
                SharePointService.GetAllListsFromWeb(outputValue.URL, "").then(function (data) {
                    setAvailableLists(data);
                });
            }
        }
    }, [webUrlData]);
    var extractTemplate = function () {
        setExtractingTemplate(true);
        console.log("extractHandlers", extractHandlers);
        console.log("extractBoolValues", extractBoolValues);
        console.log("extractListsIds", extractListsIds);
        FeatureFilesService.ExtractTemplate(extractWebUrl, extractFileName, extractHandlers, extractBoolValues, extractListsIds).then(function (newJobId) {
            setJobId(newJobId);
        });
    };
    var deleteFile = function (fileName) {
        setLoadingFiles(true);
        FeatureFilesService.DeleteFile(fileName).then(function (files) {
            loadFileList();
        });
    };
    var updateBoolable = function (checked, value) {
        setBoolableData(boolableData.map(function (bd) { return (bd.value === value ? __assign(__assign({}, bd), { enabled: checked }) : bd); }));
    };
    var jobFinished = function () {
        loadFileList();
        setExtractFileName("");
        //setExtractWebUrl("");
        setWebUrlData("");
        setShowAddModal(false);
        setExtractingTemplate(false);
        setJobId(undefined);
    };
    var listChecked = function (listId, checked) {
        if (listId !== undefined && listId !== null && listId !== "") {
            var currentChecked = extractListData;
            if (currentChecked !== undefined && (currentChecked === null || currentChecked === void 0 ? void 0 : currentChecked.indexOf(listId)) > -1) {
                if (checked === false) {
                    currentChecked = currentChecked.replace(listId + "|", "");
                }
            }
            else {
                if (checked === true) {
                    currentChecked += listId + "|";
                }
            }
            setExtractListData(currentChecked);
        }
    };
    var isChecked = function (listId) {
        if (extractListData !== undefined && extractListData.indexOf(listId) > -1) {
            return true;
        }
        else {
            return false;
        }
    };
    var canModalClose = function () {
        if (extractingTemplate === false) {
            setShowAddModal(!showAddModal);
        }
    };
    var titleClick = function (fileName) {
        if (props.onSelect !== undefined) {
            props.onSelect(fileName);
        }
    };
    return (React.createElement("div", { className: "feature-files" },
        props.onSelect !== undefined ? React.createElement(React.Fragment, null) : React.createElement(PageHeader.SetLeftContent, { title: "Tools", subTitle: "Template Files" }),
        React.createElement(Subtitle, null, orchestryContext.IsCurrentUserOrchestryAdmin === true ? (React.createElement("div", { style: { display: "inline-block" } },
            React.createElement("a", { href: orchestryContext.TenantSharePointUrl + "/sites/orchestry/Storage", target: "_blank", rel: "noopener noreferrer" },
                React.createElement(SubtitleButton, { title: "View Storage Library in SharePoint", faIcon: "list-alt", variant: "info" })))) : null),
        React.createElement(Portlet, { className: "files-portlet" },
            React.createElement(PortletHeader, { title: "Extracted PNP Files", toolbar: React.createElement("div", null,
                    React.createElement("div", { className: "extract-modal btn btn-success", style: { marginRight: "15px" } },
                        React.createElement(UploadTemplate, { afterUpload: function () { return loadFileList(); } },
                            React.createElement("i", { className: "fas fa-file-upload" }),
                            " Upload Template")),
                    React.createElement("div", { className: "extract-modal btn btn-success", onClick: function () { return setShowAddModal(!showAddModal); } },
                        React.createElement("i", { className: "fa fa-plus" }),
                        " Extract New Template")) }),
            React.createElement(PortletBody, null, loadingFiles === true ? (React.createElement("div", { className: "loading" },
                React.createElement(SkeletonFakeTableRow, { repeatCols: 6 },
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 80 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 12 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 })),
                    React.createElement(SkeletonFakeTableCol, { widthPercent: 8 },
                        React.createElement(SkeletonLoaderFakeBox, { bodyHeight: 30 }))))) : (React.createElement("div", { className: "files" },
                currentFiles.map(function (file, fileIndex) { return (React.createElement("div", { className: "file", key: fileIndex },
                    React.createElement("div", { className: "file-icon" },
                        React.createElement("i", { className: "far fa-file" })),
                    React.createElement("div", { className: "file-name" }, props.onSelect !== undefined ? (React.createElement("div", { className: "select-item", onClick: function () { return titleClick(removeFeatureFolder(file.Name)); } }, removeFeatureFolder(file.Name))) : (React.createElement(ViewTemplate, { fileName: removeFeatureFolder(file.Name) },
                        React.createElement("a", null, removeFeatureFolder(file.Name))))
                    // <a href={"/api/FeatureFiles/Download?fileName=" + removeFeatureFolder(file.Name)}>{removeFeatureFolder(file.Name)}</a>
                    ),
                    React.createElement("div", { className: "file-date" }, moment(file.Properties.CreatedOn).format("LLL")),
                    React.createElement("div", { className: "download", title: "Download" },
                        React.createElement("a", { href: "/api/FeatureFiles/Download?fileName=" + removeFeatureFolder(file.Name) },
                            React.createElement("i", { className: "fas fa-file-download" }))),
                    React.createElement("div", { className: "view-button", title: "View Template Contents" },
                        React.createElement(ViewTemplate, { fileName: removeFeatureFolder(file.Name) },
                            React.createElement("i", { className: "fas fa-file-code" }))),
                    React.createElement("div", { title: "Test and Apply Template" },
                        React.createElement(ApplyFeatureFile, { fileName: removeFeatureFolder(file.Name) },
                            React.createElement("i", { className: "fas fa-flask" }))),
                    React.createElement("div", { className: "file-delete", onClick: function () { return deleteFile(removeFeatureFolder(file.Name)); }, title: "Delete Template File" },
                        React.createElement("i", { className: "fa fa-trash-can-xmark" })))); }),
                currentFiles.length === 0 ? React.createElement("div", { className: "no-files" }, "You have not created any templates yet!") : null)))),
        React.createElement(Modal, { centered: true, show: showAddModal, onHide: function () { return canModalClose(); }, animation: true, className: "feature-extraction-modal", backdropClassName: "feature-extraction-modal-backdrop" },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Extract New Template")),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "feature-extraction-wrapper" }, extractingTemplate === true ? (React.createElement(ProgressReport, { jobId: jobId, showOnlySubBar: true, onComplete: function () { return jobFinished(); }, turnOffAfter: false, showBeforeJobId: true, showConsoleByDefault: true })) : (React.createElement(FormValidationContext, { UniqueIdentifier: "extraction-form", OnChangeIsValid: function (isValid) { return setExtractionFormValid(isValid); } },
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(Form.Label, null, "File Name"),
                        React.createElement(FormValidatorFocus, { UniqueIdentifier: "name" },
                            React.createElement(Form.Control, { value: extractFileName, onChange: function (event) { return setExtractFileName(event.target.value); } })),
                        React.createElement(FormValidator, { UniqueIdentifier: "name", FieldName: "File Name", Validators: ["string-is-more-than-2"], ShowOnFocus: true, Value: extractFileName })),
                    React.createElement(Form.Group, { className: "form-group" },
                        React.createElement(FormValidatorFocus, { UniqueIdentifier: "url" },
                            React.createElement(FormOfficeAssetPicker, { pickerType: "AllSharePoint", value: webUrlData, 
                                // skipIntialListAll={true}
                                onChange: function (serializedItem) { return setWebUrlData(serializedItem); } })),
                        React.createElement(FormValidator, { UniqueIdentifier: "url", FieldName: "Web Url", ShowOnFocus: true, Validators: ["string-not-empty"], Value: extractWebUrl })),
                    React.createElement("div", { className: "advanced-configuration" + (showAdvancedConfig === true ? " show-advanced-configs" : "") },
                        React.createElement("div", { className: "toggle-title", onClick: function () { return setShowAdvancedConfig(!showAdvancedConfig); } },
                            React.createElement("i", { className: "fa fa-caret-down" }),
                            React.createElement("span", { className: "show-text" }, "Advanced Configuration")),
                        React.createElement(Collapse, { in: showAdvancedConfig },
                            React.createElement("div", { className: "configs" },
                                React.createElement(Form.Group, { controlId: "control-extract-all", className: "form-group checkbox-green" },
                                    React.createElement(Form.Check, { type: "checkbox", label: "Extraction Handlers > Includes all types", checked: extractAllHandlers, onChange: function (e) {
                                            setExtractAllHandlers(e.currentTarget.checked);
                                        } })),
                                React.createElement(Collapse, { in: extractAllHandlers === false },
                                    React.createElement("div", null,
                                        React.createElement(Picklist, { disableSort: true, leftTitle: "Included in extraction", rightTitle: "Excluded from extraction", data: handlerPicklistData, onChange: function (handlers) { return setHandlerPicklistData(handlers); } }))),
                                React.createElement(Form.Group, { controlId: "control-extract-lists", className: "form-group checkbox-green" },
                                    React.createElement(Form.Check, { type: "checkbox", label: "Extract all lists", checked: !extractSpecificLists, onChange: function (e) {
                                            setExtractSpecificLists(!e.currentTarget.checked);
                                        } })),
                                React.createElement(Collapse, { in: extractSpecificLists },
                                    React.createElement("div", { className: "lists-to-clone" },
                                        React.createElement(DynamicScrollbar, { maxHeight: 150, shouldShow: availableLists !== undefined && availableLists.length > 6 ? true : false }, availableLists !== undefined ? (React.createElement(React.Fragment, null,
                                            availableLists.map(function (listItem) { return (React.createElement(Form.Group, { controlId: "site-options-cloning-site-list-" + listItem.Id, className: "form-group checkbox-green", key: listItem.Id },
                                                React.createElement(Form.Check, { type: "checkbox", checked: isChecked(listItem.Id), onChange: function (event) { return listChecked(listItem.Id, event.target.checked); }, label: listItem.Title }))); }),
                                            availableLists.length === 0 ? (React.createElement("div", { className: "no-results" }, "No lists to display. This is usually because you need to select a SharePoint site above first.")) : null)) : (React.createElement(AnimatedLoader, { size: "xsmall" }))))),
                                boolableData.map(function (boolDat, bdIndex) { return (React.createElement(Form.Group, { controlId: "control-bool-data-" + bdIndex, key: "control-bool-data-" + bdIndex, className: "form-group boolable-checkitems checkbox-green" },
                                    React.createElement(Form.Check, { type: "checkbox", label: boolDat.title, checked: boolDat.enabled, onChange: function (e) {
                                            updateBoolable(e.currentTarget.checked, boolDat.value);
                                        } }),
                                    React.createElement("div", { className: "checkbox-description" }, boolDat.description))); })))))))),
            React.createElement(Modal.Footer, null,
                React.createElement("div", { className: "action-buttons" }, extractFormValid === false || extractingTemplate === true ? (React.createElement("button", { className: "submit-button btn btn-success", disabled: true }, "Extract PNP File")) : (React.createElement("button", { className: "submit-button btn btn-success", onClick: function () { return extractTemplate(); } }, "Extract PNP File")))))));
});
export default FeatureFiles;
