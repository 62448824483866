import { Group, Stack, Text } from "@mantine/core";
import FormUserGroupSelect from "app/components/common/form-user-group-select";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestrySaveButton } from "appv2/components/orchestry-save-button/orchestry-save-button.component";
import { mapFormGroupsStringToIds } from "appv2/utils/control.utils";
import React from "react";
import { useShareHealthCheckModal } from "./use-share-health-check-modal-content.hook";
export var ShareHealthCheckModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps, uniqueId = _b.uniqueId, intl = _b.intl, tenantGuid = _b.tenantGuid;
    var _c = useShareHealthCheckModal({ context: context, id: id, innerProps: { uniqueId: uniqueId, intl: intl, tenantGuid: tenantGuid } }), form = _c.form, viewersCount = _c.viewersCount, isPageLoading = _c.isPageLoading, handleFormSubmit = _c.handleFormSubmit;
    return (React.createElement("form", { onSubmit: form.onSubmit(function (values) { return handleFormSubmit(values); }) },
        React.createElement(Stack, { gap: 16, ff: "var(--mantine-font-family)" },
            React.createElement(Group, { gap: 16 },
                React.createElement(OrchestryFeaturedIcon, { variant: "double-circle", size: "lg", colorVariant: "primary-6", iconClassName: ORCH_ICONS.checkVerified02 }),
                React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check.share_modal.title", defaultMessage: "Sharing" }))),
            React.createElement(Text, { c: "text-primary.0", fz: "18px", fw: 400, lh: "28px" }, intl.formatMessage({ id: "page.health_check.share_modal.viewers_count", defaultMessage: "Viewers ({count})" }, { count: viewersCount })),
            React.createElement(Text, { c: "text-secondary.0", fz: "14px", fw: 400, lh: "20px" }, intl.formatMessage({
                id: "page.health_check.share_modal.description",
                defaultMessage: "The following users have been granted access to View this particular Health Check. Any new Viewer will receive a notification when a new Health Check has been Published."
            })),
            React.createElement(Text, { c: "text-secondary.0", fz: "12px", fw: 400, lh: "18px", mb: 8 }, intl.formatMessage({ id: "page.health_check.share_modal.note", defaultMessage: "Note: Orchestry Admins and Health Check Editors can always see Health Checks" })),
            React.createElement(FormUserGroupSelect, { isLoading: isPageLoading, defaultValue: undefined, allowMultiple: true, returnType: "users", label: intl.formatMessage({ id: "page.health_check.share_modal.viewers", defaultMessage: "Viewers:" }), placeholder: intl.formatMessage({ id: "form_user_group_select.placeholder.allow_multiple.users", defaultMessage: "No user(s) selected. Click the + button above to add a user." }), value: form.getInputProps("SharedWith").value, onChange: function (groups) { return form.setFieldValue("SharedWith", mapFormGroupsStringToIds(groups)); }, excludeGuests: true, shouldUseDynamic: true, error: form.getInputProps("SharedWith").error })),
        React.createElement(Group, { gap: 12, justify: "right" },
            React.createElement(OrchestryButton, { disabled: isPageLoading, variant: "white", size: "md", onClick: function () { return context.closeModal(id); } }, intl.formatMessage({ id: "controls.cancel", defaultMessage: "Cancel" })),
            React.createElement(OrchestrySaveButton, { disabled: isPageLoading, variant: "primary", type: "submit" }, intl.formatMessage({ id: "controls.save", defaultMessage: "Save" })))));
};
