import * as React from "react";
import "./queue.scss";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import moment from "moment";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import LifecycleService from "app/services/lifecycle";
var LifecycleQueues = React.memo(function (props) {
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    // const currentTenant: Tenant = useSelector((state: RootState) => state.tenantCurrentState.item);
    var _a = React.useState(false), loadedOnce = _a[0], setLoadedOnce = _a[1];
    var _b = React.useState(true), loopForUpdates = _b[0], setLoopForUpdates = _b[1];
    var refLoopForUpdates = React.useRef(loopForUpdates);
    var _c = React.useState(null), lifecycleQueue = _c[0], setLifecycleQueue = _c[1];
    React.useEffect(function () {
        // dispatch(TenantCurrentStore.LoadWithInfo(""));
    }, []);
    var loadData = function () { };
    var refresh = function () {
        setLoadedOnce(false);
        loadData();
    };
    React.useEffect(function () {
        loadData();
        // setLoadedOnce(true);
        // loopForUpdatesWatch();
        // return () => {
        //    refLoopForUpdates.current = false;
        //    setLoopForUpdates(false);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(function () {
        var timerID; //= setInterval(timer, 5000); //every 5 seconds
        var shouldStop = false;
        function getLatestData() {
            LifecycleService.GetLifecycleQueue().then(function (data) {
                setLifecycleQueue(data);
                setLoadedOnce(true);
                if (shouldStop === false) {
                    timerID = setTimeout(function () {
                        getLatestData();
                    }, 5000);
                }
            });
        }
        getLatestData();
        return function () {
            //When we leave component we stop the timer
            if (timerID != null) {
                shouldStop = true;
                clearInterval(timerID);
            }
        };
    }, []);
    // const loopForUpdatesWatch = () => {
    //    if (refLoopForUpdates.current === true) {
    //       setTimeout(() => {
    //          if (refLoopForUpdates.current === true) {
    //             LifecycleService.GetLifecycleQueue().then(data => {
    //                setLifecycleQueue(data);
    //                loopForUpdatesWatch();
    //             })
    //             // dispatch(RequestListStore.Load_Page({
    //             //    items: [],
    //             //    view: "queue",
    //             //    paging: { CurrentPage: 1, ResultsPerPage: 100 }
    //             // }));
    //          }
    //       }, 5000);
    //    }
    // }
    // const getWidthOfProgress = (numbers: string) => {
    //    if (numbers.indexOf("|") > -1) {
    //       const nums = numbers.split("|");
    //       const total = Number(nums[0]);
    //       const complete = Number(nums[1]);
    //       return ((complete / total) * 100) + "%";
    //    } else {
    //       return "100%";
    //    }
    // }
    var GetLogoForSiteId = function (siteId) {
        var foundLogo = lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 : lifecycleQueue.StatLogos.find(function (sl) { return sl.SiteId === siteId && sl.Base64Logo != null; });
        if (foundLogo === undefined) {
            return null;
        }
        else {
            return foundLogo.Base64Logo;
        }
    };
    var getStatusText = function (status) {
        if (status != null) {
            if (status === "Active" || status === null) {
                return "Active";
            }
            if (status === "PendingApproval") {
                return "Pending Approval";
            }
            if (status === "Extended") {
                return "Workspace Extended";
            }
            if (status === "ReadyToArchive") {
                return "Ready to Archive";
            }
            if (status === "ReadyToDelete") {
                return "Ready to Delete";
            }
            if (status === "Processing") {
                return "Processing Archival";
            }
            if (status === "Done") {
                return "Archived";
            }
            if (status === "Failed") {
                return "Archival Failed";
            }
            if (status === "Deleted") {
                return "Deleted";
            }
        }
    };
    var RenderItem = function (_a) {
        var pw = _a.pw;
        var isPending = false;
        // if (["ReadyToProvision", "Provisioning"].some((sp) => sp === r.Status)) {
        //    isPending = true;
        // }
        return (React.createElement("div", { className: "request-item" },
            React.createElement("div", { className: "icon" },
                React.createElement(OfficeProfilePicture, { base64image: GetLogoForSiteId(pw.SiteId), displayName: pw.Title, size: "sm" })),
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "content" },
                    React.createElement(NavLink, { to: ("/lifecycle/listing/" + pw.SiteId) }, pw.Title))),
            React.createElement("div", { className: "status" },
                React.createElement("div", { className: "ws-archival-status lc-stats-" + (pw.ArchivalStatus != null ? pw.ArchivalStatus.toLowerCase() : "active") },
                    React.createElement("span", null, getStatusText(pw.ArchivalStatus)))),
            React.createElement("div", { className: "request-date" },
                React.createElement("span", null, moment
                    .utc(pw.UpdatedDate)
                    .local()
                    .format("Do MMM h:mm a")))));
    };
    return (React.createElement("div", { className: "lifecycle-queue" },
        React.createElement(Subtitle, { title: "Workspace Archivals", subtitle: React.createElement(React.Fragment, null, "Tenant Queue") },
            React.createElement(NavLink, { to: "/lifecycle/listing" },
                React.createElement(SubtitleButton, { title: "Back", faIcon: "arrow-left", variant: "secondary" }))),
        orchestryContext.IsCurrentUserOrchestryAdmin === true ? (React.createElement(React.Fragment, null,
            React.createElement(Portlet, { className: "current-status", fluidHeight: true },
                React.createElement(PortletBody, null,
                    React.createElement("div", { className: "request-container" },
                        React.createElement("div", { className: "request-title" },
                            React.createElement("div", { className: "icon-title col-header" }, "Archiving"),
                            React.createElement("div", { className: "status col-header" }, "Status"),
                            React.createElement("div", { className: "request-date col-header" }, "Last Updated")),
                        loadedOnce === false ? (React.createElement("div", { className: "loading-skeleton" },
                            React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                                React.createElement("div", { className: "pic" },
                                    React.createElement("div", { className: "animated-wave" })),
                                React.createElement("div", { className: "title" },
                                    React.createElement("div", { className: "title-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 :
                            lifecycleQueue.ArchivalProcessing.map(function (item) { return (React.createElement(RenderItem, { pw: item })); }),
                            (lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 : lifecycleQueue.ArchivalProcessing.length) === 0 ? React.createElement("div", { className: "no-results" }, "You do not have any workspaces being archived.") : null))),
                    React.createElement("div", { className: "request-divider" }),
                    React.createElement("div", { className: "request-container" },
                        React.createElement("div", { className: "request-title" },
                            React.createElement("div", { className: "icon-title col-header" }, "Pending")),
                        loadedOnce === false ? (React.createElement("div", { className: "loading-skeleton" },
                            React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                                React.createElement("div", { className: "pic" },
                                    React.createElement("div", { className: "circle animated-wave" })),
                                React.createElement("div", { className: "title" },
                                    React.createElement("div", { className: "title-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 :
                            lifecycleQueue.ArchivalPending.map(function (r) { return (React.createElement(RenderItem, { pw: r })); }),
                            (lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 : lifecycleQueue.ArchivalPending.length) === 0 ? React.createElement("div", { className: "no-results" }, "You do not have any pending workspaces.") : null))),
                    React.createElement("div", { className: "request-divider" }),
                    React.createElement("div", { className: "request-container" },
                        React.createElement("div", { className: "request-title" },
                            React.createElement("div", { className: "icon-title col-header" }, "Last 20 Complete")),
                        loadedOnce === false ? (React.createElement("div", { className: "loading-skeleton" },
                            React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                                React.createElement("div", { className: "pic" },
                                    React.createElement("div", { className: "circle animated-wave" })),
                                React.createElement("div", { className: "title" },
                                    React.createElement("div", { className: "title-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 :
                            lifecycleQueue.ArchivalComplete.map(function (r) { return (React.createElement(RenderItem, { pw: r })); }),
                            (lifecycleQueue === null || lifecycleQueue === void 0 ? void 0 : lifecycleQueue.ArchivalComplete.length) === 0 ? React.createElement("div", { className: "no-results" }, "You do not have any complete workspaces.") : null))))))) : (React.createElement(React.Fragment, null,
            React.createElement(Portlet, null,
                React.createElement(PortletBody, null, "You must be an administrator to view this information"))))));
});
export default LifecycleQueues;
