var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useForm } from "@mantine/form";
import { useGetPartnersSettingsQuery, useUpdatePartnersSettingsMutation, useVerifyPartnerSecretMutation } from "appv2/api/partners-settings/partners-settings.api";
import { OrchestrySuccessToast } from "appv2/components/orchestry-success-toast/orchestry-success-toast.component";
import { useGetUserPhoto } from "appv2/hooks/use-get-user-photo.hook";
import { useGetUser } from "appv2/hooks/use-get-user.hook";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
export var usePartnersSettingsPage = function () {
    var _a;
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenantName = orchestryContext === null || orchestryContext === void 0 ? void 0 : orchestryContext.TenantName;
    var _b = useGetPartnersSettingsQuery(), partnersSettings = _b.data, isPartnerSettingsLoading = _b.isLoading, isPartnerSettingsFetching = _b.isFetching, isPartnersSettingsSuccess = _b.isSuccess, refetchPartnerSettings = _b.refetch;
    var _c = useUpdatePartnersSettingsMutation(), updatePartnerSettings = _c[0], isUpdatePartnerSettingsSuccess = _c[1].isSuccess;
    var _d = useVerifyPartnerSecretMutation(), verifyPartnerSecret = _d[0], _e = _d[1], data = _e.data, isVerifyPartnerSecretLoading = _e.isLoading, isVerifiyPartnerSecretError = _e.isError, resetVerifyPartnerSecret = _e.reset;
    var secretKey = partnersSettings === null || partnersSettings === void 0 ? void 0 : partnersSettings.SecretKey;
    useEffect(function () {
        if (isPartnersSettingsSuccess && secretKey) {
            verifyPartnerSecret(secretKey);
        }
    }, [isPartnersSettingsSuccess, verifyPartnerSecret, secretKey]);
    var partnerTenantGuid = data === null || data === void 0 ? void 0 : data.TenantGUID;
    var partnerOrganizationName = data === null || data === void 0 ? void 0 : data.OrganizationName;
    var isPartnerConnected = !!partnerTenantGuid;
    console.log("isPartnerConnected", isPartnerConnected);
    var form = useForm({
        initialValues: {
            TenantGuid: "",
            SecretKey: "",
            IsConsented: false,
            ConsentedBy: "",
            ConsentedDate: "",
            SupportEmail: ""
        },
        validate: {
            IsConsented: function (value) { return (!value ? "Consent is required" : null); },
            SecretKey: function (value) { return (!value || isVerifiyPartnerSecretError ? "Invalid Partner Management Secret. Please try again." : null); }
        }
    });
    useEffect(function () {
        if (isVerifiyPartnerSecretError) {
            form.validate();
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVerifiyPartnerSecretError]);
    var consentedByGuid = form.getInputProps("ConsentedBy").value;
    var _f = useGetUser(consentedByGuid), consentedUser = _f.user, userIsLoading = _f.isLoading;
    var consentorDisplayName = consentedUser === null || consentedUser === void 0 ? void 0 : consentedUser.DisplayName;
    var _g = (_a = useGetUserPhoto(consentedByGuid)) !== null && _a !== void 0 ? _a : undefined, consentorPhoto = _g.base64Image, consentorPhotoIsLoading = _g.isLoading;
    var formSecretKey = form.getInputProps("SecretKey").value;
    var onSecretInputControlBlur = useCallback(function () { return verifyPartnerSecret(formSecretKey); }, [formSecretKey, verifyPartnerSecret]);
    // Added to verify the secret key when the user types it in the box.
    // IMO a better experience than waiting for the user to click out of the box
    useEffect(function () {
        if (formSecretKey) {
            setTimeout(function () {
                if (formSecretKey === form.getInputProps("SecretKey").value) {
                    verifyPartnerSecret(formSecretKey);
                }
            }, 500);
        }
    }, [formSecretKey]);
    useEffect(function () {
        if (partnersSettings) {
            form.setValues(partnersSettings);
            form.setInitialValues(partnersSettings);
        }
        // form is not a dependency because it is updated in the useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnersSettings]);
    // Refetch the settings when the page is navigated away from and back to
    useEffect(function () {
        refetchPartnerSettings();
    }, [refetchPartnerSettings]);
    useEffect(function () {
        if (isUpdatePartnerSettingsSuccess) {
            OrchestrySuccessToast("Partners settings saved successfully");
        }
    }, [isUpdatePartnerSettingsSuccess]);
    var handleFormSubmit = function (values) {
        form.setInitialValues(values);
        updatePartnerSettings(values).then(function () { return refetchPartnerSettings(); });
    };
    var onDisconnectTenantClicked = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            refetchPartnerSettings();
            resetVerifyPartnerSecret();
            return [2 /*return*/];
        });
    }); };
    var isPageLoading = !partnersSettings || isPartnerSettingsLoading || isPartnerSettingsFetching || userIsLoading || consentorPhotoIsLoading || !form.values;
    var isSaveDisabled = isPageLoading || isVerifyPartnerSecretLoading || !form.isDirty();
    var showEmail = isPartnerConnected && form.getInputProps("SupportEmail").value;
    return {
        isSaveDisabled: isSaveDisabled,
        isPageLoading: isPageLoading,
        form: form,
        handleFormSubmit: handleFormSubmit,
        consentorDisplayName: consentorDisplayName,
        consentorPhoto: consentorPhoto,
        partnerTenantGuid: partnerTenantGuid,
        partnerOrganizationName: partnerOrganizationName,
        isVerifyPartnerSecretLoading: isVerifyPartnerSecretLoading,
        verifyPartnerSecret: verifyPartnerSecret,
        onSecretInputControlBlur: onSecretInputControlBlur,
        showEmail: showEmail,
        onDisconnectTenantClicked: onDisconnectTenantClicked,
        currentTenantName: currentTenantName
    };
};
