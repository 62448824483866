import { Card, Stack } from "@mantine/core";
import { getReadableDate, getReadableDateFromNow } from "app/utils/dateUtils";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { toSimplePercentageLocaleString } from "appv2/utils/string.utils";
import React from "react";
import { CardKeyValue } from "../../card-key-value.component";
import { OrchestryCardTitle } from "../../orchestry-card-title.component";
import { useTenantPage } from "../../use-tenant-page.hook";
export var Archival = function () {
    var _a = useTenantPage(), _b = _a.tenantAnalytics.Archival, _c = _b === void 0 ? {} : _b, LastArchivalJobDate = _c.LastArchivalJobDate, CustomArchivalPoliciesCount = _c.CustomArchivalPoliciesCount, ArchivalPolicyApplicationPercentage = _c.ArchivalPolicyApplicationPercentage, isPageLoading = _a.isPageLoading;
    return (React.createElement(Card, { variant: "default", p: 24, h: "100%" },
        React.createElement(OrchestryCardTitle, { isLoading: isPageLoading, title: "Archival" }),
        React.createElement(Stack, { mt: 16, gap: 6 },
            React.createElement(CardKeyValue, { icon: ORCH_ICONS.calendar, keyString: "Last Archival Job Date", value: LastArchivalJobDate != null ? getReadableDate(LastArchivalJobDate) : "Never", suffix: LastArchivalJobDate != null ? "(".concat(getReadableDateFromNow(new Date(LastArchivalJobDate)), ")") : "", isLoading: isPageLoading }),
            React.createElement(CardKeyValue, { icon: ORCH_ICONS.archive, keyString: "Custom Archival Policies", value: CustomArchivalPoliciesCount === null || CustomArchivalPoliciesCount === void 0 ? void 0 : CustomArchivalPoliciesCount.toLocaleString(), isLoading: isPageLoading }),
            React.createElement(CardKeyValue, { icon: ORCH_ICONS.archive, keyString: "Archival Policy Application", value: toSimplePercentageLocaleString(ArchivalPolicyApplicationPercentage) + "%", isLoading: isPageLoading }))));
};
