var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AsyncWait } from "app/components/common/async";
import FormDropdownSelect from "app/components/common/form-dropdown-select";
import { SubtitleButton } from "app/components/common/subtitle";
import OrchestryService from "app/services/orchestry";
import TenantService from "app/services/tenant";
import { AsyncStatus } from "app/store/async";
import { ServiceAppPermissionConfiguration, TenantLicenseStatus, TenantType } from "app/store/tenants";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as TenantListStore from "app/store/tenants/list";
import { TenantListActionTypes } from "app/store/tenants/list/types";
import moment from "moment";
import * as React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import StatusAccessCode from "./status.access-code.component";
import "./status.component.scss";
export var TenantTypes = [
    { id: "NotDefined", title: "Not Defined", value: TenantType.NotDefined },
    { id: "OrchestryInternal", title: "Orchestry Internal", value: TenantType.OrchestryInternal },
    { id: "OrchestryCustomer", title: "Orchestry Customer", value: TenantType.OrchestryCustomer },
    { id: "PartnerHub", title: "Partner Hub", value: TenantType.PartnerHub },
    { id: "PartnerSandbox", title: "Partner Sandbox", value: TenantType.PartnerSandbox },
    { id: "PartnerCustomer", title: "Partner Customer", value: TenantType.PartnerCustomer }
];
var OrchestryTenantsDetailsStatus = React.memo(function () {
    var _a, _b;
    var tenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var _c = React.useState([]), activeRegions = _c[0], setActiveRegions = _c[1];
    var dispatch = useDispatch();
    var _d = React.useState(null), accessCodeTenant = _d[0], setAccessCodeTenant = _d[1];
    var licenseStatusTypes = [
        { id: "Unlicensed", title: "Unlicensed", value: TenantLicenseStatus.Unlicensed },
        { id: "PartnerHub", title: "Orchestry Trial", value: TenantLicenseStatus.OrchestryTrial },
        { id: "OrchestryCustomer", title: "Orchestry", value: TenantLicenseStatus.Orchestry },
        { id: "OrchestryInternal", title: "Beacon", value: TenantLicenseStatus.Beacon }
    ];
    var unarchiveTenant = function () {
        TenantService.unarchiveTenant(tenant.GUID).then(function () {
            dispatch(TenantCurrentStore.Clear());
            dispatch(TenantCurrentStore.LoadWithInfo(tenant.GUID));
        });
    };
    var archiveTenant = function () {
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, TenantListActionTypes.Tenant_List_Item_Delete).then(function () {
            dispatch(TenantCurrentStore.Clear());
            dispatch(TenantCurrentStore.LoadWithInfo(tenant.GUID));
        });
        dispatch(TenantListStore.DeleteItem(tenant));
    };
    React.useEffect(function () {
        OrchestryService.GetActiveRegionsAsDropdownOptions().then(function (regions) {
            setActiveRegions(regions);
        });
        return function () {
            dispatch(TenantCurrentStore.Clear());
        };
    }, []);
    var getLicenseStatus = function () {
        var tempLicenseStatus = __spreadArray([], licenseStatusTypes, true);
        tempLicenseStatus.forEach(function (item) {
            if (item.value === TenantLicenseStatus.Orchestry || item.value === TenantLicenseStatus.OrchestryTrial) {
                if ((tenant.LicenseStatus === TenantLicenseStatus.Beacon || tenant.LicenseStatus === TenantLicenseStatus.Unlicensed) &&
                    tenant.ServiceAppPermissionConfig === ServiceAppPermissionConfiguration.OrchestryReadAll) {
                    item.disabled = true;
                    item.description = "Cannot change license to Orchestry(Trial). Customer's GA needs to upgrade and consent to write permissions";
                }
            }
        });
        return tempLicenseStatus;
    };
    return (React.createElement(Container, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, { md: 6, lg: 5, xl: 4 },
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Tenant Guid"),
                    React.createElement(Form.Control, { placeholder: "", 
                        //@ts-ignore
                        value: tenant.GUID, disabled: true })),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-template-name" },
                    React.createElement(Form.Label, null, "Display Name (Might want to leave blank for customer to fill in)"),
                    React.createElement(Form.Control, { placeholder: "Enter Diplay Name", 
                        //@ts-ignore
                        value: tenant.DisplayName, onChange: function (event) { return dispatch(TenantCurrentStore.Update({ DisplayName: event.target.value })); } })),
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-tenant-type" },
                            React.createElement(Form.Label, null, "License Status"),
                            React.createElement(FormDropdownSelect, { list: getLicenseStatus(), value: (_a = tenant.LicenseStatus) !== null && _a !== void 0 ? _a : TenantLicenseStatus.Unlicensed, onChange: function (value) { return dispatch(TenantCurrentStore.Update({ LicenseStatus: value })); } }))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-expire-date" },
                            React.createElement(Form.Label, null, "Trial Expires"),
                            React.createElement(Form.Control, { placeholder: "", value: tenant.TrialExpires, type: "date", onChange: function (event) { return dispatch(TenantCurrentStore.Update({ TrialExpires: event.target.value })); } })))),
                React.createElement(StatusAccessCode, null),
                React.createElement(Row, null,
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-expire-date" },
                            React.createElement(Form.Label, null, "Renewal Date"),
                            React.createElement(Form.Control, { placeholder: "", value: tenant.RenewalDate, type: "date", onChange: function (event) { return dispatch(TenantCurrentStore.Update({ RenewalDate: event.target.value })); } }))),
                    React.createElement(Col, null,
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-expire-date" },
                            React.createElement(Form.Label, null, "Seat Count"),
                            React.createElement(Form.Control, { type: "number", className: "seats", onChange: function (event) {
                                    return dispatch(TenantCurrentStore.Update({
                                        Seats: event.target.value === "0" ? 0 : Number(event.target.value)
                                    }));
                                }, value: (tenant.Seats === null || tenant.Seats === undefined ? 0 : tenant.Seats).toString() })))),
                React.createElement(Form.Group, { className: "form-group", controlId: "control-tenant-type" },
                    React.createElement(Form.Label, null, "Tenant Type"),
                    React.createElement(FormDropdownSelect, { list: TenantTypes, value: (_b = tenant.TenantType) !== null && _b !== void 0 ? _b : TenantType.NotDefined, onChange: function (value) { return dispatch(TenantCurrentStore.Update({ TenantType: value })); } }))),
            React.createElement(Col, { md: 1, lg: 2, xl: 3 }),
            React.createElement(Col, null,
                React.createElement("div", { style: { paddingLeft: "20px" } }, tenant.TenantInformation !== undefined ? (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Signup form details"),
                    React.createElement("table", { className: "detail-form" },
                        React.createElement("tr", null,
                            React.createElement("td", null, "GUID"),
                            React.createElement("td", null, tenant.TenantInformation.GUID)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "First Name"),
                            React.createElement("td", null, tenant.TenantInformation.SignupFirstname)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Last Name"),
                            React.createElement("td", null, tenant.TenantInformation.SignupLastName)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Job Title"),
                            React.createElement("td", null, tenant.TenantInformation.SignupJobTitle)),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Phone Number"),
                            React.createElement("td", null, tenant.TenantInformation.SignupPhoneNumber))))) : null),
                React.createElement("div", { style: { paddingLeft: "20px", paddingTop: "30px" } },
                    React.createElement(React.Fragment, null,
                        React.createElement("h3", null, "Install Information"),
                        tenant !== undefined ? (React.createElement("table", { className: "detail-form" },
                            React.createElement("tr", null,
                                React.createElement("td", null, "Install Date"),
                                React.createElement("td", null, tenant.TrialStart)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Installed Version"),
                                React.createElement("td", null, tenant.LastInstalledVersion)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Orchestry App Installed Version"),
                                React.createElement("td", null, tenant.LastConsentedVersion)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Server App Installed Version - Read & Write"),
                                React.createElement("td", null, tenant.ServiceAppLastConsentedVersion)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Server App Installed Version - Read-Only"),
                                React.createElement("td", null, tenant.ServiceAppReadAllLastConsentedVersion)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Service App - Permissions Config"),
                                React.createElement("td", null, tenant.ServiceAppPermissionConfig)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Private Cosmos Location"),
                                React.createElement("td", null, tenant.HasPrivateCosmosDbConnectionString === true ? "Customer Hosted" : "Shared")),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Private Azure Table Location"),
                                React.createElement("td", null, tenant.HasPrivateAzureStorageConnectionString === true ? "Customer Hosted" : "Shared")),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Tenant Db SQL Location"),
                                React.createElement("td", null, tenant.TenantDbSource === "Private" ? "Customer Hosted" : tenant.TenantDbSource)),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Subscription Disabled Date"),
                                React.createElement("td", null, tenant.SubscriptionDisabled !== undefined && tenant.SubscriptionDisabled !== null ? moment(tenant.SubscriptionDisabled).format("LL") : "---")))) : null)),
                React.createElement("div", { style: { paddingLeft: "20px", paddingTop: "30px" } },
                    React.createElement(React.Fragment, null,
                        React.createElement("h3", null, "Maintenance Information"),
                        tenant != null ? (React.createElement("table", { className: "detail-form" },
                            React.createElement("tr", null,
                                React.createElement("td", null, "Secret Refresh Date"),
                                React.createElement("td", null, tenant.ServiceAppSecretLastRefreshedDate !== undefined && tenant.ServiceAppSecretLastRefreshedDate != null && tenant.ServiceAppSecretLastRefreshedDate === ""
                                    ? "---"
                                    : moment(tenant.ServiceAppSecretLastRefreshedDate).format("LL"))),
                            React.createElement("tr", null,
                                React.createElement("td", null, "Secret Refresh Failed"),
                                React.createElement("td", null, tenant.ServiceAppSecretLastRefreshedFailed != null ? (tenant.ServiceAppSecretLastRefreshedFailed ? "True" : "False") : "False")),
                            React.createElement("tr", null,
                                React.createElement("td", null, "SP Certificate Refresh Date"),
                                React.createElement("td", null, tenant.ServiceAppSPCertRefreshedDate != null && tenant.ServiceAppSPCertRefreshedDate === ""
                                    ? "---"
                                    : moment(tenant.ServiceAppSPCertRefreshedDate).format("LL"))))) : null)),
                React.createElement("div", { style: { paddingLeft: "20px", paddingTop: "30px" } }, tenant.IsArchived !== undefined && tenant.IsArchived === true ? (React.createElement(React.Fragment, null,
                    React.createElement(SubtitleButton, { title: "Unarchive", color: "orange", onClick: function () { return unarchiveTenant(); }, noMargin: true }),
                    tenant.ArchivedDate !== undefined && tenant.ArchivedDate !== null && (React.createElement(React.Fragment, null,
                        React.createElement("div", { style: { fontWeight: 500, marginTop: "10px" } },
                            "All tenant data will be purged 90 days after archival on",
                            " ",
                            moment(tenant.ArchivedDate)
                                .add("day", 90)
                                .format("LL")),
                        React.createElement("br", null),
                        React.createElement(Form.Group, { className: "form-group", controlId: "control-archive-date" },
                            React.createElement(Form.Label, null, "Archival Date"),
                            React.createElement(Form.Control, { placeholder: "", value: tenant.ArchivedDate, type: "date", onChange: function (event) { return dispatch(TenantCurrentStore.Update({ ArchivedDate: event.target.value })); } })))))) : (React.createElement(React.Fragment, null,
                    React.createElement(SubtitleButton, { title: "Archive", color: "orange", onClick: function () { return archiveTenant(); }, noMargin: true }))))))));
});
export default OrchestryTenantsDetailsStatus;
