import FormToggleControl from "app/components/common/form-toggle-control";
import * as PageHeader from "app/components/common/page-header";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import PortletAlert from "app/components/common/portlet-alert";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import CardPrivateAzureStorage from "app/components/lifecycle/workspaces/settings/cards/card-private-azure-storage";
import CardPrivateCosmosDb from "app/components/lifecycle/workspaces/settings/cards/card-private-cosmos-db";
import CardPrivateTenantDb from "app/components/lifecycle/workspaces/settings/cards/card-private-tenant-db";
import InstallService from "app/services/install";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
import * as TenantCurrentStore from "app/store/tenants/current";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import "./settings-installation.scss";
import SettingsInstallationFeatureEnablement from "app/components/settings/settings-installation/components/settings-installation-feature-enablement";
import SettingsInstallationStatus from "app/components/settings/settings-installation/components/settings-installation-status";
import SettingsInstallationTeamsAppStatus from "app/components/settings/settings-installation/components/settings-installation-teams-app-status";
import SettingsInstallationWebpartStatus from "app/components/settings/settings-installation/components/settings-installation-webpart-status";
import GroupsService from "app/services/groups";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useCustomEventListener } from "react-custom-events";
var SettingsInstallation = React.memo(function (_a) {
    var InstallStatusData = _a.InstallStatusData;
    var dispatch = useDispatch();
    var _b = React.useState(), installStatus = _b[0], setInstallStatus = _b[1];
    var _c = React.useState(false), groupEnablement = _c[0], setgroupEnablement = _c[1];
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    React.useEffect(function () {
        // console.log("currentTenant", currentTenant);
        dispatch(TenantCurrentStore.LoadWithInfo(""));
    }, []);
    useCustomEventListener("orchestry-update-complete", function () {
        setInstallStatus(undefined);
        dispatch(OrchestryContextStore.Refresh());
        InstallService.GetInstallStatus().then(function (data) {
            setInstallStatus(data);
        });
        GroupsService.getGroupEnblement();
    });
    // On first load, Load the data from the server.
    React.useEffect(function () {
        InstallService.GetInstallStatus().then(function (data) {
            setInstallStatus(data);
        });
        GroupsService.getGroupEnblement().then(function (data) {
            setgroupEnablement(data);
        });
    }, []);
    return (React.createElement("div", { className: "settings-installation fadein-500ms" },
        React.createElement(PageHeader.SetLeftContent, { title: "Installation", subTitle: "Information and options" }),
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(SettingsInstallationStatus, null),
                !currentUserIsBeaconUser && React.createElement(SettingsInstallationTeamsAppStatus, { InstallStatusData: installStatus }),
                !currentUserIsBeaconUser && React.createElement(SettingsInstallationFeatureEnablement, { InstallStatusData: installStatus, GroupEnablement: groupEnablement })),
            React.createElement(Col, null,
                !currentUserIsBeaconUser && React.createElement(SettingsInstallationWebpartStatus, null),
                React.createElement(Portlet, null,
                    React.createElement(PortletHeader, { title: "Database Hosting" }),
                    React.createElement(PortletBody, null,
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement("h5", { style: { marginBottom: "25px" } }, "Cosmos Db"),
                                React.createElement(CardPrivateCosmosDb, { ShowPortlet: false }))),
                        React.createElement(Row, { style: { marginTop: "30px" } },
                            React.createElement(Col, null,
                                React.createElement("h5", { style: { marginBottom: "25px" } }, "Azure Storage"),
                                React.createElement(CardPrivateAzureStorage, { ShowPortlet: false }))),
                        React.createElement(Row, { style: { marginTop: "30px" } },
                            React.createElement(Col, null,
                                React.createElement("h5", { style: { marginBottom: "25px" } }, "Sql Tenant Storage"),
                                React.createElement(CardPrivateTenantDb, { ShowPortlet: false })))))))));
});
export default SettingsInstallation;
var SettingsInstallationTeamsAppInstallToggle = React.memo(function () {
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var currentTenant = useSelector(function (state) { return state.tenantCurrentState.item; });
    var dispatch = useDispatch();
    return (React.createElement("div", { className: "teams-app-skip-install fadein-500ms" },
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "teams-app-install-choice" },
                !orchestryContext.IsCurrentUserAzureGlobalAdmin ? (React.createElement(PortletAlert, { size: "small", icon: "alert", type: "warning", message: "You must be a global administrator to perform these actions." })) : null,
                React.createElement("div", { className: "permission-options lifecycle-toggle" },
                    React.createElement("div", { className: "permissions-area" },
                        React.createElement("div", { className: "permissions-content" }, currentTenant === undefined || (currentTenant !== undefined && currentTenant.SkipTeamsAppInstall === undefined) ? (React.createElement("div", { style: { marginBottom: "2rem", marginTop: "10px" } },
                            React.createElement(SkeletonLoaderFakeBox, null))) : (React.createElement("div", { className: "content-wrap" },
                            React.createElement(FormToggleControl, { border: true, checked: !currentTenant.SkipTeamsAppInstall, label: "Install/update Orchestry teams app in future Orchestry updates?", handleChange: function (checked) { return dispatch(TenantCurrentStore.UpdateSilent({ SkipTeamsAppInstall: !checked })); } }))))))))));
});
