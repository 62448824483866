import { getReadableDate } from "app/utils/dateUtils";
import React from "react";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
/**
 * Renders a date column for the OrchestryDataTable
 *
 * @param date The date to display
 * @param isLoading The loading state of the date
 */
export var OrchestryDataTableDateColumn = function (date, isLoading) {
    if (!date) {
        return null;
    }
    return (React.createElement(OrchestryText, { isLoading: isLoading, c: "text-secondary.0", size: "14px", fw: 400, lh: "20px" }, getReadableDate(date)));
};
