import * as React from "react";
import "./request-queue.scss";
import { useSelector, useDispatch } from "react-redux";
import * as RequestListStore from "app/store/requests/list";
import iconSP from "app/assets/img/icon-sharepoint.svg";
import iconTeams from "app/assets/img/icon-teams.svg";
import { NavLink } from "react-router-dom";
import { Portlet, PortletBody } from "app/components/common/portlet";
import Subtitle, { SubtitleButton } from "app/components/common/subtitle";
import { RenderWithLinkIfCan } from "app/components/requests/request-details";
import moment from "moment";
import RequestStatus from "app/components/requests/request-status";
import OfficeProfilePicture from "app/components/common/office-profile-picture";
import { AsyncWait } from "app/components/common/async";
import { AsyncCallType, AsyncStatus } from "app/store/async";
import { FormattedMessage } from "react-intl";
var RequestQueue = React.memo(function (props) {
    var _a, _b, _c, _d;
    var dispatch = useDispatch();
    var requests = useSelector(function (state) { return state.requestListState.items; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    // const currentTenant: Tenant = useSelector((state: RootState) => state.tenantCurrentState.item);
    var _e = React.useState(false), loadedOnce = _e[0], setLoadedOnce = _e[1];
    var _f = React.useState(true), loopForUpdates = _f[0], setLoopForUpdates = _f[1];
    var refLoopForUpdates = React.useRef(loopForUpdates);
    React.useEffect(function () {
        // dispatch(TenantCurrentStore.LoadWithInfo(""));
    }, []);
    React.useEffect(function () {
        dispatch(RequestListStore.Load_Page({
            items: [],
            view: "queue",
            paging: { CurrentPage: 1, ResultsPerPage: 100 }
        }));
        AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, null, null, "Request_List_Load_Page").then(function () {
            setLoadedOnce(true);
        });
        loopForUpdatesWatch();
        return function () {
            refLoopForUpdates.current = false;
            setLoopForUpdates(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var loopForUpdatesWatch = function () {
        AsyncWait(AsyncCallType.List, AsyncStatus.Succeeded, null, null, "Request_List_Load_Page").then(function () {
            if (refLoopForUpdates.current === true) {
                setTimeout(function () {
                    if (refLoopForUpdates.current === true) {
                        dispatch(RequestListStore.Load_Page({
                            items: [],
                            view: "queue",
                            paging: { CurrentPage: 1, ResultsPerPage: 100 }
                        }));
                        loopForUpdatesWatch();
                    }
                }, 5000);
            }
        });
    };
    var renderType = function (request) {
        if (request.RequestType === "SharePoint") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconSP, alt: "SharePoint", className: "fadein-500ms" }))));
        }
        if (request.RequestType === "Team") {
            return (React.createElement("div", { className: "icon-sp-type" },
                React.createElement("span", { className: "icon-holder" },
                    React.createElement("img", { src: iconTeams, alt: "Teams", className: "fadein-500ms" }))));
        }
        return React.createElement(React.Fragment, null);
    };
    var getItems = function (status) {
        console.log("getItems", requests);
        if (status === "pending") {
            return requests === null || requests === void 0 ? void 0 : requests.filter(function (r) {
                if (["ReadyToProvision"].some(function (sp) { return sp === r.Status; })) {
                    return true;
                }
                return false;
            });
        }
        else if (status === "provisioning") {
            return requests === null || requests === void 0 ? void 0 : requests.filter(function (r) {
                if (["Provisioning"].some(function (sp) { return sp === r.Status; })) {
                    return true;
                }
                return false;
            });
        }
    };
    var getWidthOfProgress = function (numbers) {
        if (numbers.indexOf("|") > -1) {
            var nums = numbers.split("|");
            var total = Number(nums[0]);
            var complete = Number(nums[1]);
            return (complete / total) * 100 + "%";
        }
        else {
            return "100%";
        }
    };
    var RenderItem = function (_a) {
        var r = _a.r, showLinks = _a.showLinks;
        var isPending = false;
        if (["ReadyToProvision", "Provisioning"].some(function (sp) { return sp === r.Status; })) {
            isPending = true;
        }
        return (React.createElement("div", { className: "request-item" },
            React.createElement("div", { className: "icon" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement(OfficeProfilePicture, { base64image: r.Image, displayName: r.Name, type: "SharePoint", size: "sm" })) : (React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true },
                React.createElement(OfficeProfilePicture, { base64image: r.Image, displayName: r.Name, type: "SharePoint", size: "sm" })))),
            React.createElement("div", { className: "title" },
                React.createElement("div", { className: "content" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement(React.Fragment, null, r.Name)) : (React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true }, r.Name)))),
            React.createElement("div", { className: "status" }, r.ProvisioningStateData !== undefined && r.ProvisioningStateData !== null && r.ProvisioningStateData.indexOf("|") > -1 ? (React.createElement("div", { className: "percent" },
                React.createElement("div", { className: "wrapper" },
                    React.createElement("div", { className: "bar", style: { width: getWidthOfProgress(r.ProvisioningStateData) } },
                        React.createElement("div", { className: "complete" }, r.ProvisioningStateData.split("|")[1])),
                    React.createElement("div", { className: "total" }, r.ProvisioningStateData.split("|")[0])))) : null),
            React.createElement("div", { className: "request-date" },
                React.createElement("span", null, moment
                    .utc(r.RequestedDate)
                    .local()
                    .format("Do MMM h:mm a"))),
            React.createElement("div", { className: "link" }, isPending === true || r.Status === "Rejected" || r.Status === "Failed" ? (React.createElement("span", { className: "request-status" },
                React.createElement(RequestStatus, { request: r }))) : (
            // <><AnimatedLoader size="xxsmall" />
            React.createElement(RenderWithLinkIfCan, { currentRequest: r, openInTeamsIfPossible: true },
                React.createElement("div", { className: "open" },
                    React.createElement(FormattedMessage, { id: "page.request_queue.open_workspace", defaultMessage: "Open Workspace" })))))));
    };
    return (React.createElement("div", { className: "request-queue" },
        React.createElement(Subtitle, { title: "Workspace Requests", subtitle: React.createElement(React.Fragment, null,
                React.createElement(FormattedMessage, { id: "page.request_queue.tenant_label", defaultMessage: "Tenant Queue" })) },
            React.createElement(NavLink, { to: "/requests" },
                React.createElement(SubtitleButton, { title: "Back", faIcon: "arrow-left", variant: "secondary" }))),
        orchestryContext.IsCurrentUserOrchestryAdmin === true ? (React.createElement(React.Fragment, null,
            React.createElement(Portlet, { className: "current-status", fluidHeight: true },
                React.createElement(PortletBody, null,
                    React.createElement("div", { className: "request-container" },
                        React.createElement("div", { className: "request-title" },
                            React.createElement("div", { className: "icon-title col-header" },
                                React.createElement(FormattedMessage, { id: "page.request_queue.provisioning", defaultMessage: "Provisioning" })),
                            React.createElement("div", { className: "request-date col-header" },
                                React.createElement(FormattedMessage, { id: "page.request_queue.request_date", defaultMessage: "Request date" })),
                            React.createElement("div", { className: "link col-header" })),
                        loadedOnce === false ? (React.createElement("div", { className: "loading-skeleton" },
                            React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                                React.createElement("div", { className: "pic" },
                                    React.createElement("div", { className: "circle animated-wave" })),
                                React.createElement("div", { className: "title" },
                                    React.createElement("div", { className: "title-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, (_a = getItems("provisioning")) === null || _a === void 0 ? void 0 :
                            _a.map(function (r) { return (React.createElement(RenderItem, { r: r, showLinks: true })); }),
                            ((_b = getItems("provisioning")) === null || _b === void 0 ? void 0 : _b.length) === 0 ? (React.createElement("div", { className: "no-results" },
                                React.createElement(FormattedMessage, { id: "page.request_queue.no_provisioning_requests", defaultMessage: "You do not have any requests provisioning." }))) : null))),
                    React.createElement("div", { className: "request-divider" }),
                    React.createElement("div", { className: "request-container" },
                        React.createElement("div", { className: "request-title" },
                            React.createElement("div", { className: "icon-title col-header" },
                                React.createElement(FormattedMessage, { id: "page.request_queue.pending_label", defaultMessage: "Pending" }))),
                        loadedOnce === false ? (React.createElement("div", { className: "loading-skeleton" },
                            React.createElement("div", { className: "items" }, [1, 1, 1].map(function (what, index) { return (React.createElement("div", { className: "item", key: index },
                                React.createElement("div", { className: "pic" },
                                    React.createElement("div", { className: "circle animated-wave" })),
                                React.createElement("div", { className: "title" },
                                    React.createElement("div", { className: "title-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })),
                                React.createElement("div", { className: "property" },
                                    React.createElement("div", { className: "property-box animated-wave" })))); })))) : (React.createElement("div", { className: "request-list" }, (_c = getItems("pending")) === null || _c === void 0 ? void 0 :
                            _c.map(function (r) { return (React.createElement(RenderItem, { r: r, showLinks: false })); }),
                            ((_d = getItems("pending")) === null || _d === void 0 ? void 0 : _d.length) === 0 ? (React.createElement("div", { className: "no-results" },
                                React.createElement(FormattedMessage, { id: "page.request_queue.no_pending_requests", defaultMessage: "You do not have any pending requests." }))) : null))))))) : (React.createElement(React.Fragment, null,
            React.createElement(Portlet, null,
                React.createElement(PortletBody, null,
                    React.createElement(FormattedMessage, { id: "page.request_queue.must_be_admin", defaultMessage: "You must be an administrator to view this information" })))))));
});
export default RequestQueue;
