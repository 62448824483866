import React from "react";
import { IntlProvider } from "react-intl";
import { AddAnnotationModalContent } from "./add-annotation-modal-content.component";

/**
 * This wrapper component is used to provide the necessary context to the AddAnnotationModalContent component
 * because some of it's subcomponents use a useIntl hook which requires the IntlProvider to be present in the component tree.
 */
export const AddAnnotationModal = props => {
   const {
      context,
      id,
      innerProps: { healthCheckId, annotationCategory, associatedGUID, intl, tenantGuid, onDeleteAnnotationClicked, hideAnnotationList, shouldShowMutativeButtons }
   } = props;
   const children = (
      <AddAnnotationModalContent
         context={context}
         id={id}
         innerProps={{ healthCheckId, annotationCategory, associatedGUID, intl, tenantGuid, onDeleteAnnotationClicked, hideAnnotationList, shouldShowMutativeButtons }}
      />
   );

   return (
      <IntlProvider locale={intl.locale} messages={intl.messages}>
         {children}
      </IntlProvider>
   );
};
