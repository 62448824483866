var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Collapse } from "@mantine/core";
import { Portlet, PortletBody } from "app/components/common/portlet";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryActionButtons } from "appv2/components/orchestry-action-buttons/orchestry-action-buttons.component";
import { OrchestryButton } from "appv2/components/orchestry-button/orchestry-button.component";
import { OrchestryFilterSelector } from "appv2/components/orchestry-filter-selector/orchestry-filter-selector.component";
import { OrchestryPageHeader } from "appv2/components/orchestry-page-header/orchestry-page-header.component";
import { OrchestryPageTitle } from "appv2/components/orchestry-page-title/orchestry-page-title.component";
import { OrchestryPagination } from "appv2/components/orchestry-pagination/orchestry-pagination.component";
import { OrchestrySearchBox } from "appv2/components/orchestry-searchbox/orchestry-searchbox.component";
import { OrchestryTabs } from "appv2/components/orchestry-tabs/orchestry-tabs.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import React from "react";
import { OrchestryQueryContext } from "../../../components/orchestry-query-context/orchestry-query-context.component";
import { RecommendationShareSuggestion } from "./recommendation-footer/recommendation-share-suggestion/recommendation-share-suggestion.component";
import { RecommendationHeaderSkeletons } from "./recommendation-header/recommendation-header-sekeletons/recommendation-header-skeletons.component";
import { RecommendationNumberStat } from "./recommendation-header/recommendation-number-stat.component";
import { RecommendationPriorityMetric } from "./recommendation-header/recommendation-priority-metric.component";
import { RecommendationTopCategoriesMetric } from "./recommendation-header/recommendation-top-categories-metric.component";
import { RecommendationsList } from "./recommendation-list/recommendations-list.component";
import { RecommendationTabsSkeletons } from "./recommendation-tabs-skeletons/recommendations-tabs-skeletons.component";
import { RecommendationViewHistoryButton } from "./recommendation-view-history-button.component";
import { RecommendationsNotAvailableModal } from "./recommendations-not-available-modal.component";
import "./recommendations-page.scss";
import { RECOMMENDATIONS_FILTER_GROUPS } from "./recommendations-page.utils";
import { useRecommendationsPage } from "./use-recommendations-page.hook";
export var RecommendationsPage = function () {
    var _a = useRecommendationsPage(), intl = _a.intl, pagedRecommendations = _a.pagedRecommendations, pagedRecommendationsIsFetching = _a.pagedRecommendationsIsFetching, recommendationRecordVisibilityCountsIsLoading = _a.recommendationRecordVisibilityCountsIsLoading, applyFilterButtonFilters = _a.applyFilterButtonFilters, clearAllFilters = _a.clearAllFilters, priorityMetricConfig = _a.priorityMetricConfig, topCategoryConfig = _a.topCategoryConfig, recommendationStatsSuccessful = _a.recommendationStatsSuccessful, removeFilter = _a.removeFilter, appliedFilters = _a.appliedFilters, shouldShowShareSuggestion = _a.shouldShowShareSuggestion, shouldShowViewHistory = _a.shouldShowViewHistory, navigateToResolutionsPage = _a.navigateToResolutionsPage, isTabsDeactivated = _a.isTabsDeactivated, shouldShowRecommendationsStats = _a.shouldShowRecommendationsStats, recommendationStats = _a.recommendationStats, activePage = _a.activePage, setActivePage = _a.setActivePage, resultsPerPage = _a.resultsPerPage, setResultsPerPage = _a.setResultsPerPage, querySearchText = _a.querySearchText, searchTextInputValue = _a.searchTextInputValue, badgeOnClick = _a.badgeOnClick, tab = _a.tab, setTab = _a.setTab, tabs = _a.tabs, togglePrioritySort = _a.togglePrioritySort, recommendationsEnabled = _a.recommendationsEnabled, pageOptions = _a.pageOptions, onSearchBoxChange = _a.onSearchBoxChange, onSearchBoxKeyDown = _a.onSearchBoxKeyDown, onSearchBoxXClicked = _a.onSearchBoxXClicked, isPageLoading = _a.isPageLoading, onSettingsClicked = _a.onSettingsClicked, onAddClicked = _a.onAddClicked, highlightSearchWords = _a.highlightSearchWords;
    return (React.createElement(Portlet, null,
        React.createElement(PortletBody, null,
            !recommendationsEnabled && React.createElement(RecommendationsNotAvailableModal, null),
            React.createElement("div", { className: "recommendations-page" },
                React.createElement(OrchestryPageHeader, null,
                    React.createElement(OrchestryPageTitle, { isLoading: isPageLoading, title: "Recommendations" }),
                    React.createElement(OrchestryActionButtons, null,
                        React.createElement(OrchestryButton, { onClick: onAddClicked, isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 80, iconType: "font", size: "md", iconLeft: ORCH_ICONS.plusCircle, variant: "secondary-gray" }, intl.formatMessage({ id: "controls.add", defaultMessage: "Add" })),
                        React.createElement(OrchestryButton, { isLoading: isPageLoading, skeletonHeight: 34, skeletonWidth: 102, iconType: "font", size: "md", iconLeft: ORCH_ICONS.settings, variant: "secondary-gray", onClick: onSettingsClicked }, intl.formatMessage({ id: "controls.settings", defaultMessage: "Settings" })))),
                React.createElement("div", { className: "page-header" },
                    React.createElement("div", { className: "filter-row" },
                        React.createElement("div", { className: "left-side" }, recommendationRecordVisibilityCountsIsLoading ? (React.createElement(RecommendationTabsSkeletons, null)) : (React.createElement(OrchestryTabs, { showCount: true, tabs: tabs, tab: tab, onTabChange: setTab, isDeactivated: isTabsDeactivated }))),
                        React.createElement("div", { className: "right-side" },
                            React.createElement(OrchestrySearchBox, { placeholder: "Search", radius: "6px", tooltipLabel: "Click to perform a search within your current results", onXClicked: onSearchBoxXClicked, onChange: onSearchBoxChange, value: searchTextInputValue, onKeyDown: onSearchBoxKeyDown }),
                            React.createElement(OrchestryTooltip, { label: "Click to apply filters to your current results" },
                                React.createElement(OrchestryFilterSelector, { popoverPosition: "bottom-end", buttonTitle: "Filters", filters: appliedFilters, onApply: applyFilterButtonFilters, onClear: clearAllFilters, buttonSize: "sm" })))),
                    isPageLoading && tab === RecommendationRecordVisibility.Visible ? (React.createElement(RecommendationHeaderSkeletons, null)) : (recommendationStatsSuccessful && (React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: shouldShowRecommendationsStats },
                        React.createElement("div", { className: "stats-header" },
                            priorityMetricConfig && React.createElement(RecommendationPriorityMetric, __assign({}, priorityMetricConfig)),
                            React.createElement(RecommendationNumberStat, { count: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsResolvedThisMonthCount, footerLabel: "Last 30 days", percentChange: {
                                    iconIncrease: ORCH_ICONS.arrowUp,
                                    iconDecrease: ORCH_ICONS.arrowDown,
                                    value: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsResolvedMonthlyPercentIncrease
                                }, iconLabel: {
                                    iconClassName: ORCH_ICONS.checkDone02,
                                    iconVariant: "light-circle",
                                    iconSize: "sm",
                                    iconColorVariant: "pink-6",
                                    textColorVariant: "text-secondary",
                                    textLabel: "Resolutions",
                                    textFW: 400,
                                    textSize: "sm",
                                    colour: "text-secondary.0"
                                }, onClick: navigateToResolutionsPage }),
                            React.createElement(RecommendationNumberStat, { count: recommendationStats === null || recommendationStats === void 0 ? void 0 : recommendationStats.RecommendationsNewThisMonthCount, footerLabel: "Last 30 days", iconLabel: {
                                    iconClassName: ORCH_ICONS.star05,
                                    iconVariant: "light-circle",
                                    iconSize: "sm",
                                    iconColorVariant: "success-6",
                                    textColorVariant: "text-secondary",
                                    textLabel: "New",
                                    textFW: 400,
                                    textSize: "sm",
                                    colour: "text-secondary.0"
                                } }),
                            topCategoryConfig && React.createElement(RecommendationTopCategoriesMetric, __assign({}, topCategoryConfig))))))),
                React.createElement("div", { className: "query-context" },
                    React.createElement(Collapse, { transitionDuration: 250, transitionTimingFunction: "ease-in", in: appliedFilters.some(function (filter) { return filter.selected; }) },
                        React.createElement(OrchestryQueryContext, { filterGroups: RECOMMENDATIONS_FILTER_GROUPS, appliedFilters: appliedFilters, totalResults: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) || 0, removeFilter: removeFilter, clearAllFilters: clearAllFilters }))),
                React.createElement(RecommendationsList, { currentTab: tab, isLoading: pagedRecommendationsIsFetching, badgeOnClick: badgeOnClick, priorityOnClick: togglePrioritySort, recommendations: pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.Items, searchString: querySearchText, highlightSearchWords: highlightSearchWords }),
                shouldShowViewHistory && React.createElement(RecommendationViewHistoryButton, { onClick: navigateToResolutionsPage }),
                React.createElement(OrchestryPagination, { onResultsPerPageChange: function (value) {
                        setResultsPerPage(value);
                        localStorage.setItem("recommendations-resultsPerPage", value);
                    }, resultsPerPage: resultsPerPage, activePage: activePage, setactivePage: setActivePage, totalCount: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.TotalCount) || 0, pageTotal: (pagedRecommendations === null || pagedRecommendations === void 0 ? void 0 : pagedRecommendations.PageTotal) || 0, resultOptions: pageOptions }),
                shouldShowShareSuggestion && React.createElement(RecommendationShareSuggestion, null)))));
};
