import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as AuthStore from "app/store/auth";
import AuthenticationService from "app/services/authentication";
import getParameterByName from "app/utils/getQueryStringParam";
var Authenticate = React.memo(function (props) {
    var _a = React.useState(false), doneCheck = _a[0], setDoneCheck = _a[1];
    var dispatch = useDispatch();
    var auth = useSelector(function (state) { return state.authState; });
    function waitForPropertyToBePopulated(timeoutMs) {
        return new Promise(function (resolve, reject) {
            var startTime = Date.now();
            var pollInterval = 100; // Adjust as needed
            function poll() {
                if (document.OrchestryIsInSharePoint !== undefined) {
                    resolve(document.OrchestryIsInSharePoint);
                }
                else if (Date.now() - startTime >= timeoutMs) {
                    reject(new Error("Timeout: Property was not populated within the specified time."));
                }
                else {
                    setTimeout(poll, pollInterval);
                }
            }
            poll();
        });
    }
    React.useEffect(function () {
        var teamParamCheck = getParameterByName("tid");
        var iframemode = getParameterByName("iframemode");
        var isInTeams = true;
        var isInSharePoint = false;
        if (teamParamCheck === null) {
            isInTeams = false;
        }
        if (teamParamCheck !== null && teamParamCheck === "{tid}") {
            isInTeams = false;
        }
        if (iframemode !== null && iframemode === "sharepoint") {
            isInSharePoint = true;
        }
        if (isInTeams) {
            console.log("Orchestry Authentication Mode > Teams");
            AuthenticationService.loginTeamsUserIntoOrchestry()
                .then(function (data) {
                dispatch(AuthStore.IsAccessTokenValid());
            })
                .catch(function (error) {
                console.log("error", error);
            });
        }
        else if (isInSharePoint) {
            waitForPropertyToBePopulated(5000).then(function () {
                AuthenticationService.loginTeamsUserIntoOrchestry()
                    .then(function (data) {
                    dispatch(AuthStore.IsAccessTokenValid());
                })
                    .catch(function (error) {
                    console.log("error", error);
                });
            });
        }
        // If we are standalone
        else {
            console.log("Orchestry Authentication Mode > Standalone");
            dispatch(AuthStore.IsAccessTokenValid());
        }
    }, []);
    React.useEffect(function () {
        if (auth.isAuthorized !== undefined) {
            setDoneCheck(true);
        }
    }, [auth]);
    if (doneCheck) {
        return React.createElement(React.Fragment, null, props.children);
    }
    else {
        return React.createElement(React.Fragment, null);
    }
});
export default Authenticate;
