import * as React from "react";
import "./request-details-approval.scss";
import { Row, Col, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as RequestCurrentStore from "app/store/requests/current";
import { Portlet, PortletBody, PortletHeader } from "app/components/common/portlet";
import AnimatedLoader from "app/components/common/animated-loader";
import PortletAlert from "app/components/common/portlet-alert";
import { AsyncWait } from "app/components/common/async";
import { AsyncStatus } from "app/store/async";
import DisableArea from "app/components/common/disable-area";
import { useIntl, FormattedMessage } from "react-intl";
var RequestDetailsApproval = React.memo(function (props) {
    //console.log("Request Param Guid", props.match.params.guid);
    var intl = useIntl();
    var dispatch = useDispatch();
    var currentRequest = useSelector(function (state) { return state.requestCurrentState.item; });
    var currentTemplate = useSelector(function (state) { return state.templateCurrentState.item; });
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _a = React.useState(""), approvalFeedback = _a[0], setApprovalFeedback = _a[1];
    var _b = React.useState(""), approvalAdminFeedback = _b[0], setApprovalAdminFeedback = _b[1];
    var _c = React.useState(false), actionProcessing = _c[0], setActionProcessing = _c[1];
    var _d = React.useState(props.ParamAction), queryAction = _d[0], setQueryAction = _d[1];
    var _e = React.useState(false), showApprovalBoxModal = _e[0], setShowApprovalBoxModal = _e[1];
    var _f = React.useState(true), rejectDisabled = _f[0], setRejectDisabled = _f[1];
    var _g = React.useState(true), rejectAdminDisabled = _g[0], setRejectAdminDisabled = _g[1];
    React.useEffect(function () {
        setQueryAction(props.ParamAction);
    }, [props.ParamAction]);
    React.useEffect(function () {
        if (queryAction !== undefined && queryAction !== null && queryAction !== "") {
            setShowApprovalBoxModal(true);
        }
        else {
            setShowApprovalBoxModal(false);
        }
    }, [queryAction]);
    var submitStatusUpdate = function (action) {
        setActionProcessing(true);
        dispatch(RequestCurrentStore.UpdateStatus({
            itemGUID: currentRequest.GUID,
            action: action,
            feedback: action === "ApproveOverride" || action === "RejectOverride" ? approvalAdminFeedback : approvalFeedback
        }));
        AsyncWait(undefined, AsyncStatus.Succeeded, undefined, undefined, "Request_Current_Update_Status").then(function () {
            setActionProcessing(false);
        });
    };
    React.useEffect(function () {
        if ("Failed" === currentRequest.Status || "Rejected" === currentRequest.Status || "Provisioned" === currentRequest.Status || "ApprovalRequired" === currentRequest.Status) {
            setActionProcessing(false);
        }
    }, [currentRequest.Status]);
    var canCurrentUserSeeApprovalActions = function () {
        var canSee = true;
        if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.RequireApprovalBeforeProvisioning) === false) {
            canSee = false;
        }
        if (currentRequest.Status !== "ApprovalRequired") {
            canSee = false;
        }
        if (currentRequest.ApprovalWorkflow !== undefined && currentRequest.ApprovalWorkflow !== null && currentRequest.ApprovalWorkflow.AssociatedSteps !== undefined) {
            var hasAtLeastOneStep_1 = false;
            currentRequest.ApprovalWorkflow.AssociatedSteps.forEach(function (step) {
                if (step.IsCurrentUserApprover === true && step.IsComplete === false) {
                    hasAtLeastOneStep_1 = true;
                }
            });
            if (hasAtLeastOneStep_1 === false) {
                canSee = false;
            }
        }
        return canSee;
    };
    var canCurrentUserSeeAdminApprovalActions = function () {
        var canSee = true;
        if ((currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.RequireApprovalBeforeProvisioning) === false) {
            canSee = false;
        }
        if (currentRequest.Status !== "ApprovalRequired") {
            canSee = false;
        }
        if (currentRequest.ApprovalWorkflow !== undefined && currentRequest.ApprovalWorkflow !== null && currentRequest.ApprovalWorkflow.AssociatedSteps !== undefined) {
            var hasAtLeastOneStep_2 = false;
            currentRequest.ApprovalWorkflow.AssociatedSteps.forEach(function (step) {
                hasAtLeastOneStep_2 = true;
            });
            if (hasAtLeastOneStep_2 === false) {
                canSee = false;
            }
        }
        if (orchestryContext.IsCurrentUserOrchestryAdmin === false) {
            canSee = false;
        }
        return canSee;
    };
    var rejectClick = function (isAdmin) {
        if (isAdmin !== undefined && isAdmin === true) {
            if (approvalAdminFeedback === "") {
            }
            else {
                submitStatusUpdate("RejectOverride");
            }
        }
        else {
            if (approvalFeedback === "") {
            }
            else {
                submitStatusUpdate("Reject");
            }
        }
    };
    var setFeedback = function (isAdmin, feedback) {
        if (isAdmin === true) {
            setApprovalAdminFeedback(feedback);
            if (feedback !== "") {
                setRejectAdminDisabled(false);
            }
            else {
                setRejectAdminDisabled(true);
            }
        }
        else {
            setApprovalFeedback(feedback);
            if (feedback !== "") {
                setRejectDisabled(false);
            }
            else {
                setRejectDisabled(true);
            }
        }
    };
    React.useEffect(function () {
        // if(approvalFeedback !== "" && )
    }, [approvalAdminFeedback, approvalFeedback]);
    var renderApprovalBoxContent = function (showButtons, isAdmin) {
        isAdmin = isAdmin === undefined ? false : isAdmin;
        return (React.createElement(Portlet, { className: "approval-render-box current-status" },
            isAdmin ? React.createElement(PortletHeader, { title: intl.formatMessage({ id: "page.request_details.approval.admin_override", defaultMessage: "Administrative Override" }) }) : null,
            React.createElement(PortletBody, null, actionProcessing === false ? (React.createElement(React.Fragment, null,
                isAdmin !== undefined && isAdmin ? (React.createElement(React.Fragment, null,
                    React.createElement(PortletAlert, { icon: "info", type: "warning", message: intl.formatMessage({
                            id: "page.request_details.approval.admin_override_message",
                            defaultMessage: "Administrators can choose to override all approval steps in the workflow."
                        }), size: "small" }))) : null,
                React.createElement(React.Fragment, null,
                    React.createElement(FormattedMessage, { id: "page.request_details.approval.approval_mark_message", defaultMessage: "Your approval will mark the following steps as complete:" })),
                React.createElement("ul", null, currentRequest.ApprovalWorkflow !== undefined && currentRequest.ApprovalWorkflow.AssociatedSteps !== undefined ? (isAdmin === false ? (React.createElement(React.Fragment, null, currentRequest.ApprovalWorkflow.AssociatedSteps.map(function (step) { return (React.createElement(React.Fragment, null, step.IsComplete === false && step.IsCurrentUserApprover ? React.createElement("li", null, step.Name) : null)); }))) : (React.createElement(React.Fragment, null, currentRequest.ApprovalWorkflow.AssociatedSteps.map(function (step) { return (React.createElement(React.Fragment, null, step.IsComplete === false ? React.createElement("li", null, step.Name) : null)); })))) : null),
                React.createElement("div", { className: "actions" },
                    React.createElement("div", { className: "comment" },
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.comments_label", defaultMessage: "Comments" })),
                        React.createElement("textarea", { value: isAdmin !== undefined && isAdmin ? approvalAdminFeedback : approvalFeedback, className: "form-control", "data-pw": "comment-input", onChange: function (event) {
                                setFeedback(isAdmin !== undefined && isAdmin, event.target.value);
                            } }),
                        React.createElement("div", { className: "reject-required-comment" },
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.rejection_requires_comment", defaultMessage: "* Rejection requires a comment." }))),
                    showButtons !== undefined && showButtons === false ? null : (React.createElement(Row, { className: "row-actions" },
                        React.createElement(Col, { xs: 6 },
                            React.createElement("button", { type: "button", "data-pw": "approve", className: "btn btn-approve btn-md btn-block", onClick: function () {
                                    if (isAdmin) {
                                        submitStatusUpdate("ApproveOverride");
                                    }
                                    else {
                                        submitStatusUpdate("Approve");
                                    }
                                } },
                                React.createElement("i", { className: "fa fa-check" }),
                                React.createElement(FormattedMessage, { id: "page.request_details.approval.approve_button", defaultMessage: "Approve" }))),
                        React.createElement(Col, { xs: 6 },
                            React.createElement(DisableArea, { isDisabled: isAdmin !== undefined && isAdmin ? rejectAdminDisabled : rejectDisabled, useOverlay: true },
                                React.createElement("button", { type: "button", className: "btn btn-decline btn-md btn-block", "data-pw": "reject", onClick: function () { return rejectClick(isAdmin); } },
                                    React.createElement("i", { className: "fa fa-times" }),
                                    React.createElement(FormattedMessage, { id: "page.request_details.approval.reject_button", defaultMessage: "Reject" }))))))))) : (React.createElement(AnimatedLoader, { size: "xsmall", text: "loading" })))));
    };
    return (React.createElement(React.Fragment, null,
        canCurrentUserSeeApprovalActions() ? (React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, null, renderApprovalBoxContent()),
            React.createElement(Modal, { show: showApprovalBoxModal, className: "approval-modal", onHide: function () { return setShowApprovalBoxModal(!showApprovalBoxModal); }, animation: false },
                React.createElement("h3", null,
                    React.createElement(FormattedMessage, { id: "page.request_details.approval.request_approval_headline", defaultMessage: "Request Approval" })),
                renderApprovalBoxContent(false),
                React.createElement("div", { className: "confirmation-box" },
                    React.createElement("div", { className: "confirm" },
                        React.createElement(FormattedMessage, { id: "page.request_details.approval.please_confirm_selection", defaultMessage: "Please confirm your selection:" })),
                    React.createElement("div", { className: "action-wrapper" }, queryAction === "approve" ? (React.createElement("div", { className: "approve btn btn-approve btn-md", onClick: function () {
                            submitStatusUpdate("Approve");
                        } },
                        React.createElement(FormattedMessage, { id: "page.request_details.approval.approve_this_request", defaultMessage: "Approve this request" }))) : (React.createElement(DisableArea, { isDisabled: rejectDisabled, useOverlay: true },
                        React.createElement("div", { className: "reject btn btn-decline btn-md", onClick: function () {
                                submitStatusUpdate("Reject");
                            } },
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.reject_this_request", defaultMessage: "Reject this request" }))))),
                    React.createElement("div", { className: "cancel btn btn-secondary", onClick: function () { return setShowApprovalBoxModal(!showApprovalBoxModal); } },
                        React.createElement(FormattedMessage, { id: "page.request_details.approval.cancel_button", defaultMessage: "Cancel" })))))) : null,
        canCurrentUserSeeAdminApprovalActions() ? React.createElement(React.Fragment, null, renderApprovalBoxContent(true, true)) : null,
        "Failed" === currentRequest.Status && orchestryContext.IsCurrentUserOrchestryAdmin ? (React.createElement(Portlet, { className: "current-status" },
            React.createElement(PortletBody, null, actionProcessing === false ? (React.createElement("div", { className: "actions" },
                React.createElement(Row, { className: "row-actions" },
                    React.createElement(Col, { xs: 12 },
                        React.createElement("div", { style: { marginBottom: "15px" } },
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.provision_again", defaultMessage: "Attempt to provision this request again." })),
                        React.createElement("button", { type: "button", className: "btn btn-success btn-md btn-block", onClick: function () {
                                submitStatusUpdate("Retry");
                            } },
                            React.createElement("i", { className: "fas fa-sync" }),
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.retry_provisioning_button", defaultMessage: "Retry Provisioning" })))))) : (React.createElement(AnimatedLoader, { size: "xsmall", text: "loading" }))))) : null,
        "Rejected" === currentRequest.Status && orchestryContext.IsCurrentUserOrchestryAdmin ? (React.createElement(Portlet, { className: "current-status" },
            React.createElement(PortletBody, null, actionProcessing === false ? (React.createElement("div", { className: "actions" },
                React.createElement(Row, { className: "row-actions" },
                    React.createElement(Col, { xs: 12 },
                        React.createElement("div", { style: { marginBottom: "15px" } },
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.rerun_approval", defaultMessage: "Attempt to re-run approval on this request." })),
                        React.createElement("button", { type: "button", className: "btn btn-success btn-md btn-block", onClick: function () {
                                submitStatusUpdate("ApprovalRequired");
                            } },
                            React.createElement("i", { className: "fas fa-sync" }),
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.retry_approvals_button", defaultMessage: "Retry Approvals" })))))) : (React.createElement(AnimatedLoader, { size: "xsmall", text: "loading" }))))) : null,
        ("Failed" === currentRequest.Status || "Rejected" === currentRequest.Status || "Provisioned" === currentRequest.Status) && orchestryContext.IsCurrentUserOrchestryAdmin ? (React.createElement(Portlet, { className: "current-status" },
            React.createElement(PortletBody, null, actionProcessing === false ? (React.createElement("div", { className: "actions" },
                React.createElement(Row, { className: "row-actions" },
                    React.createElement(Col, { xs: 12 },
                        React.createElement("div", { style: { marginBottom: "15px" } },
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.archival_description", defaultMessage: "Archiving will remove this request from the request list." })),
                        React.createElement("button", { type: "button", "data-pw": "archive-request", className: "btn btn-info btn-md btn-block", onClick: function () {
                                submitStatusUpdate("Archive");
                            } },
                            React.createElement("i", { className: "fa fa-archive" }),
                            React.createElement(FormattedMessage, { id: "page.request_details.approval.archival_button", defaultMessage: "Archive Request" })))))) : (React.createElement(AnimatedLoader, { size: "xsmall", text: "loading" }))))) : null));
});
export default RequestDetailsApproval;
