import * as React from "react";
import "./lifecycle-approval.scss";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AnimatedLoader from "app/components/common/animated-loader";
import PortletAlert from "app/components/common/portlet-alert";
import DisableArea from "app/components/common/disable-area";
import * as LifecyclePolicyWorkspaceStore from "app/store/lifecycle-policy-workspaces/current";
import { SkeletonLoaderFakeBox } from "app/components/common/skeleton-loaders";
import * as UserCurrentStore from "app/store/users/current";
var LifecycleApproval = React.memo(function (props) {
    //console.log("Request Param Guid", props.match.params.guid);
    var dispatch = useDispatch();
    var currentUser = useSelector(function (state) { return state.userCurrentState.item; });
    var policyWorkspace = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.item; });
    var policy = useSelector(function (state) { return state.lifecyclePoliciesCurrentState.item; });
    var members = useSelector(function (state) { return state.lifecyclePolicyWorkspacesCurrentState.members; });
    var _a = React.useState(true), currentUserApprover = _a[0], setCurrentUserApprover = _a[1];
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var _b = React.useState(""), comment = _b[0], setComment = _b[1];
    // const [showSubmittingLoader, setShowSubmittingLoader] = React.useState(false);
    //const [wasApproved, setWasApproved] = React.useState<boolean | null>(null);
    var _c = React.useState(""), userSelection = _c[0], setUserSelection = _c[1];
    React.useEffect(function () {
        if (currentUser === undefined || (currentUser !== undefined && currentUser.Id === undefined)) {
            dispatch(UserCurrentStore.Load());
        }
    }, []);
    React.useEffect(function () {
        if (policyWorkspace !== null && policyWorkspace.ArchivalPolicyGuid !== null) {
            // if (policy !== undefined && policy.GUID !== undefined) {
            //    if (policy.GUID !== policyWorkspace.ArchivalPolicyGuid) {
            //       dispatch(LifecyclePolicyCurrentStore.Clear());
            //    }
            // }
            // if (policy === undefined || (policy !== undefined && policy.GUID === undefined)) {
            //    dispatch(LifecyclePolicyCurrentStore.Load("Archival", policyWorkspace.ArchivalPolicyGuid as string));
            // }
        }
    }, [policyWorkspace]);
    React.useEffect(function () {
        // if (policyWorkspace !== null && policyWorkspace.ArchivalPolicyGuid !== null && policy !== undefined && policy.GUID !== undefined && currentUser !== undefined) {
        //    if (policyWorkspace.ArchivalStatus === "PendingApproval" && currentUserApprover === null) {
        //       // if(currentUser.IsAdmin){
        //       // }
        //       // if (policy.Execution_Approvers_ApprovalType === "WorkspaceOwners" || policy.Execution_Approvers_ApprovalType === "WorkspaceMembers") {
        //       //    if (members === null) {
        //       //       dispatch(LifecyclePolicyWorkspaceStore.LoadMembers(policyWorkspace.SiteId as string));
        //       //    }
        //       //    else {
        //       //       if (policy.Execution_Approvers_ApprovalType === "WorkspaceOwners") {
        //       //          setCurrentUserApprover(members.Owners.some(m => m.UserPrincipalName === currentUser.UserPrincipalName));
        //       //       }
        //       //       if (policy.Execution_Approvers_ApprovalType === "WorkspaceMembers") {
        //       //          setCurrentUserApprover(members.Members.some(m => m.UserPrincipalName === currentUser.UserPrincipalName));
        //       //       }
        //       //    }
        //       // } else if (policy.Execution_Approvers_Approval_SpecificUsers !== null && policy.Execution_Approvers_Approval_SpecificUsers?.indexOf("[") > -1) {
        //       //    let userObjects: (User)[] = [];
        //       //    try {
        //       //       userObjects = JSON.parse(policy.Execution_Approvers_Approval_SpecificUsers);
        //       //    } catch { }
        //       //    setCurrentUserApprover(userObjects.some(m => m.UserPrincipalName === currentUser.UserPrincipalName));
        //       // } else {
        //       //    setCurrentUserApprover(false);
        //       // }
        //       setCurrentUserApprover(true);
        //    }
        // }
    }, [policy, members, currentUser]);
    var performAction = function (userSel) {
        setUserSelection(userSel);
        if (policyWorkspace !== undefined && policyWorkspace !== null) {
            dispatch(LifecyclePolicyWorkspaceStore.LoadNewStatus(true));
            dispatch(LifecyclePolicyWorkspaceStore.WorkflowActionSubmit({
                UserSelection: userSel,
                SiteId: policyWorkspace.SiteId,
                Feedback: comment
            }));
        }
    };
    React.useEffect(function () {
        if (policyWorkspace !== null && policyWorkspace.ArchivalStatus !== "PendingApproval") {
            if (userSelection !== "") {
                setUserSelection("");
            }
            if (comment !== "") {
                setComment("");
            }
        }
    }, [policyWorkspace]);
    return (React.createElement(React.Fragment, null, (policyWorkspace === null || policyWorkspace === void 0 ? void 0 : policyWorkspace.ArchivalStatus) === "PendingApproval" ? (currentUserApprover === null || currentUserApprover === undefined ? (React.createElement(SkeletonLoaderFakeBox, { marginTop: 20 })) : currentUserApprover === true ? (React.createElement("div", { className: "lifecycle-approval" },
        orchestryContext.IsCurrentUserOrchestryAdmin && React.createElement(PortletAlert, { type: "warning", size: "small", message: "Administrative Override", outline: false, icon: "check" }),
        React.createElement("div", { className: "comment" },
            React.createElement("span", null, "Comments"),
            React.createElement("textarea", { value: comment !== null ? comment : "", className: "form-control", onChange: function (event) {
                    setComment(event.target.value);
                } }),
            React.createElement("div", { className: "reject-required-comment" }, "* Extension requires a comment.")),
        React.createElement(Row, { className: "row-actions" },
            React.createElement(Col, { xs: policy.Option_Content_Archival_Allow_Delete_Alternative === true ? 4 : 6 },
                React.createElement(DisableArea, { isDisabled: comment === "" || userSelection !== "", useOverlay: true },
                    React.createElement("button", { type: "button", className: "btn btn-extend btn-md btn-block size-small", onClick: function () { return performAction("extend"); } },
                        userSelection === "extend" && React.createElement(AnimatedLoader, { size: "xxsmall" }),
                        userSelection === "" && React.createElement("i", { className: "far fa-clock" }),
                        "Renew"))),
            React.createElement(Col, { xs: policy.Option_Content_Archival_Allow_Delete_Alternative === true ? 4 : 6 },
                React.createElement(DisableArea, { isDisabled: userSelection !== "", useOverlay: true },
                    React.createElement("button", { type: "button", className: "btn btn-archive btn-md btn-block size-small", onClick: function () { return performAction("archive"); } },
                        userSelection === "archive" && React.createElement(AnimatedLoader, { size: "xxsmall" }),
                        userSelection === "" ? React.createElement("i", { className: "fa fa-archive" }) : null,
                        "Archive"))),
            policy.Option_Content_Archival_Allow_Delete_Alternative === true ? (React.createElement(Col, { xs: 4 },
                React.createElement(DisableArea, { isDisabled: userSelection !== "", useOverlay: true },
                    React.createElement("button", { type: "button", className: "btn btn-delete btn-md btn-block size-small", onClick: function () { return performAction("delete"); } },
                        userSelection === "delete" && React.createElement(AnimatedLoader, { size: "xxsmall" }),
                        userSelection === "" && React.createElement("i", { className: "far fa-trash" }),
                        "Delete")))) : null))) : null) : null));
});
export default LifecycleApproval;
