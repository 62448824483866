import { Text } from "@mantine/core";
import { getReadableDate } from "app/utils/dateUtils";
import { OrchestryBadge } from "appv2/components/orchestry-badge/orchestry-badge.component";
import React from "react";
import { RECOMMENDATION_HISTORY_EVENT_TYPE_TO_TEXT_MAPPING, getBadgeColor } from "./recommendation-history-metadata.utils";
import "./recommendation-history-metadata.scss";
export var RecommendationHistoryMetadata = function (_a) {
    var recommendationHistory = _a.recommendationHistory, index = _a.index;
    return (React.createElement("div", { className: "recommendation-history-metadata" },
        React.createElement(OrchestryBadge, { size: "md", title: getReadableDate(recommendationHistory.CreatedDate), color: getBadgeColor(index) }),
        React.createElement(Text, { className: "recommendation-history-title", c: "text-secondary.0", fw: "500", size: "14px" },
            RECOMMENDATION_HISTORY_EVENT_TYPE_TO_TEXT_MAPPING[recommendationHistory.EventType],
            React.createElement(Text, { c: "text-secondary.0", span: true, fw: "400", inherit: true }, " by "),
            React.createElement(Text, { c: "text-secondary.0", span: true, fw: "700", inherit: true }, recommendationHistory.ModifiedByName),
            recommendationHistory.Description && (React.createElement(Text, { c: "text-secondary.0", span: true, fw: "400", inherit: true }, " (".concat(recommendationHistory.Description, ")"))))));
};
