import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePendo } from "app/utils/hooks/usePendo";
import * as OrchestryContextStore from "app/store/orchestry-context/current";
import { LayoutSplashScreen } from "_metronic";
import { actions } from "_metronic/ducks/i18n";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import "moment/locale/nb";
import "moment/locale/de";
import "moment/locale/sv";
import "moment/locale/pt";
import "moment/locale/es";
import getEnvironmentName from "app/utils/environmentName";
var OrchestryContext = React.memo(function (props) {
    var _a = React.useState(false), doneCheck = _a[0], setDoneCheck = _a[1];
    var dispatch = useDispatch();
    var orchestryContext = useSelector(function (state) { return state.orchestryContextCurrentState.item; });
    var showLoader = useSelector(function (state) { return state.orchestryContextCurrentState.showLoaderOverride; });
    var i18n = useSelector(function (state) { return state.i18n; });
    var _b = React.useState(false), errorOccured = _b[0], setErrorOccured = _b[1];
    usePendo();
    React.useEffect(function () {
        dispatch(OrchestryContextStore.Load());
        var environmentName = getEnvironmentName();
        if (environmentName !== null) {
            document.title = "Orch - " + environmentName;
        }
    }, []);
    React.useEffect(function () {
        if (orchestryContext !== undefined && orchestryContext.DoesTenantHaveSubscription !== undefined) {
            setDoneCheck(true);
            // AppcuesService.IdentifyUser();
        }
        if (orchestryContext !== undefined && orchestryContext.Culture !== undefined && orchestryContext.Culture !== null) {
            //i18n.setLanguage(orchestryContext.Culture);
            dispatch(actions.setLanguage(orchestryContext.Culture));
            // For Orchestry's NO langauge, we have to use NB.
            // So this logic checks for that specific problem.
            var momentLang = orchestryContext.Culture;
            if (momentLang === "no") {
                momentLang = "nb";
            }
            moment.locale(momentLang);
        }
        else {
            moment.locale("en");
        }
    }, [orchestryContext]);
    var getLifecycleDateOverrideNotification = function () {
        if (orchestryContext !== undefined && orchestryContext.ExtendedProperties !== undefined && Object.keys(orchestryContext.ExtendedProperties).length > 0) {
            return (React.createElement("div", { style: {
                    position: "fixed",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 9999,
                    // left: "calc(50% - 200px)",
                    width: "100%",
                    display: "flex"
                } },
                orchestryContext.ExtendedProperties["LifecycleDayOverride"] !== undefined ? (React.createElement("div", { style: {
                        display: "flex",
                        borderRadius: "0 0 5px 5px",
                        backgroundColor: "#ffffb3",
                        color: "#838029",
                        fontSize: "12px",
                        // width: "250px",
                        boxShadow: "rgb(131 128 41 / 29%) 0px 1px 2px",
                        textAlign: "center",
                        padding: "5px 15px",
                        marginRight: "20px"
                    } },
                    React.createElement("div", { style: { textAlign: "left", paddingRight: "15px" } }, "Date Override"),
                    React.createElement("div", null,
                        React.createElement("strong", null, moment(orchestryContext.ExtendedProperties["LifecycleDayOverride"]).format("LLL"))))) : null,
                orchestryContext.ExtendedProperties["Branch"] !== undefined ? (React.createElement("div", { style: {
                        display: "flex",
                        borderRadius: "0 0 5px 5px",
                        backgroundColor: "#cff4fc",
                        color: "#055160",
                        fontSize: "12px",
                        boxShadow: "rgb(41 68 131 / 29%) 0px 1px 2px",
                        textAlign: "center",
                        padding: "5px 15px"
                    } },
                    React.createElement("div", { style: { textAlign: "left", paddingRight: "15px" } }, "Branch"),
                    React.createElement("div", null,
                        React.createElement("strong", null, orchestryContext.ExtendedProperties["Branch"])))) : null));
        }
        return null;
    };
    if (doneCheck && showLoader === false && i18n.loadingMessages === false) {
        return (React.createElement(React.Fragment, null,
            getLifecycleDateOverrideNotification(),
            props.children));
    }
    else if (errorOccured) {
        return React.createElement("div", null, "Error Occured");
    }
    else {
        return React.createElement(LayoutSplashScreen, { visible: true });
    }
});
export default OrchestryContext;
