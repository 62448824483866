import { Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { toSimplePercentageLocaleString } from "appv2/utils/string.utils";
import React from "react";
import { useIntl } from "react-intl";
import { OrchestryDeltaText } from "../orchestry-delta-text/orchestry-delta-text.component";
import { OrchestryFontIcon } from "../orchestry-font-icon/orchestry-font-icon.component";
import { OrchestryText } from "../orchestry-text/orchestry-text.component";
import { OrchestryTooltip } from "../orchestry-tooltip/orchestry-tooltip.component";
export var OrchestryDeltaPeriod = function (_a) {
    var value = _a.value, deltaValue = _a.deltaValue, deltaPercentage = _a.deltaPercentage, deltaSuffix = _a.deltaSuffix, lastPeriodValue = _a.lastPeriodValue, align = _a.align, isLoading = _a.isLoading, lastPeriodValueSuffix = _a.lastPeriodValueSuffix, daysAgoCount = _a.daysAgoCount, dataCoverageAvailable = _a.dataCoverageAvailable;
    var intl = useIntl();
    var isLastPeriodValueAPercentage = deltaValue === null || deltaValue === undefined;
    var valueLastPeriod = lastPeriodValue !== undefined && lastPeriodValue !== null
        ? isLastPeriodValueAPercentage
            ? "".concat(toSimplePercentageLocaleString(lastPeriodValue), "%")
            : lastPeriodValue.toLocaleString() + (lastPeriodValueSuffix !== null && lastPeriodValueSuffix !== void 0 ? lastPeriodValueSuffix : "")
        : null;
    return (React.createElement(Stack, { gap: 2, align: align !== null && align !== void 0 ? align : "Flex-end" },
        React.createElement(Group, { align: "left", gap: value !== undefined && value !== null ? 8 : 0 },
            React.createElement(OrchestryText, { fw: 700, fz: "12px", lh: "24px", c: "var(--mantine-color-text-primary-0)" }, value),
            React.createElement(OrchestryDeltaText, { isLoading: isLoading, deltaValue: deltaValue, deltaSuffix: deltaSuffix, deltaPercentage: deltaPercentage })),
        React.createElement(Group, { gap: 4, align: "center" },
            lastPeriodValue !== undefined && lastPeriodValue !== null && (React.createElement(OrchestryText, { isLoading: isLoading, fw: 400, c: "var(--mantine-color-text-tertiary-0)", size: "12px", lh: "24px" }, daysAgoCount !== undefined
                ? intl.formatMessage({ id: "value_days_ago", defaultMessage: "{value} ({daysAgoCount} days ago)" }, { value: valueLastPeriod, daysAgoCount: daysAgoCount })
                : intl.formatMessage({ id: "value_previous_period", defaultMessage: "{value} (previous period)" }, { value: valueLastPeriod }))),
            dataCoverageAvailable === false && (React.createElement(OrchestryTooltip, { multiline: true, w: 300, label: intl.formatMessage({
                    id: "tooltip_historical_data_unavailable",
                    defaultMessage: "Historical trend data is not available for the entire targeted period. Available data is being displayed."
                }), withinPortal: false },
                React.createElement(OrchestryFontIcon, { iconClassName: ORCH_ICONS.alertTriangle, fontSize: 14, lineHeight: "14px", color: "var(--mantine-color-error-6)", marginTop: 2 }))))));
};
