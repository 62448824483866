import { Button, Table, Text } from "@mantine/core";
import { RecommendationSource } from "appv2/api/recommendations/recommendation-source.type";
import { useUpdateRecommendationVoteMutation } from "appv2/api/recommendations/recommendations.api";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { orchestryToast } from "appv2/components/orchestry-notifications/orchestry-toast-notification.component";
import { OrchestryThumbs } from "appv2/components/orchestry-thumbs/orchestry-thumbs.component";
import { OrchestryTooltip } from "appv2/components/orchestry-tooltip/orchestry-tooltip.component";
import { OrchestryUnlockFullAccessOverlay } from "appv2/components/orchestry-unlock-full-access-overlay/orchestry-unlock-full-access-overlay.component";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import React from "react";
import "./recommendation-card-view-details-modal.scss";
export var RecommendationViewDetailsModalContent = function (_a) {
    var context = _a.context, id = _a.id, _b = _a.innerProps.recommendation, Recommendation = _b.Recommendation, Vote = _b.Vote, RecommendationRecord = _b.RecommendationRecord;
    var currentUserIsBeaconUser = useCurrentUserRoles().currentUserIsBeaconUser;
    var isCustom = RecommendationRecord.Source === RecommendationSource.Custom;
    var shouldRenderWhyShouldICare = !isCustom;
    var shouldRenderWasThisUseful = !currentUserIsBeaconUser && !isCustom;
    var showOverlay = currentUserIsBeaconUser && !isCustom;
    var resources = React.useMemo(function () { var _a; return (((_a = Recommendation === null || Recommendation === void 0 ? void 0 : Recommendation.Resources) === null || _a === void 0 ? void 0 : _a.startsWith("[{")) ? JSON.parse(Recommendation.Resources) : null); }, []);
    var updateVote = useUpdateRecommendationVoteMutation()[0];
    var thumbOnClick = function (value) {
        updateVote({ RecommendationGUID: Recommendation.Guid, Vote: value }).then(function () {
            orchestryToast(React.createElement(React.Fragment, null,
                React.createElement(Text, { span: true, c: "text-primary.0", size: "sm" }, "Thank you for your feedback!")), {
                autoClose: 2500,
                progressBarVariant: "heartpink",
                toastId: "recommendation-card-reopen-toast-".concat(Recommendation.Guid),
                icon: React.createElement(OrchestryFeaturedIcon, { size: "lg", variant: "double-circle", colorVariant: "pink-8", iconClassName: ORCH_ICONS.thumbsUp })
            });
        });
    };
    // Set the ul/li on custom recommendations so that the css styles are applied for the checkmark icon
    // Also
    var recommendationAction = isCustom
        ? "<ul><li>".concat(RecommendationRecord === null || RecommendationRecord === void 0 ? void 0 : RecommendationRecord.RecommendationAction, "</li></ul>")
        : currentUserIsBeaconUser
            ? '<div style="padding: 5px 5px 0 5px"><ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.</li><li>Donec ipsum tellus, dapibus at ultrices in, interdum sit amet nisl. Quisque fermentum bibendum nulla, non dictum ipsum tincidunt non. Quisquam est qui dolorem ipsum quia dolor sit amet.</li></ul></div>'
            : Recommendation === null || Recommendation === void 0 ? void 0 : Recommendation.RecommendationAction;
    var whyShouldICare = currentUserIsBeaconUser && !isCustom
        ? "<div style=\"padding: 0px 5px\"><ul><li>Curabitur euismod sem vel velit blandit, sit amet molestie sapien pulvinar. Aenean molestie faucibus fringilla. Nam at justo eget nulla dictum fermentum.</li><li><div>Proin eget tortor risus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut <strong>libero</strong> malesuada feugiat. Quisque velit nisi, pretium ut lacinia in, elementum id enim.</div></li></ul></div>"
        : Recommendation === null || Recommendation === void 0 ? void 0 : Recommendation.WhyShouldICare;
    return (React.createElement("div", { className: "recommendation-card-view-details-modal" },
        ((Recommendation === null || Recommendation === void 0 ? void 0 : Recommendation.RecommendationAction) || (RecommendationRecord === null || RecommendationRecord === void 0 ? void 0 : RecommendationRecord.RecommendationAction)) && (React.createElement("div", { className: "recommended-action" },
            React.createElement(Text, { c: "text-primary.0", size: "md", fw: 500, className: "title" }, "Recommended Action"),
            React.createElement("div", { className: "recommended-action-contents" },
                showOverlay && React.createElement(OrchestryUnlockFullAccessOverlay, { showIcon: true }),
                React.createElement("div", { className: "action-list", dangerouslySetInnerHTML: { __html: recommendationAction } }),
                shouldRenderWhyShouldICare && (React.createElement(Text, { p: currentUserIsBeaconUser ? 5 : undefined, c: "text-primary.0", size: "md", fw: 500, className: "title" }, "Why Should I Care?")),
                React.createElement("div", { className: "why", dangerouslySetInnerHTML: { __html: whyShouldICare } })))),
        resources && resources.length > 0 && (React.createElement("div", { className: "resources" },
            React.createElement("div", { className: "resources-header" },
                React.createElement(Text, { c: "text-primary.0", size: "md", fw: 500, className: "title" }, "Resources"),
                React.createElement(Text, { c: "text-tertiary.0", size: "xs" }, "Helpful content to guide and action this recommendation.")),
            React.createElement("div", { className: "resources-table" },
                React.createElement(Table, { variant: "gray" },
                    React.createElement(Table.Thead, null,
                        React.createElement(Table.Tr, null,
                            React.createElement(Table.Th, null, "Source"),
                            React.createElement(Table.Th, null, "Link"))),
                    React.createElement(Table.Tbody, null, resources.map(function (resource, index) { return (React.createElement(Table.Tr, { key: index },
                        React.createElement(Table.Td, null, resource.ResourceSource),
                        React.createElement(Table.Td, null,
                            React.createElement("a", { target: "_blank", href: resource.ResourceLink }, resource.ResourceLabel)))); })))))),
        shouldRenderWasThisUseful && (React.createElement("div", { className: "useful" },
            React.createElement(Text, { size: "xs", c: "text-primary.0" }, "Was this recommendation useful?"),
            React.createElement(OrchestryTooltip, { position: "bottom", label: "Was this recommendation useful?", withinPortal: false },
                React.createElement(OrchestryThumbs, { onClickThumbs: thumbOnClick, voteValue: Vote })))),
        React.createElement("div", { className: "bottom-buttons" },
            React.createElement(Button, { size: "md", variant: "secondary-gray", onClick: function () { return context.closeModal(id); } }, "Close"))));
};
