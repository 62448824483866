import { Card, Group, Stack } from "@mantine/core";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";
import { OrchestryFeaturedIcon } from "appv2/components/orchestry-featured-icon/orchestry-featured-icon.component";
import { OrchestryText } from "appv2/components/orchestry-text/orchestry-text.component";
import React from "react";
import { useHealthCheckActivity } from "../../../use-health-check-activity.hook";
import { ToDatePill } from "../to-date-pill.component";
export var ProvisioningTile = function () {
    var _a = useHealthCheckActivity(), intl = _a.intl, isLoading = _a.isLoading, _b = _a.data.MetricsData.OrchestryUsage.Provisioning, Total = _b.Total, PreviousValue = _b.PreviousValue, DeltaValue = _b.DeltaValue, DeltaPercentage = _b.DeltaPercentage, DataCoverageDaysCount = _b.DataCoverageDaysCount, DataCoverageAvailable = _b.DataCoverageAvailable, TotalToDate = _b.TotalToDate;
    return (React.createElement(Card, { variant: "shadow-border", mih: "100%", p: 24, style: { overflow: "visible" } },
        React.createElement(Stack, { p: 0, align: "Stretch", gap: 0, m: 0 },
            React.createElement(Group, { gap: "8px" },
                React.createElement(OrchestryFeaturedIcon, { isLoading: isLoading, size: "xs", variant: "light-circle", colorVariant: "blue-5", iconClassName: ORCH_ICONS.send01 }),
                React.createElement(OrchestryText, { isLoading: isLoading, c: "text-primary.0", fz: "14", fw: 500, lh: "20px" }, intl.formatMessage({ id: "page.health_check.provisioning_tile.title", defaultMessage: "Provisioning" }))),
            React.createElement(OrchestryText, { skeletonWidth: 30, isLoading: isLoading, c: "text-primary.0", fz: 24, fw: 500, lh: "32px", mt: 8 }, Total.toLocaleString()),
            React.createElement(OrchestryText, { skeletonWidth: 60, isLoading: isLoading, c: "text-secondary.0", fz: 14, fw: 300, lh: "20px", mt: -4, mb: 68 }, intl.formatMessage({ id: "page.health_check.provisioning_tile.workspaces", defaultMessage: "Workspaces" })),
            React.createElement(ToDatePill, { isLoading: isLoading, totalToDate: TotalToDate !== null && TotalToDate !== void 0 ? TotalToDate : 0, badgeColor: "var(--mantine-color-blue-7)", mt: 8 }))));
};
