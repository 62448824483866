import { OrchRole } from "app/store/orchestry-context/orch-role-type";
import ORCH_ICONS from "appv2/assets/orchestry-icon-font/icons";

export default {
   header: {
      self: {},
      items: [
         {
            title: "Quick Tools",
            root: true,
            alignment: "left",
            // page: "dashboard",
            translate: "MENU.DASHBOARD",
            submenu: [
               {
                  title: "Job Browser",
                  bullet: "dot",
                  page: "orchestry-admin/tool/job-browser",
                  access: ["orchestry-internal-admin"]
               },
               {
                  title: "Tenant Failure Report",
                  bullet: "dot",
                  page: "orchestry-admin/failure-stats",
                  access: ["orchestry-internal-admin"]
               },
               {
                  title: "Tenant Status",
                  bullet: "dot",
                  page: "orchestry-admin/stats/etl-crawl",
                  access: ["orchestry-internal-admin"]
               }
            ]
         }
      ]
   },
   aside: {
      self: {},
      items: [
         {
            title: "Home",
            translate: "layout.menu.home",
            root: true,
            icon: "flaticon-home-2",
            page: "",
            access: ["orchestry-user"]
         },
         {
            title: "Recommendations",
            translate: "layout.menu.recommendations",
            root: true,
            icon: ORCH_ICONS.checkVerified02,
            page: "recommendations",
            access: [OrchRole.Admin, OrchRole.BeaconAdmin]
         },
         {
            title: "Health Checks",
            translate: "layout.menu.health_check",
            root: true,
            icon: ORCH_ICONS.calendarHeart01,
            page: "health-checks",
            access: [OrchRole.Admin, OrchRole.BeaconAdmin, OrchRole.HealthCheckViewer, OrchRole.HealthCheckReviewer]
         },
         {
            title: "Requests",
            translate: "layout.menu.requests",
            root: true,
            bullet: "dot",
            icon: "flaticon2-telegram-logo",
            page: "requests",
            access: ["orchestry-user"],
            separator: true
         },
         {
            title: "Workspaces",
            translate: "layout.menu.workspaces",
            root: true,
            bullet: "dot",
            icon: "flaticon2-layers",
            page: "workspaces",
            submenu: [
               {
                  title: "Templates",
                  translate: "layout.menu.workspaces.templates",
                  page: "workspaces/templates"
               },
               {
                  title: "Features",
                  translate: "layout.menu.workspaces.features",
                  page: "workspaces/features"
               },
               {
                  title: "Channels",
                  translate: "layout.menu.workspaces.channels",
                  page: "workspaces/channels"
               },
               {
                  title: "Document Templates",
                  translate: "layout.menu.workspaces.document_templates",
                  page: "workspaces/document-templates"
               },
               // {
               //   title: "Security",
               //   page: "workspaces/security"
               // },
               {
                  title: "Approval Workflows",
                  translate: "layout.menu.workspaces.approval_workflows",
                  page: "workspaces/approval-workflows"
               },
               {
                  title: "Naming Policies",
                  translate: "layout.menu.workspaces.naming_policies",
                  page: "workspaces/naming-policies"
               },
               {
                  title: "Metadata",
                  translate: "layout.menu.workspaces.metadata",
                  page: "workspaces/metadata"
               }
            ],
            access: ["orchestry-customer-admin"]
         },
         {
            title: "Directory",
            translate: "layout.menu.directory",
            root: true,
            bullet: "dot",
            icon: "fa fa-sitemap",
            submenu: [
               {
                  title: "View Directory",
                  translate: "layout.menu.directory.view",
                  page: "directory/view"
               },
               {
                  title: "Configuration",
                  translate: "layout.menu.directory.configuration",
                  page: "directory/configuration"
               }
            ],
            access: ["orchestry-customer-admin"]
         },
         {
            title: "Lifecycle Management",
            translate: "layout.menu.lifecycle",
            isLifecycle: true,
            root: true,
            bullet: "dot",
            //icon: "fa fa-stethoscope", //
            icon: "fa fa-sync-alt", //sync-alt
            submenu: [
               {
                  title: "Dashboard",
                  translate: "layout.menu.lifecycle.dashboard",
                  page: "lifecycle/dashboard"
               },
               {
                  title: "Workspace Insights",
                  translate: "layout.menu.lifecycle.workspace_insights",
                  page: "lifecycle/listing"
               },
               // {
               //    title: "Workspace Renewals",
               //    page: "lifecycle/renewals"
               // },
               {
                  title: "Renewal / Archival Policies",
                  translate: "layout.menu.lifecycle.archival_policies",
                  page: "lifecycle/policies/archival"
               },
               {
                  title: "Lifecycle Settings",
                  translate: "layout.menu.lifecycle.settings",
                  page: "lifecycle/settings"
               }
            ],
            access: ["orchestry-customer-admin"]
         },
         {
            title: "Guest Management",
            isGuestManagement: true,
            translate: "layout.menu.guest",
            root: true,
            bullet: "dot",
            icon: "fas fa-user-lock",
            submenu: [
               { title: "Dashboard", translate: "layout.menu.guest.dashboard", page: "guests/dashboard" },
               { title: "Insights", translate: "layout.menu.guest_insights", page: "guests/listing" },
               { title: "Policies", translate: "layout.menu.guest_policies", page: "guests/policies" },
               { title: "Guest Settings", translate: "layout.menu.guest_settings", page: "guests/settings" }
            ],
            access: ["orchestry-customer-admin"]
         },
         // {
         //   title: "Insights",
         //   root: true,
         //   bullet: "dot",
         //   icon: "flaticon2-chart2",
         //   submenu: [
         //     {
         //       title: "Orchestry Usage",
         //       page: "admin/insights/orchestry-usage"
         //     },
         //     {
         //       title: "Microsoft Teams Reporting",
         //       page: "admin/insights/microsoft-teams-reporting"
         //     },
         //     {
         //       title: "Trends",
         //       page: "admin/insights/trends"
         //     }
         //   ],
         //   access: ["orchestry-customer-admin","orchestry-internal-admin"]
         // },
         {
            title: "Tools",
            translate: "layout.menu.tools",
            root: true,
            bullet: "dot",
            icon: "fas fa-wrench",
            access: ["orchestry-customer-admin"],
            separator: true,
            submenu: [
               {
                  title: "Template Files",
                  translate: "layout.menu.tools.template_files",
                  page: "tools/feature-files"
               }
               // {
               //   title: "Account",
               //   page: "admin/settings/account"
               // },
               // {
               //   title: "Preferences",
               //   page: "admin/settings/preferences"
               // }
            ]
         },
         {
            title: "Partner Tools",
            translate: "layout.menu.partner_tools",
            root: true,
            bullet: "dot",
            icon: "fas fa-tools",
            access: [OrchRole.PartnerHubAdmin, OrchRole.PartnerTenantManager, OrchRole.PartnerAdmin],
            page: "partner-tools",
            separator: true
         },
         {
            title: "Settings",
            translate: "layout.menu.settings",
            root: true,
            bullet: "dot",
            icon: "fa fa-cog",
            hideIfNoChildren: true,
            separatorIfBeacon: true,
            submenu: [
               {
                  title: "Installation",
                  translate: "layout.menu.settings.installation",
                  page: "settings/installation",
                  access: ["orchestry-customer-admin", OrchRole.BeaconAdmin]
               },
               {
                  title: "Teams Application",
                  translate: "layout.menu.settings.teams_applications",
                  page: "settings/teams-app",
                  access: ["orchestry-customer-admin"]
               },
               {
                  title: "Accounts",
                  translate: "layout.menu.settings.accounts",
                  page: "settings/account",
                  access: ["orchestry-customer-admin", OrchRole.BeaconAdmin]
               },
               {
                  title: "Partners",
                  translate: "layout.menu.settings.partners",
                  page: "settings/partners",
                  access: [OrchRole.Admin, OrchRole.BeaconAdmin]
               },
               {
                  title: "Health Check",
                  translate: "layout.menu.settings.health_check",
                  page: "settings/health-check",
                  access: [OrchRole.Admin, OrchRole.BeaconAdmin]
               },
               {
                  title: "Recommendations",
                  translate: "layout.menu.settings.recommendations",
                  page: "settings/recommendations",
                  access: [OrchRole.Admin, OrchRole.BeaconAdmin]
               },
               {
                  title: "Notifications",
                  translate: "layout.menu.settings.notifications",
                  page: "settings/notifications",
                  access: ["orchestry-customer-admin"]
               },
               {
                  title: "Language",
                  translate: "layout.menu.settings.languages",
                  page: "settings/language",
                  access: ["orchestry-customer-admin", OrchRole.BeaconAdmin]
               },
               {
                  title: "Blocked Words",
                  translate: "layout.menu.settings.blocked_words",
                  page: "settings/blocked-words",
                  access: ["orchestry-customer-admin"]
               },
               {
                  title: "Categories",
                  translate: "layout.menu.settings.categories",
                  page: "settings/categories",
                  access: ["orchestry-customer-admin"]
               },
               {
                  title: "Localization Editor",
                  // bullet: "icon",
                  // icon: "fa fa-user-lock",
                  page: "orchestry-admin/tool/localization-editor",
                  access: ["localization-editor"]
               },
               {
                  title: "Classifications and Labels",
                  translate: "layout.menu.settings.classifications",
                  page: "settings/classifications-and-labels",
                  access: ["orchestry-customer-admin"]
               },
               {
                  title: "API",
                  page: "Client API",
                  access: ["orchestry-customer-admin"],
                  submenu: [
                     {
                        title: "Documentation",
                        page: "client-api/documentation"
                     },
                     {
                        title: "Definition and Testing",
                        page: "client-api/definition"
                     },
                     {
                        title: "Connections",
                        page: "client-api/connections"
                     }
                  ]
               }
            ],
            access: ["orchestry-user", "orchestry-customer-admin", "orchestry-customer-admin", "localization-editor", OrchRole.PartnerAdmin, OrchRole.BeaconAdmin]
         },
         {
            section: "Orchestry Settings",
            access: ["orchestry-internal-admin"]
         },
         {
            title: "Library",
            root: true,
            icon: "fa fa-book",
            submenu: [
               {
                  title: "Template Library",
                  page: "orchestry-admin/library/templates"
               },
               {
                  title: "Channel Library",
                  page: "orchestry-admin/library/channels"
               },
               {
                  title: "Feature Library",
                  page: "orchestry-admin/library/features"
               },
               {
                  title: "Document Template Library",
                  page: "orchestry-admin/library/document-templates"
               },
               {
                  title: "Library Settings",
                  page: "orchestry-admin/library/settings"
               }
            ],
            access: ["orchestry-internal-admin"]
         },
         {
            title: "Orchestry Tenants",
            root: true,
            icon: "fa fa-building",
            page: "orchestry-admin/tenants",
            access: ["orchestry-internal-admin"]
         },
         {
            title: "Install Codes",
            root: true,
            icon: "fa fa-qrcode",
            page: "orchestry-admin/install-codes",
            access: ["orchestry-internal-admin"]
         },
         // {
         //   title: "Hangfire",
         //   root: true,
         //   icon: "fa fa-fire-alt",
         //   page: "hangfire",
         //   access: ["orchestry-internal-admin"]
         // },

         {
            title: "Stats",
            root: true,
            bullet: "dot",
            icon: "fa fa-chart-bar",
            access: ["orchestry-internal-admin"],
            submenu: [
               {
                  title: "Request Stats by Tenant",
                  page: "orchestry-admin/stats/requests"
               },
               {
                  title: "Crawler - Tenants",
                  page: "orchestry-admin/stats/etl-crawl"
               },
               {
                  title: "Crawler - Timeline",
                  bullet: "dot",
                  page: "orchestry-admin/stats/etl-timeline"
               },
               {
                  title: "Tenant Failures",
                  bullet: "dot",
                  page: "orchestry-admin/failure-stats"
               },
               {
                  title: "Throttling",
                  page: "Throttling",
                  submenu: [
                     {
                        title: "Sql - 5 Min",
                        page: "orchestry-admin/stats/sql-throttling"
                     },
                     {
                        title: "Sql - 24 Hours",
                        page: "orchestry-admin/stats/sql-throttling-24hours"
                     },
                     {
                        title: "Graph/SP - 5 Min",
                        page: "orchestry-admin/stats/throttling"
                     },
                     {
                        title: "Graph/SP - 24 Hours",
                        page: "orchestry-admin/stats/throttling-24hours"
                     },
                     {
                        title: "Graph/SP 429 Returned Stats",
                        page: "orchestry-admin/stats/throttling-errors"
                     },
                     {
                        title: "Cosmos - 5 Min",
                        bullet: "dot",
                        page: "orchestry-admin/stats/cosmos-throttling"
                     },
                     {
                        title: "Cosmos - 24 Hours",
                        bullet: "dot",
                        page: "orchestry-admin/stats/cosmos-throttling-24hours"
                     },
                     {
                        title: "Azure Table Storage - 5 Min",
                        bullet: "dot",
                        page: "orchestry-admin/stats/azure-table-throttling"
                     },
                     {
                        title: "Azure Table Storage - 24 Hours",
                        bullet: "dot",
                        page: "orchestry-admin/stats/azure-table-throttling-24hours"
                     }
                  ]
               }
            ]
         },
         {
            title: "Tools",
            root: true,
            bullet: "dot",
            icon: "fa fa-chalkboard-teacher",
            access: ["orchestry-internal-admin"],
            submenu: [
               {
                  title: "Database Browser",
                  page: "orchestry-admin/tool/database-browser"
               },
               {
                  title: "Database Information",
                  page: "orchestry-admin/tool/database-information"
               },
               {
                  title: "Request Duplication Tool",
                  page: "orchestry-admin/tool/stress-test"
               },
               {
                  title: "Lifecycle Tools",
                  page: "orchestry-admin/tool/lifecycle-testing"
               },
               {
                  title: "Job Browser",
                  page: "orchestry-admin/tool/job-browser"
               },
               {
                  title: "Tenant Event Browser",
                  page: "orchestry-admin/tool/tenant-event-browser"
               },
               {
                  title: "Localization Editor",
                  page: "orchestry-admin/tool/localization-editor"
               },
               {
                  title: "Permission Info Editor",
                  page: "orchestry-admin/tool/permission-info-editor"
               },
               {
                  title: "Bulk Team Provisioning",
                  page: "orchestry-admin/tool/bulk-team-provisioning"
               },
               {
                  title: "Bulk Guest Provisioning",
                  page: "orchestry-admin/tool/bulk-guest-provisioning"
               },
               {
                  title: "Recommendations Browser",
                  page: "orchestry-admin/tool/tenant-recommendations-browser/"
               },
               {
                  title: "Recommendations Analytics",
                  page: "orchestry-admin/orchestry-admin-recommendations-analytics"
               },
               {
                  title: "Recommendations Suggestions",
                  page: "orchestry-admin/orchestry-admin-recommendations-suggestions"
               }

               // {
               //   title: "Account",
               //   page: "admin/settings/account"
               // },
               // {
               //   title: "Preferences",
               //   page: "admin/settings/preferences"
               // }
            ]
         }
      ]
   }
};
