import * as microsoftTeams from "@microsoft/teams-js";
import stripHtmlTags from "app/utils/stripHtmlTags";
var TeamClientAppNavigation = /** @class */ (function () {
    function TeamClientAppNavigation() {
    }
    TeamClientAppNavigation.hasInitialized = false;
    TeamClientAppNavigation.goToTeamsLink = function (url, event, hostClientType) {
        if (hostClientType === microsoftTeams.HostClientType.web) {
            // Microsoft has a bug if we try to deeplink in web starting with the new Teams V2.
            // So we return here and a new tab will be opened instead.
            return;
        }
        var potentialTeamsUrl = stripHtmlTags(url);
        var isInTeams = document.getElementsByTagName("html")[0].classList.contains("in-teams");
        if (isInTeams) {
            if (TeamClientAppNavigation.hasInitialized === false) {
                TeamClientAppNavigation.hasInitialized = true;
                microsoftTeams.initialize();
            }
            if (potentialTeamsUrl.indexOf("https://teams.microsoft.com") > -1) {
                if (event !== undefined) {
                    event.preventDefault();
                }
                microsoftTeams.executeDeepLink(potentialTeamsUrl);
            }
        }
        //  else {
        //    if (window != null) {
        //       (window.open(potentialTeamsUrl, "_blank") as Window).focus();
        //    }
        // }
    };
    return TeamClientAppNavigation;
}());
export default TeamClientAppNavigation;
