import { OrchestryDataTableDateColumn } from "appv2/components/orchestry-data-table/orchestry-data-table-date-column.component";
import { useCustomMantineReactTable } from "appv2/components/orchestry-data-table/use-custom-mantine-react-table.hook";
import { useMapLocaleToMRTLocalization } from "appv2/components/orchestry-data-table/use-map-local-to-MRT-localization";
import { openOrchestryModal } from "appv2/components/orchestry-modal/orchestry-modal-definitions";
import { OrchestryNoRecordsFound } from "appv2/components/orchestry-no-records-found/orchestry-no-records-found.component";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { DEFAULT_LOCALE } from "appv2/types/globals.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { HealthChecksTableActionsColumn, HealthChecksTableNameColumn, HealthChecksTableStatusColumn, HealthChecksTableTypeColumn, HealthChecksTableTypeHeader, HealthChecksTrendDataStartDateColumn } from "./health-checks-table-columns";
import { useHealthCheckRouteParams } from "./use-health-check-route-params.hook";
import { useHealthChecksFilters } from "./use-health-checks-filters.hook";
export var useHealthChecksTable = function (pagedHealthChecks, sorting, setSorting, isPageLoading, isTableLoading, highlightSearchWords, showAlert) {
    var _a;
    var intl = useIntl();
    var history = useHistory();
    var isMobile = useIsMobile();
    var localization = useMapLocaleToMRTLocalization();
    var tenantGuid = useHealthCheckRouteParams().tenantGuid;
    var HEALTH_CHECKS_TABLE_FILTERS_LIST = useHealthChecksFilters().HEALTH_CHECKS_TABLE_FILTERS_LIST;
    var onSortingChange = function (updaterOrValue) {
        setSorting(updaterOrValue);
    };
    var onViewHealthCheckClicked = useCallback(function (uniqueId) {
        if (!isPageLoading && !isTableLoading) {
            if (tenantGuid) {
                history.push("/partner-tools/tenants/view/".concat(tenantGuid, "/health-checks/view/").concat(uniqueId, "?displayMode=view"));
            }
            else {
                history.push("/health-checks/view/".concat(uniqueId, "?displayMode=view"));
            }
        }
    }, [isPageLoading, isTableLoading, tenantGuid]);
    var onDownloadHealthCheckClicked = useCallback(function (uniqueId) {
        var _a, _b;
        if (!isPageLoading && !isTableLoading) {
            if (tenantGuid) {
                window.open("/partner-tools/tenants/view/".concat(tenantGuid, "/health-checks/pdfs/view/").concat(uniqueId, "?locale=").concat((_a = intl.locale) !== null && _a !== void 0 ? _a : DEFAULT_LOCALE, "&displayMode=pdf"), "_blank");
            }
            else {
                window.open("/health-checks/pdfs/view/".concat(uniqueId, "?locale=").concat((_b = intl.locale) !== null && _b !== void 0 ? _b : DEFAULT_LOCALE, "&displayMode=pdf"), "_blank");
            }
        }
    }, [isPageLoading, isTableLoading, intl.locale, tenantGuid]);
    var onShareHealthCheckClicked = useCallback(function (uniqueId) {
        if (!isPageLoading && !isTableLoading) {
            openOrchestryModal("shareHealthCheckModal", { uniqueId: uniqueId, intl: intl, tenantGuid: tenantGuid }, "lg");
        }
    }, [isPageLoading, isTableLoading, intl, tenantGuid]);
    var onEditHealthCheckClicked = useCallback(function (uniqueId) {
        if (!isPageLoading && !isTableLoading) {
            if (tenantGuid) {
                window.open("/partner-tools/tenants/view/".concat(tenantGuid, "/health-checks/view/").concat(uniqueId, "?displayMode=edit"));
            }
            else {
                window.open("/health-checks/view/".concat(uniqueId, "?displayMode=edit"));
            }
        }
    }, [isPageLoading, isTableLoading, tenantGuid]);
    var onDeleteHealthCheckClicked = useCallback(function (uniqueId, name) {
        openOrchestryModal("deleteHealthCheckModal", { uniqueId: uniqueId, name: name, intl: intl, tenantGuid: tenantGuid }, "md");
    }, [intl, tenantGuid]);
    var columns = useMemo(function () { return [
        {
            accessorKey: "Name",
            header: intl.formatMessage({ id: "page.health_checks.table.header.health_checks", defaultMessage: "Health Checks" }),
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return HealthChecksTableNameColumn(cell.getValue(), highlightSearchWords);
            },
            grow: true,
            minSize: isMobile ? undefined : 280
        },
        {
            accessorKey: "Type",
            header: intl.formatMessage({ id: "page.health_checks.table.header.type", defaultMessage: "Type" }),
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return HealthChecksTableTypeColumn(row);
            },
            Header: HealthChecksTableTypeHeader,
            minSize: 230,
            grow: true
        },
        {
            accessorKey: "StartDate",
            header: intl.formatMessage({ id: "page.health_checks.table.header.trend_data_start_date", defaultMessage: "Trend Data Start Date" }),
            enableSorting: false,
            Cell: function (_a) {
                var row = _a.row;
                return HealthChecksTrendDataStartDateColumn(row);
            },
            minSize: 230,
            grow: true
        },
        {
            accessorKey: "GeneratedDate",
            header: intl.formatMessage({ id: "page.health_checks.table.header.date_generated", defaultMessage: "Date Generated" }),
            enableSorting: true,
            Cell: function (_a) {
                var cell = _a.cell;
                return OrchestryDataTableDateColumn(cell.getValue());
            },
            desc: true,
            grow: true
        },
        {
            accessorKey: "Status",
            header: intl.formatMessage({ id: "page.health_checks.table.header.status", defaultMessage: "Status" }),
            enableSorting: false,
            Cell: function (_a) {
                var cell = _a.cell;
                return HealthChecksTableStatusColumn(cell.getValue());
            },
            grow: true
        },
        {
            accessorKey: "actions",
            header: intl.formatMessage({ id: "page.health_checks.table.header.actions", defaultMessage: "Actions" }),
            enableSorting: false,
            mantineTableHeadCellProps: {
                align: "right"
            },
            mantineTableBodyCellProps: {
                align: "right"
            },
            Cell: function (_a) {
                var row = _a.row;
                return HealthChecksTableActionsColumn(row, onViewHealthCheckClicked, onDownloadHealthCheckClicked, onShareHealthCheckClicked, onEditHealthCheckClicked, onDeleteHealthCheckClicked);
            },
            grow: false,
            maxSize: 90
        },
        {
            accessorKey: "GenerationMethod",
            header: ""
        },
        {
            accessorKey: "UseHistoricalTrend",
            header: ""
        },
        {
            accessorKey: "UniqueId",
            header: ""
        }
    ]; }, [
        intl,
        isMobile,
        highlightSearchWords,
        HEALTH_CHECKS_TABLE_FILTERS_LIST,
        onViewHealthCheckClicked,
        onDownloadHealthCheckClicked,
        onShareHealthCheckClicked,
        onEditHealthCheckClicked,
        onDeleteHealthCheckClicked
    ]);
    var _b = useState({
        GenerationMethod: false,
        UseHistoricalTrend: false,
        UniqueId: false
    }), columnVisibility = _b[0], setColumnVisibility = _b[1];
    useEffect(function () {
        if (isMobile) {
            setColumnVisibility({
                GenerationMethod: false,
                actions: true,
                Status: false,
                StartDate: false,
                GeneratedDate: false,
                Type: false,
                Name: true,
                UseHistoricalTrend: false,
                UniqueId: false
            });
        }
        else {
            setColumnVisibility({
                GenerationMethod: false,
                UseHistoricalTrend: false,
                UniqueId: false
            });
        }
    }, [isMobile]);
    var onColumnVisibilityChange = function (newVisibility) {
        setColumnVisibility(newVisibility);
    };
    var onRowClicked = useCallback(function (row) {
        if (row.getValue("Status") !== "Processing") {
            onViewHealthCheckClicked(row.getValue("UniqueId"));
        }
    }, [onViewHealthCheckClicked]);
    var getRowStyle = useCallback(function (row) {
        if (row.getValue("Status") === "Processing") {
            return undefined;
        }
        return { cursor: "pointer" };
    }, []);
    var table = useCustomMantineReactTable({
        layoutMode: "grid",
        onSortingChange: onSortingChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        initialState: {
            sorting: sorting,
            columnVisibility: columnVisibility,
            isLoading: true
        },
        mantineLoadingOverlayProps: {
            visible: false
        },
        state: { sorting: sorting, columnVisibility: columnVisibility, isLoading: isTableLoading },
        columns: columns,
        data: (_a = pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.Items) !== null && _a !== void 0 ? _a : [],
        renderEmptyRowsFallback: OrchestryNoRecordsFound,
        mantineTableContainerProps: {
            style: { maxHeight: showAlert ? "550px" : "630px" }
        },
        mantineTableBodyRowProps: function (_a) {
            var row = _a.row;
            return ({
                onClick: function () { return onRowClicked(row); },
                style: getRowStyle(row)
            });
        },
        localization: localization
        //These were causing a bug with the table using mantineTableBodyRowProps.
        //But removing the virtualization, the tables still seem pretty fast.
        //Only needed if a table has well over 100 rows, but our paging will cap out at 100.
        // enableRowVirtualization: true,
        // rowVirtualizerOptions: { overscan: 12 }
    }, isPageLoading ? undefined : !(pagedHealthChecks === null || pagedHealthChecks === void 0 ? void 0 : pagedHealthChecks.Items.length));
    return {
        table: table
    };
};
